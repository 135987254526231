import React, { useContext, useEffect, useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Typography,
  TextField, Button
} from '@mui/material';
import { IconButton } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import LobbyPageStyle from './LobbyPageStyle';
import cn from 'classnames';
import {useHistory, useLocation} from 'react-router-dom';
import { SessionContext } from 'context/SessionContext';
import { Session } from 'services';
import { UserContext } from 'context/UserContext';
import { handleNetworkError } from 'utils/utils';
import { toast } from 'react-toastify';

const LobbyPage = ({ classes }) => {
  const [initialized, setInitialized] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const [orderBy, setOrderBy] = useState('date');
  const [order, setOrder] = useState('asc');
  const [search, setSearch] = useState('');

  let interval = useRef(null);
  const location = useLocation();

  const history = useHistory();

  const { sessionData, setSessionData, currentSessionData, setCurrentSessionData } = useContext(SessionContext);
  const { userData } = useContext(UserContext);

  useEffect(() => {
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    }
  }, [])

  // useEffect(() => {
  //   if(userData?.isHost === false && userData?.newUser === true) {
  //     history.push('/enter-stage-name-and-photo');
  //   }
  // }, [userData])

  useEffect(() => {
    console.log('current session data test', currentSessionData);
    setCurrentSessionData(null);
  }, [currentSessionData]);

  // Fetch session data on component mount
  useEffect(() => {
    fetchSessionData();
  }, []); // Empty dependency array for component mount

  // Fetch session data when location changes
  useEffect(() => {
    const locationKey = location.pathname + location.search; // Create a unique key based on location
    fetchSessionData();
  }, [location.pathname, location.search]);

  useEffect(() => {
    interval.current = setInterval(fetchSessionData, 5000);
    return () => clearInterval(interval.current); // clear interval on component unmount
  }, [userData]);

  const fetchSessionData = () => {
    if(userData === null) {
      return;
    }

    const userId = userData?.isHost ? userData.id : null;
    console.log('requesting session data in lobby for: ', userData)
    Session.listAll(userId)
      .then(response => {
        setSessionData(response.data);
      })
      .catch(error => {
        handleNetworkError(error, false, false);
      });
  };

  const handleRowClick = row => {
    setSelectedRow(row);
  };

  const handleSortClick = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const joinSession = () => {
    console.log('selected row', selectedRow);
    console.log('user data', userData);

    if(userData?.isHost) {
      history.push('/host-queue', { data: selectedRow });
    }
    else {
      if(!selectedRow?.enableScoreboard && userData === 'voter') {
        toast.error('This session does not allow voters to join. Please select another session.');
        return;
      }
      if(selectedRow?.enableSessionId) {
        history.push('/enter-session', { data: selectedRow });
      }
      else {
        history.push('/performer-queue', { data: selectedRow });
      }
    }
  }

  const startSession = () => {
    console.log('selected row', selectedRow);
    Session.activate(selectedRow.id)
      .then(() => {
        history.push('/host-queue', { data: selectedRow });
      })
      .catch(handleNetworkError);
  }

  const createSession = () => {
    history.push('/create-session', { data: selectedRow });
  }

  const rows = sessionData || [];

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const sortedRows = rows.sort((a, b) => {
    const isAsc = order === 'asc';
    let result = 0;
    if (a[orderBy] < b[orderBy]) {
      result = -1;
    } else if (a[orderBy] > b[orderBy]) {
      result = 1;
    }
    return isAsc ? result : -result;
  });

  const filteredRows = sortedRows.filter(row => {
    return row?.date?.toLowerCase()?.includes(search.toLowerCase()) ||
      row?.venue?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
      row?.time?.toLowerCase()?.includes(search.toLowerCase()) ||
      row?.host?.toLowerCase()?.includes(search.toLowerCase())
  });

  if(!userData) return null;

  return (
    <div className={classes.root}>
      <Typography variant="h4" fontWeight={'bold'} className={classes.title}>
        {userData?.isHost ? 'Start a session' : 'Join a session'}
      </Typography>

      {!userData?.isHost && (
        <>
          <Typography variant="subtitle1" className={classes.subtitle}>
            In order to join a session, the session must be currently active
          </Typography>

          <Typography variant="subtitle1" sx={{marginTop: '16px'}}>
            Select a row
          </Typography>
        </>
      )}

      <div className={classes.searchContainer}>
        <TextField
          label="Search"
          variant="outlined"
          placeholder={'Search by date, venue, time, host, or active'}
          size="small"
          className={classes.searchInput}
          value={search}
          onChange={handleSearchChange}
        />
      </div>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell onClick={() => handleSortClick('date')} className={classes.sortableHeader}>
                <div className={classes.sortableHeaderContainer}>
                  <span>Date</span>
                  {orderBy === 'date' && (
                    <IconButton size="small" >
                      {order === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  )}
                </div>
              </TableCell>

              <TableCell onClick={() => handleSortClick('venue')} className={classes.sortableHeader}>
                <div className={classes.sortableHeaderContainer}>
                  <span>Venue</span>
                  {orderBy === 'venue' && (
                    <IconButton size="small">
                      {order === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  )}
                </div>
              </TableCell>

              <TableCell onClick={() => handleSortClick('time')} className={classes.sortableHeader}>
                <div className={classes.sortableHeaderContainer}>
                  <span>Time</span>
                  {orderBy === 'time' && (
                    <IconButton size="small">
                      {order === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  )}
                </div>
              </TableCell>

              <TableCell onClick={() => handleSortClick('host')} className={classes.sortableHeader}>
                <div className={classes.sortableHeaderContainer}>
                  <span>Host</span>
                  {orderBy === 'host' && (
                    <IconButton size="small">
                      {order === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  )}
                </div>
              </TableCell>

              <TableCell onClick={() => handleSortClick('active')} className={classes.sortableHeader}>
                <div className={classes.sortableHeaderContainer}>
                  <span>Active</span>
                  {orderBy === 'active' && (
                    <IconButton size="small">
                      {order === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {filteredRows.map(row => (
              <TableRow
                key={row.id}
                className={cn(selectedRow === row ? classes.selectedRow : '', row.active ? classes.activeRow : '')}
                onClick={() => handleRowClick(row)}
              >
                <TableCell className={cn(classes.tableCell, selectedRow?.id === row?.id ? classes.selectedRow : '')}>
                  {row.date}
                </TableCell>
                <TableCell className={cn(classes.tableCell, selectedRow?.id === row?.id ? classes.selectedRow : '')}>
                  {row.venue?.name}
                </TableCell>
                <TableCell className={cn(classes.tableCell, selectedRow?.id === row?.id ? classes.selectedRow : '')}>
                  {row.time}
                </TableCell>
                <TableCell className={cn(classes.tableCell, selectedRow?.id === row?.id ? classes.selectedRow : '')}>
                  {row.host}
                </TableCell>
                <TableCell className={cn(classes.tableCell, selectedRow?.id === row?.id ? classes.selectedRow : '')}>
                  {row.active === true ? 'Yes' : 'No'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedRow && (
        <div className={classes.cardContainer}>
          <CardContent>
            <Typography variant="h6">{selectedRow.venue?.name}</Typography>
            <Typography variant="subtitle1">{selectedRow.date} @ {selectedRow.time}</Typography>
            <Typography variant="subtitle1">Hosted by {selectedRow.host}</Typography>
            <Typography variant="subtitle1">Active: {selectedRow.active === true ? 'Yes' : 'No'}</Typography>

            <Button
              variant="contained"
              color="primary"
              className={classes.joinButton}
              onClick={joinSession}
              sx={{
                marginBottom: '8px'
              }}
            >
              Join This Session
            </Button>
          </CardContent>
        </div>
      )}

      {userData?.isHost && (
        <>
          <Button
            variant="contained"
            className={classes.createButton}
            onClick={createSession}
            sx={{
              marginTop: '8px'
            }}
          >
            Create Session
          </Button>
        </>
      )}
    </div>
  );
};

LobbyPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(LobbyPageStyle)(LobbyPage);
