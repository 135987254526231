import React, { useContext, useState, useEffect } from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import EditBumpSongPageStyle from './EditBumpSongPageStyle';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import { SessionContext } from 'context/SessionContext';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { getOrdinalIndicator } from 'utils/utils';
import { toast } from 'react-toastify';

const EditBumpSongPage = props => {
  const { classes } = props;

  const {sessionState, sessionActions} = useContext(SessionContext);
  const {userData} = useContext(UserContext);

  const [connected, setConnected] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [currentSessionData, setCurrentSessionData] = useState(null);
  const [songInfo, setSongInfo] = useState({
    title: '',
    artist: ''
  });
  const [userIsTopOfQueue, setUserIsTopOfQueue] = useState(false);
  const [busy, setBusy] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // save the user's position in queue
    const userPosition = sessionState.queue.findIndex(item => item.user.id === userData?.id);

    if(userPosition === 0) {
      setUserIsTopOfQueue(true);
    }
    else {
      setUserIsTopOfQueue(false);
    }
  }, [sessionState.queue])

  useEffect(() => {
    // get the session data from the location
    const data = location?.state?.data;
    setCurrentSessionData(data);
    console.log('current session data', data)

    // find the current user in sessionState.queue and set song info
    const user = sessionState.queue.find(item => item.user.id === userData?.id);

    setSongInfo({
      title: user?.song?.song_name,
      artist: user?.song?.artist
    });
  }, [location, sessionState])

  useEffect(() => {
    if(sessionState.wsConnection && !connected && currentSessionData) {
      console.log('attempt join session', {currentSessionData})

      setBusy(true);
      sessionActions.joinSession(currentSessionData.sessionId, currentSessionData.id)
        .then(response => {
          console.log('join session response', response);
        })
        .catch(error => {
          console.error('join session error', error);
        })
        .finally(() => setBusy(false))
      setConnected(true);
    }
  }, [sessionState, userData, currentSessionData])

  const handleRemove = () => {
    console.log('remove self from queue', currentSessionData, currentSessionData.sessionId)

    setBusy(true);
    sessionActions.removeFromQueue(currentSessionData.sessionId)
      .then(response => {
        console.log('remove from queue response', response);
      })
      .catch(error => {
        console.error('remove from queue error', error);
      })
      .finally(() => setBusy(false))

    history.push('/performer-queue', { data: currentSessionData });
  };

  const handleChangeSong = () => {
    history.push('/choose-song', { isEditing: true, data: currentSessionData });
  };

  const handleBumpToTop = () => {
    // calculate the amount of bumps the user has used
    let userBumps = sessionState?.bumps?.filter(item => (item.user === userData?.id) && (item.type === 'bump_to_top'));

    if(userData?.id === sessionState.queue[1]?.user?.id) {
      toast.error('You are already at the top of the queue.')
      return;
    }

    if(userBumps.length >= 1) {
      toast.error('You have used all of your bumps to the top for this session.');
      return;
    }

    if(userIsTopOfQueue) {
      toast.error('You are already at the top of the queue.');
    }
    else if(userData?.last4 === null && currentSessionData?.bumpToTopFee > 0) {
      toast.error('You must have a card on file to bump up in this session.');
    }
    else {
      setBusy(true)
      sessionActions.bumpToTop(currentSessionData.sessionId, userData?.id, false)
        .then(response => {
          console.log('bump to top response', response);
        })
        .catch(error => {
          console.error('bump to top error', error);
        })
        .finally(() => setBusy(false))
    }
  };

  const handleBumpUp = () => {
    // calculate the amount of bumps the user has used
    let userBumps = sessionState?.bumps?.filter(item => (item.user === userData?.id) && (item.type !== 'bump_to_top'));

    if(userBumps.length >= currentSessionData?.maximumNumberOfBumps) {
      toast.error('You have used all of your bumps for this session.');
      return;
    }

    if(userIsTopOfQueue) {
      toast.error('You are already at the top of the queue.');
    }
    else if(userData?.last4 === null && currentSessionData?.bumpUpFee > 0) {
      toast.error('You must have a card on file to bump up in this session.');
    }
    else {
      setBusy(true)
      sessionActions.bumpUp(currentSessionData.sessionId, userData?.id, false)
        .then(response => {
          console.log('bump up response', response);
        })
        .catch(error => {
          console.error('bump up error', error);
        })
        .finally(() => setBusy(false))
    }
  };

  const handleClose = () => {
    history.push('/performer-queue', { data: currentSessionData });
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerContent}>
        <Typography variant="h4" className={classes.title}>
          {currentSessionData?.type === 'Karaoke' ? 'Edit Song Options' : 'Edit Bump Options'}
        </Typography>

        <div className={classes.cardContainer}>
          <Typography variant="h5" className={classes.cardTitle}>
            Your Upcoming Performance
          </Typography>

          <div className={classes.cardContainerInner}>
            {currentSessionData?.type === 'Karaoke' && (
              <div>
                <Typography
                  sx={{
                    fontWeight: 'bold'
                  }}
                >
                  {songInfo?.title}
                </Typography>
                <Typography
                  sx={{
                    marginTop: '8px',
                    marginBottom: '8px'
                  }}
                >
                  by:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 'bold'
                  }}
                >
                  {songInfo?.artist}
                </Typography>
              </div>
            )}

            <div className={classes.column}>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Remove From Queue
              </Button>

              {currentSessionData?.type === 'Karaoke' && (
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={handleChangeSong}
                >
                  Change Song
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className={classes.cardContainer}>
          <Typography variant="h5" className={classes.cardTitle}>
            Bump Options
          </Typography>

          <div className={classes.cardContainerInner}>
            <div>
              <Typography
                sx={{

                }}
              >
                You are currently
              </Typography>
              <Typography
                sx={{
                  marginTop: '8px',
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  fontSize: '28px'
                }}
              >
              {/* find the user's place in the queue */}
              {getOrdinalIndicator(sessionState.queue.findIndex(item => item.user.id === userData?.id) + 1)}
              </Typography>
              <Typography
                sx={{

                }}
              >
                in the queue
              </Typography>
            </div>

            <div className={classes.bumpToTopContainer}>
              <Typography className={classes.typography}>
                You can move to the top of the queue.
              </Typography>
              <Typography className={classes.typography}>
                You can only do this once per session.
              </Typography>
              <Typography className={classes.typography}>
                No other performer can bump you from this spot.
              </Typography>
              <Typography>
                Should you remove this song or miss your stage call, this will not be refunded.
              </Typography>

              <div className={classes.buttonContainer}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={handleBumpToTop}
                  disabled={busy}
                >
                  Bump to Top
                </Button>
              </div>
            </div>

            <div className={classes.textAlign}>
              <Typography className={classes.typography}>
               You can advance one position in the queue.
              </Typography>
              <Typography className={classes.typography}>
                You can do this max times per session.
              </Typography>
              <Typography className={classes.typography}>
                If you remove this song, this fee will be refunded.
              </Typography>
              <Typography>
                If you miss your stage call, it will not be refunded.
              </Typography>

              <div className={classes.buttonContainer}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  disabled={busy}
                  onClick={handleBumpUp}
                >
                  Bump Up
                </Button>
              </div>
            </div>

            <div className={classes.textAlign}>
              <Typography
                sx={{
                  marginTop: '16px',
                }}
              >
                You have 2 remaining bumps
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.closeButtonContainer}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={handleClose}
          >
            Close
          </Button>
        </div>

      </div>

      <Dialog open={openConfirmationDialog}>
        <DialogTitle>
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You will lose all bump fees after removing a song, proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'contained'}
            onClick={handleRemove}
            disabled={busy}
          >
            Confirm
          </Button>
          <Button
            variant={'outlined'}
            onClick={() => setOpenConfirmationDialog(false)}
            disabled={busy}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EditBumpSongPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(EditBumpSongPageStyle)(EditBumpSongPage);
