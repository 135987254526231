import { makeStyles } from '@mui/styles';

const LobbyPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    maxWidth: '800px',
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '18px',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  tableContainer: {
    width: 'calc(100% - 32px)',
    marginBottom: theme.spacing(2),
    borderRadius: 8,
    maxHeight: '300px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  },
  tableHead: {

  },
  cardContainer: {
    width: '100%',
    borderRadius: 8,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    padding: theme.spacing(2),
  },
  cardTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  cardSubtitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  tableCell: {
    fontSize: '11px !important',
    display: 'flex',
    padding: '10px !important',
    alignItems: 'center',

    justifyContent: 'center',
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    '& span': {
      marginRight: theme.spacing(2),
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    margin: '32px 0',
    marginBottom: '12px'
  },
  searchInput: {
    width: '75%',
    fontSize: '12px !important'
  },
  sortableHeader: {
    cursor: 'pointer',
    padding: '10px !important',
    background: theme.palette.primary.main + ' !important',

    '& *': {
      color: 'white !important'
    }
  },
  sortableHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '& span': {
      fontSize: '12px !important'
    },

    '& svg': {
      height: '18px !important',
      width: '18px !important',
      fontSize: '18px !important'
    }
  },
  selectedRow: {
    backgroundColor: theme.palette.primary.light + ' !important',
    transition: '0.5s'
  },
  joinButton: {
    marginTop: '12px !important'
  },
  activeRow: {
    background: 'rgba(200, 255, 197, 0.4)'
  }
});

export default LobbyPageStyle;
