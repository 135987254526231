const CreateSessionPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%',
    height: 'calc(100vh - 85px)',
  },
  innerContent: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  subtitle: {
    '&.MuiTypography-root': {
      textAlign: 'left',
      marginLeft: '8px',
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 400,
  },
  formInputContainer: {
    marginBottom: '32px',

    '& .MuiTextField-root': {
      width: '100%'
    },
  },
  formInputSwitchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  formInput: {
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: theme.spacing(1),
  },
  fullWidthAutocomplete: {
    width: '100%',
    flex: 1
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '32px'
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%'
  },
  songSwitchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    marginTop: '32px'
  },
  marginTopContainer: {
    marginTop: '16px'
  },
  addIconButton: {
    '&.MuiButtonBase-root': {
      marginLeft: '16px'
    }
  },
  sectionContainer: {
    padding: '32px 0',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderTop: `solid 1px ${theme.palette.primary.main}`,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '32px'
  },
  button: {
    width: '100%',
    height: '48px',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
  },
});

export default CreateSessionPageStyle;
