import React, { useContext, useEffect, useState } from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import ChooseSongTableStyle from './ChooseSongTableStyle';
import {Button, Checkbox, IconButton, TextField, Typography} from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { SessionContext } from 'context/SessionContext';
import { UserContext } from 'context/UserContext';
import { useHistory } from 'react-router-dom';
import { handleNetworkError } from 'utils/utils';
import { Song } from 'services';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';

const ChooseSongTable = props => {
  const {
    classes,
    songs,
    selectedSongs=[],
    currentSessionData,
    isEditing,

    chooseForGuest = false,
    deleteSong = false,
    songsEditable = false,
    multipleSelect = false,
    onSuccess = (data) => {}
  } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [selected, setSelected] = useState([]);
  const [selectedForDeletion, setSelectedForDeletion] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const history = useHistory();

  const {sessionState, sessionActions} = useContext(SessionContext);
  const {userData} = useContext(UserContext);

  const filteredSongs = searchQuery
    ? songs.filter((song) => song?.songName?.toLowerCase()?.startsWith(searchQuery?.toLowerCase()))
    : songs;

  useEffect(() => {
    console.log('selected songs', selectedSongs, selected);
    setSelected([...selectedSongs, ...selected]);
  }, [selectedSongs])

  const handleSelect = song => {
    if (multipleSelect) {
      // Handle multiple selection logic
      if (selected.some(s => s.id === song.id)) {
        console.log('test', {selected, song});
        setSelected(selected.filter(s => s.id !== song.id));
      } else {
        setSelected([...selected, song]);
      }
    } else {
      // Handle single selection logic
      setSelected([song]);
    }
  };

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);
    if (!selectAllChecked) {
      setSelected(filteredSongs);
    } else {
      setSelected([]);
    }
  };

  const handleSelectForDeletion = song => {
    console.log('select for deletion', song);
    if (selectedForDeletion.some(s => s.id === song.id)) {
      setSelectedForDeletion(selectedForDeletion.filter(s => s.id !== song.id));
    } else {
      setSelectedForDeletion([...selectedForDeletion, song]);
    }
  }

  const handleDelete = (e, song) => {
    e.stopPropagation();

    // delete song
    if(deleteSong) {
      try{
        deleteSong(song);
      }
      catch(error) {
        console.error(error);
      }
    }
  };

  const handleDoubleClick = song => {
    console.log('double click', song)
    if (songsEditable) {
      history.push('/edit-song', song);
    }
    else {
      handleSelect(song);
      chooseSongAndJoinQueue();
    }
  };

  const chooseSongAndJoinQueue = () => {
    console.log('choose song and join queue', {
      wsConnection: sessionState.wsConnection,
      userData,
      isEditing,
    });

    if (sessionState.wsConnection && userData) {
      if(chooseForGuest) {
        onSuccess(selected);
      }
      else if(multipleSelect && songsEditable) {
        let selectedSongs = [];

        for (const song of selected) {
          selectedSongs.push(song.id);
        }

        let formattedSelectedForDeletion = selectedForDeletion.map(song => song.id);

        Song.setSongs(selectedSongs, formattedSelectedForDeletion)
          .then((response) => {
            toast.success(songsEditable ? 'Songs added to host list' : 'Songs added to queue');

            // remove all songs in selectedForDeletion from songs
            onSuccess(selectedSongs, formattedSelectedForDeletion);

          })
          .catch(handleNetworkError);
      }
      else {
        // this for loop will only occur once so this is safe
        if (!isEditing) {
          // check how many times a user is already in the queue
          let count = 0;
          for (const user of currentSessionData?.queue) {
            if (user?.user?.id === userData?.id) {
              count++;
            }
          }

          if(count >= currentSessionData?.maximumNumberOfTimesAUserCanJoin) {
            toast.error('You have already joined the queue the maximum number of times for this session.');

            history.replace('/performer-queue', { data: currentSessionData });
            return;
          }

          sessionActions.joinQueue(currentSessionData?.sessionId, currentSessionData?.id, selected?.[0]?.id);
        }
        else {
          sessionActions.changeSong(currentSessionData.sessionId, selected?.[0]?.id);
        }

        history.replace('/performer-queue', { data: currentSessionData });
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <div className={classes.formInputContainer}>
          <TextField
            placeholder="Enter song title or artist here"
            name="search"
            type="search"
            label="Search"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            variant="outlined"
            color={"primary"}
            fullWidth
          />
        </div>

        {songsEditable && (
          <div className={classes.informationalHeaderText}>
            <Typography variant={'body2'}>
              Songs cannot be added during a session, please add songs before starting a session.
            </Typography>
          </div>
        )}

        <div className={classes.buttonContainer}>
          <Button
              variant={'contained'}
              color={'primary'}
              type={'submit'}
              onClick={chooseSongAndJoinQueue}
              className={classes.button}
          >
            {songsEditable ? 'Apply Changes' : multipleSelect ? 'Choose Selected Songs' : 'Choose Selected Song'}
          </Button>
        </div>

        <div className={classes.table}>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {(multipleSelect || songsEditable) && (
                    <TableCell>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <Checkbox
                            indeterminate={selected.length > 0 && selected.length < filteredSongs.length}
                            checked={selectAllChecked}
                            onChange={handleSelectAllChange}
                        />
                        Activate
                      </div>

                    </TableCell>
                  )}
                  <TableCell>Song Title</TableCell>
                  <TableCell>Artist</TableCell>
                  <TableCell>Key</TableCell>
                  {songsEditable && (
                    <TableCell>Delete</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSongs.map((song, index) => (
                  <TableRow
                    key={song.id}
                    onClick={() => songsEditable ? null : handleSelect(song)}
                    onDoubleClick={() => songsEditable ? null : handleDoubleClick(song)}
                    className={selected.some(elem => elem?.id === song.id) ?
                      classes.highlighted : selectedForDeletion.some(elem => elem?.id === song.id) ?
                        classes.deletion : ''}
                    selected={selected.some(elem => elem?.id === song.id)}
                  >
                    {(multipleSelect || songsEditable) && (
                      <TableCell size={'small'} align={'center'} sx={{padding: '4px 8px !important'}}>
                        <Checkbox
                          size={'small'}
                          checked={selected.some(elem => elem?.id === song.id)}
                          onClick={(event) => handleSelect(song)}
                        />
                      </TableCell>
                    )}
                    <TableCell component="th" scope="row" size={'small'} sx={{padding: '4px 8px !important'}}>
                      {song.songName}
                    </TableCell>
                    <TableCell size={'small'} sx={{padding: '4px 8px !important'}}>
                      {song.artist}
                    </TableCell>
                    <TableCell size={'small'} sx={{padding: '4px 8px !important'}}>
                      {song.songKey}
                    </TableCell>
                    {songsEditable && (
                      <TableCell size={'small'} sx={{padding: '4px 8px !important'}}>
                        {song.createdBy === userData?.id && (
                          <Checkbox
                            size={'small'}
                            checked={selectedForDeletion.some(elem => elem?.id === song.id)}
                            onClick={(event) => handleSelectForDeletion(song)}
                          />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

ChooseSongTable.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ChooseSongTableStyle)(ChooseSongTable);
