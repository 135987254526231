import React, {useState, useEffect} from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import EditSongPageStyle from './EditSongPageStyle';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import {Form, Formik} from "formik";
import * as Yup from "yup";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import cn from "classnames";
import { useHistory, useLocation } from 'react-router-dom';
import { Song } from 'services';
import {toast} from 'react-toastify';
import { handleNetworkError } from 'utils/utils';

const EditSongPage = props => {
  const { classes } = props;

  const [checked, setChecked] = useState(false);
  const [song, setSong] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const validationSchema = Yup.object({
    songName: Yup.string().required('Required'),
    artist: Yup.string().required('Required'),
    songKey: Yup.string()
  });

  const handleSubmit = async (values, {setErrors}) => {
    Song.edit(song.id, values)
      .then(response => {
        toast.success('Song changed!');
        console.log('success!', response);
      })
      .catch(handleNetworkError)
  };

  const handleDeleteClick = () => {
    setOpenConfirmationDialog(true);
  }

  const handleDelete = () => {
    Song.delete(song.id)
      .then(response => {
        toast.success('Song successfully deleted!');
      })
      .catch(handleNetworkError)

    setOpenConfirmationDialog(false);
    history.push('/manage-song', {deletedSongs: [song.id]});
  };

  useEffect(() => {
    console.log('song to edit', location.state);
    setSong(location.state);
  }, [location])

  return (
    <div className={classes.root}>
      <div className={classes.innerContent}>
        <Typography
          variant="h4"
          className={classes.title}
          sx={{
            marginBottom: '32px'
          }}
        >
          Edit Song
        </Typography>

        <Formik
          initialValues={{
            songName: song?.songName,
            artist: song?.artist,
            songKey: song?.songKey
          }}
          enableReinitialize
          onSubmit={handleSubmit}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {({
              values,
              errors,
              handleChange,
              isSubmitting
            }) => (
            <Form className={classes.form}>
              <div className={classes.formInputContainer}>
                <TextField
                  type="text"
                  name="songName"
                  label="Title"
                  placeholder="Title"
                  className={classes.formInput}
                  error={!!errors.songName}
                  helperText={errors.songName}
                  value={values.songName}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div className={classes.formInputContainer}>
                <TextField
                  type="text"
                  name="artist"
                  label="Artist"
                  placeholder="Artist"
                  className={classes.formInput}
                  error={!!errors.artist}
                  helperText={errors.artist}
                  value={values.artist}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div className={classes.formInputContainer}>
                <TextField
                  type="text"
                  name="songKey"
                  label="Key"
                  placeholder="Key"
                  className={classes.formInput}
                  error={!!errors.songKey}
                  helperText={errors.songKey}
                  value={values.songKey}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              {/*<div className={classes.checkboxContainer}>*/}
              {/*  <Checkbox*/}
              {/*    size={'small'}*/}
              {/*    checked={checked}*/}
              {/*    checkedIcon={*/}
              {/*      <CheckBoxIcon*/}
              {/*        className={cn(classes.checkboxIcon, classes.checkedIcon)}*/}
              {/*      />*/}
              {/*    }*/}
              {/*    icon={*/}
              {/*      <CheckBoxOutlineBlankIcon*/}
              {/*        className={classes.checkboxIcon}*/}
              {/*      />*/}
              {/*    }*/}
              {/*    onChange={() => {*/}
              {/*      setChecked(!checked)*/}
              {/*    }}*/}
              {/*    name={'activeSong'}*/}
              {/*  />*/}
              {/*  <div className={classes.textContainer}>*/}
              {/*    <Typography variant="subtitle1" className={classes.subtitle}>*/}
              {/*      Active*/}
              {/*    </Typography>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.button}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>

              <div className={classes.deleteButtonContainer}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleDeleteClick}
                >
                  Delete Song
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        <Dialog open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)}>
          <DialogContent>
            <DialogTitle sx={{paddingLeft: 0}}>
              Are you sure?
            </DialogTitle>
            <div>
              This will permanently delete the song, are you sure?
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setOpenConfirmationDialog(false)}>Cancel</Button>
            <Button onClick={() => handleDelete()}>Delete</Button>
          </DialogActions>
        </Dialog>

      </div>
    </div>
  );
};

EditSongPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(EditSongPageStyle)(EditSongPage);
