const HostQueuePageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%',
    minHeight: 'calc(100vh - 85px)',
  },
  innerContent: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  rowContainer: {
    marginTop: '48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  performerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
    padding: '32px 0'
  },
  callContainer: {
    width: '100%',
    marginBottom: '32px'
  },
  upNextContainer: {
    width: '100%',

  },
  performerButtonRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '32px'
  },
  buttonRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
    padding: '32px 0'
  },
  colContainer: {
    width: '50%',
  },
  marginRight: {
    marginRight: '16px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 400,
  },
  formInput: {
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: theme.spacing(1),
    width: '100%',
  },
  formInputContainer: {
    marginBottom: '32px'
  },
  disabledFormInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.main} !important`,
    },
    '& .MuiFormLabel-root': {
      color: `${theme.palette.primary.main} !important`,
    },
    '& .MuiOutlinedInput-input:focus': {
      borderColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.main} !important`,
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      opacity: 1,
      WebkitTextFillColor: 'rgba(0, 0, 0, 1)',
    },
  },
  endButtonContainer: {
    marginLeft: '32px'
  },
  button: {
    backgroundColor: '#f04124',
    color: 'white',
    height: '48px',
    borderRadius: 4,
    padding: theme.spacing(1),
    border: 'none',
    '&:hover': {
      backgroundColor: '#a12010',
      cursor: 'pointer',
    },
  },
  messageButtonContainer: {
    marginTop: '8px'
  },
  cardButton: {
    backgroundColor: '#f04124',
    color: 'white',
    borderRadius: 4,
    padding: theme.spacing(1),
    border: 'none',
    '&:hover': {
      backgroundColor: '#a12010',
      cursor: 'pointer',
    },
  },
  avatar: {
    width: '110px !important',
    height: '110px !important'
  },
  cardContainer: {
    width: '100%',
    borderRadius: 8,
    padding: '0 !important',
    overflow: 'hidden',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  },
  cardLeftCol: {
    width: '60%'
  },
  cardContainerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    padding: '16px'
  },
  cardTitle: {
    fontSize: '20px',
    width: '100%',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    paddingLeft: '16px'
  },
  leftAlignedContent: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  cardRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  buttonContainer: {
    marginRight: '8px'
  },
  avatarContainer: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  activeRow: {
    backgroundColor: 'rgba(200, 255, 197, 0.4)',
    cursor: 'pointer'
  },
  tableContainer: {
    width: 'calc(100% - 32px)',
    marginBottom: theme.spacing(2),
    borderRadius: 8,
    maxHeight: '300px',
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '16px'
  },
  inLineContainer: {
    margin: '32px 0'
  },
  tableRow: {
    position: 'relative'
  },
  unreadRow: {
    '&:after': {
      content: '"UNREAD MESSAGE"',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#54C24F',
      backgroundColor: 'rgba(200, 255, 197, 0.9)',
    }
  },
  unreadMessageDiv: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#54C24F',
    backgroundColor: 'rgba(200, 255, 197, 0.9)',
    zIndex: 1
  },
  relativeContainer: {
    position: 'relative'
  },
  unreadCell: {
    textAlign: 'center !important',
    color: '#54C24F',
    backgroundColor: 'rgba(200, 255, 197, 0.9)'
  }

});

export default HostQueuePageStyle;
