import React, { useContext, useEffect, useRef, useState } from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import ManageSongPageStyle from './ManageSongPageStyle';
import {Button, FormControlLabel, IconButton, Radio, TextField, Typography} from "@mui/material";
import ChooseSongTable from "components/ChooseSongTable/ChooseSongTable";
import {useLocation} from "react-router-dom";
import { Session, Song, User } from 'services';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import {UserContext} from "context/UserContext";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from '@mui/icons-material/Folder';
import { handleNetworkError } from 'utils/utils';
import { toast } from 'react-toastify';

const ManageSongPage = props => {
  const { classes } = props;

  const [currentSessionData, setCurrentSessionData] = useState();
  const [songList, setSongList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [importFileEditable, setImportFileEditable] = useState(false);
  const [mySongList, setMySongList] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [merge, setMerge] = useState(false);

  const {userData, setUserData} = useContext(UserContext);

  const fileInputRef = useRef(null);

  const location = useLocation();

  const validationSchema = Yup.object({
    songName: Yup.string().required('Required'),
    artist: Yup.string().required('Required'),
    songKey: Yup.string()
  });

  const importValidationSchema = Yup.object({
    importFile: Yup.mixed().required('Required')
  });

  useEffect(() => {
    if(location?.state?.deletedSongs) {
      setSongList(songList.filter(song => !location.state.deletedSongs.includes(song.id)));
    }
  }, [location])

  const handleSubmitAddSong = async (values, {setErrors}) => {
    console.log('add song values', values);

    Song.create(values).then((response) => {
      toast.success('Song added successfully');

      console.log('song added successfully', response);

      setSongList([...songList, response.data]);
    }).catch(handleNetworkError);
  };

  const uploadFile = () => {
    console.log('upload file', selectedFile)
    // Check if a file has been selected
    if (selectedFile) {
      // Create a FormData object
      const formData = new FormData();

      // Append the file to the FormData object
      formData.append('file', selectedFile);
      formData.append('merge', merge);

      Song.uploadCsv(formData).then((response) => {
        console.log('csv upload response', response);

        toast.success('CSV file uploaded successfully, due to file size, please refresh to see the new songs.');

        //setSongList([...songList, ...response.data]);
      });
    }
  };

  // Handle the file input's change event
  const handleFileChange = (event, values, setValues) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/csv') {
      // Update the selected file state
      setSelectedFile(file);
      // Update the TextField value to the selected file's name
      setFileName(file.name);
    }
  };

  // Handle the end adornment's click event
  const handleImportFileEdit = () => {
    fileInputRef.current.click();
  };

  const deleteSong = song => {
    Song.delete(song.id).then(() => {
      toast.success('Song deleted successfully');
      setSongList(songList.filter(s => s.id !== song.id));
    }).catch(handleNetworkError);
  }

  const onSuccess = (songsToActivate, songsToDelete) => {
    // remove songsToDelete from songList
    setSongList(songList.filter(song => !songsToDelete.includes(song.id)));

    let newUserSongData = [...userData.hostSongList];

    // add songsToActivate to userData
    songsToActivate.forEach(song => {
      newUserSongData.push(song);
    })

    let newUserData = {
      ...userData,
      hostSongList: newUserSongData
    }

    setUserData(newUserData);
  }

  useEffect(() => {
    // get the current session data from the location
    const data = location?.state?.data;
    setCurrentSessionData(data);

    // check for isEditing
    const isEditing = location?.state?.isEditing;
    setIsEditing(isEditing);

  }, [location])

  useEffect(() => {
    Song.listAll().then((response) => {
      setSongList(response.data);
      console.log('songs', response.data);
    }).catch(handleNetworkError);

    User.getMySongList().then((response) => {
      console.log('my song list', response.data);
      setMySongList(response.data);
    }).catch(handleNetworkError);

  }, [currentSessionData]);

  return (
    <div className={classes.root}>
      <div className={classes.innerContent}>
        <div className={classes.outerTableContainer}>
          <Typography variant="h4" className={classes.title}>
            Songs
          </Typography>

          <div className={classes.tableContainer}>
            <ChooseSongTable
              songs={songList}
              multipleSelect
              currentSessionData={currentSessionData}
              isEditing={isEditing}
              songsEditable
              deleteSong={deleteSong}
              onSuccess={onSuccess}
              selectedSongs={mySongList}
            />
          </div>
        </div>

        <div className={classes.addSongContainer}>
          <Typography
            variant="h4"
            className={classes.title}
            sx={{
              marginBottom: '32px'
            }}
          >
            Add Song
          </Typography>

          <Formik
              initialValues={{
                songName: '',
                artist: '',
                songKey: ''
              }}

              onSubmit={handleSubmitAddSong}
              validateOnChange={false}
              validationSchema={validationSchema}
          >
              {({
                values,
                errors,
                handleChange,
                isSubmitting,
                setValues
              }) => (
              <Form>
                <div className={classes.formInputContainer}>
                  <TextField
                    type="text"
                    name="songName"
                    label="Title"
                    placeholder="Title"
                    className={classes.formInput}
                    error={!!errors.songName}
                    helperText={errors.songName}
                    value={values.songName}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                  />
                </div>
                <div className={classes.formInputContainer}>
                  <TextField
                    type="text"
                    name="artist"
                    label="Artist"
                    placeholder="Artist"
                    className={classes.formInput}
                    error={!!errors.artist}
                    helperText={errors.artist}
                    value={values.artist}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                  />
                </div>
                <div className={classes.formInputContainer}>
                  <TextField
                    type="text"
                    name="songKey"
                    label="Key"
                    placeholder="Key"
                    className={classes.formInput}
                    error={!!errors.songKey}
                    helperText={errors.songKey}
                    value={values.songKey}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                  />
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.saveButton}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
            </Formik>
        </div>

        <div className={classes.importListContainer}>
          <Typography
            variant="h4"
            className={classes.title}
            sx={{
              marginBottom: '32px'
            }}
          >
            Import List
          </Typography>
          <Typography variant="subtitle1" sx={{marginBottom: '16px', textAlign: 'center'}}>
            Import from CSV or text file.
          </Typography>
          <Typography variant="subtitle1" sx={{marginBottom: '32px', textAlign: 'center'}}>
            Duplicates will be discarded.
          </Typography>

          <div className={classes.form}>

            <div className={classes.formInputContainer}>
              <input
                type="file"
                accept=".csv"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={e => handleFileChange(e)}
              />

              {/*TODO: THIS NEEDS TO BE ABLE TO CLICK ON AND SELECT A FILE FROM COMPUTER - BUT LOOK LIKE THIS TEXTFIELD*/}
              <TextField
                label="File"
                variant="outlined"
                fullWidth
                disabled={!importFileEditable}
                name="file"
                value={fileName}
                onChange={(e) => handleFileChange(e)}
                className={classes.textField}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      className={classes.fileButton}
                      size={'small'}
                      onClick={handleImportFileEdit}
                    >
                      {importFileEditable ?
                        <CloseIcon sx={{color: 'white', fontSize: '18px'}}/>
                        :
                        <FolderIcon sx={{color: 'white', fontSize: '18px'}}/>
                      }
                    </IconButton>
                  ),
                }}
              />
            </div>

            <div className={classes.notificationMethodContainer}>
              <div className={classes.notificationMethodRow}>
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Merge"
                  labelPlacement="end"
                  value={merge === false}
                  name="merge"
                  onChange={() => setMerge(false)}
                  checked={merge === false}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Replace"
                  labelPlacement="end"
                  value={merge === true}
                  name="merge"
                  onChange={() => setMerge(true)}
                  checked={merge === true}
                />
              </div>
            </div>

            <Button
              variant="contained"
              color="primary"
              type={'submit'}
              size={'small'}
              className={classes.saveButton}
              onClick={uploadFile}
            >
              Import
            </Button>
          </div>
        </div>

      </div>
    </div>
  );
};

ManageSongPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ManageSongPageStyle)(ManageSongPage);
