import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import AddGuestPageStyle from './AddGuestPageStyle';
import {Button, TextField, Typography} from "@mui/material";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import { SessionContext } from 'context/SessionContext';
import { Session } from 'services';
import { handleNetworkError } from 'utils/utils';
import { useHistory, useLocation } from 'react-router-dom';

const AddGuestPage = props => {
  const { classes } = props;

  const {
    sessionState,
    sessionActions,
    setCurrentSessionData: setContextSessionData
  } = useContext(SessionContext);

  const [initialized, setInitialized] = useState(false);
  const [currentSessionData, setCurrentSessionData] = useState(null);
  const [chosenSong, setChosenSong] = useState(null);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // get the session data from the location
    const data = location?.state?.data;

    if(!initialized) {
      Session.get(data.id)
        .then(response => {
          console.log('refresh session data', response);
          setCurrentSessionData(response.data);
          setContextSessionData(response.data);
          setInitialized(true);
        })
        .catch(handleNetworkError)
    }

    const song = location?.state?.song?.[0];
    setChosenSong(song);

    console.log('add guest data', {data, song})
  }, [location, sessionState])

  const validationSchema = Yup.object({
    stageName: Yup.string().required('Stage name is required'),
    songTitle: Yup.string(),
    songArtist: Yup.string(),
    queuePosition: Yup.number().required('Queue position is required')
  });

  const handleSubmit = async (values, {setErrors}) => {
    sessionActions.addGuest(currentSessionData?.sessionId, values);
    history.push('/host-queue', {data: currentSessionData});
  };

  const navigateToChooseSong = () => {
    history.push('/choose-song', {data: currentSessionData, isEditing: false, returnTo: '/add-guest'});
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerContent}>
        <Typography
          variant="h4"
          className={classes.title}
          sx={{
            marginBottom: '32px'
          }}
        >
          Add Guest
        </Typography>

        <Formik
            initialValues={{
              stageName: '',
              songTitle: chosenSong?.songName || '',
              songArtist: chosenSong?.artist || '',
              songPk: chosenSong?.id || '',
              queuePosition: '',
            }}
            onSubmit={handleSubmit}
            validateOnChange={false}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {({
                values,
                errors,
                handleChange,
                isSubmitting
            }) => (
            <Form className={classes.form}>
              <div className={classes.formInputContainer}>
                <TextField
                  type="text"
                  name="stageName"
                  label="Stage Name (required)"
                  placeholder="Enter a stage name"
                  className={classes.formInput}
                  error={!!errors.stageName}
                  helperText={errors.stageName}
                  value={values.stageName}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              {currentSessionData?.type === 'Karaoke' && (
                <>
                  <div className={classes.formInputContainer}>
                    <TextField
                      type="text"
                      name="songTitle"
                      label="Song Title"
                      placeholder="Enter a song title"
                      className={classes.formInput}
                      error={!!errors.songTitle}
                      helperText={errors.songTitle}
                      value={values.songTitle}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.formInputContainer}>
                    <TextField
                      type="text"
                      name="songArtist"
                      label="Song Artist"
                      placeholder="Enter a song artist"
                      className={classes.formInput}
                      error={!!errors.songArtist}
                      helperText={errors.songArtist}
                      value={values.songArtist}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                    />
                  </div>

                  <div className={classes.textContainer}>
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      OR
                    </Typography>
                  </div>

                  {/*TODO: MAKE THIS NAVIGATE TO CHOOSE SONG AND BACK TO THIS PAGE*/}
                  <div className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={navigateToChooseSong}
                    >
                      Choose Song
                    </Button>
                  </div>
                </>
              )}

              <div className={classes.queueOuterContainer}>
                <Typography
                  variant="h4"
                  className={classes.title}
                  sx={{
                    marginBottom: '16px'
                  }}
                >
                  Queue Position
                </Typography>
                <div className={classes.textContainer}>
                  {/*todo: need to get queue length*/}
                  <Typography variant="subtitle1" className={classes.subtitle}>
                    Enter a number between 1 and {sessionState?.queue.length} (queue length)
                  </Typography>
                </div>
                <div className={classes.formInputContainer}>
                  <TextField
                    name="queuePosition"
                    label="Queue Position"
                    placeholder="Enter a queue position"
                    className={classes.formInput}
                    error={!!errors.queuePosition}
                    helperText={errors.queuePosition}
                    value={values.queuePosition}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                  />
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    type={'submit'}
                    variant="contained"
                    className={classes.button}
                    disabled={isSubmitting}
                  >
                    Add and close
                  </Button>
                </div>
              </div>

            </Form>
          )}
          </Formik>

      </div>
    </div>
  );
};

AddGuestPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(AddGuestPageStyle)(AddGuestPage);
