import React, { useEffect, useState, useRef } from 'react';
import ConfirmPasswordResetPageStyle from './ConfirmPasswordResetPageStyle';

import {Form, Formik} from "formik";
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, CardContent, Typography, TextField, CardActions } from '@mui/material';
import { handleNetworkError } from 'utils/utils';
import { User } from 'services';
import { withStyles } from '@mui/styles';

const ConfirmPasswordResetPage = (props) => {
  const { classes } = props;
  const passwordResetToken = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required')
      .test('matchValue', 'Password and Confirm Password do not match',
        function () {
          // a normal function is important here and gives us access to "this" which
          // is how we get the value
          return this.parent.password === this.parent.confirmPassword;
        }
      ),
    confirmPassword: Yup.string().required('Confirm Password is required')
      .test('matchValue', 'Password and Confirm Password do not match',
        function () {
          // a normal function is important here and gives us access to "this" which
          // is how we get the value
          return this.parent.password === this.parent.confirmPassword;
        }
      )
  })

  useEffect(() => {
    passwordResetToken.current = location.search.split('=')[1];
    console.log('password reset token', passwordResetToken.current);

    User.verifyPasswordResetToken(passwordResetToken.current)
      .then(response => {
        setValidToken(true);
      })
      .catch(() => {
        setValidToken(false);
      })
  }, [props]);

  const onSubmit = async (values, {setErrors}) => {
    User.confirmPasswordReset(values.password, passwordResetToken.current)
      .then(response => {
        setSubmitted(true);
      })
      .catch(error => handleNetworkError(error, setErrors));
  };

  const redirectUser = () => {
    history.replace('/');
  };

  const invalidTokenScreen = (
    <>
      <Typography variant={'h6'}>
        Your password request has expired.
      </Typography>
    </>
  );

  const validTokenScreen = (
    <>
      <div className={classes.cardForm}>
        <Formik
          initialValues={{
            password: '',
            confirmPassword: ''
          }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnMount={false}
          onSubmit={onSubmit}
        >
          {({
              values,
              errors,
              handleChange
            }) => (
            <Form>
              <div className={classes.inputContainer}>
                <TextField
                  fullWidth
                  name={'password'}
                  label={'New Password'}
                  variant={'outlined'}
                  type={'password'}
                  value={values.password}
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  fullWidth
                  name={'confirmPassword'}
                  label={'Confirm New Password'}
                  variant={'outlined'}
                  type={'password'}
                  value={values.confirmPassword}
                  // intentionally using the password error
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={handleChange}
                />
              </div>
              <Button
                variant={'contained'}
                className={classes.submitButton}
                fullWidth
                type={'submit'}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );

  const passwordChangedScreen = (
    <>
      <div>
        <Typography>Your password has been reset.</Typography>

        <Button
          onClick={() => {history.replace('/')}}
          variant={'contained'}
          style={{marginTop: '12px'}}
        >
          Return to login
        </Button>
      </div>
    </>
  );

  if(validToken === null) {
    return (
      <div className={classes.root}>
        loading...
      </div>
    )
  }
  return (
    <div className={classes.root}>
      <Card>
        <CardContent className={classes.cardContent}>
          <Typography variant={'h6'} align={'center'} className={classes.resetPassword}>
            Reset Password
          </Typography>

          {validToken ? submitted ? passwordChangedScreen : validTokenScreen : invalidTokenScreen}
        </CardContent>

        {(!submitted && !validToken) &&
          <CardActions className={classes.cardActions}>
            <div>
              <Button
                variant={'contained'}
                className={classes.submitButton}
                fullWidth
                onClick={redirectUser}
              >
                Ok
              </Button>
            </div>
          </CardActions>
        }
      </Card>
    </div>
  );
};

export default withStyles(ConfirmPasswordResetPageStyle)(ConfirmPasswordResetPage);
