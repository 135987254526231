const NavbarStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '60px',
    padding: '0 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    minWidth: '200px',
  },

  logo: {
    width: '90px',
    height: '60px',

    '& img': {
      width: '100%',
      height: '100%',
    }
  }
});

export default NavbarStyle;
