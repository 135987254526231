import {toast} from "react-toastify";

export const formatForWebsocket = (type, data) => {
  const message = JSON.stringify({
    type: type,
    content: {
      ...data
    }
  });

  return message;
}

export const getOrdinalIndicator = (number) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const mod100 = number % 100;
  const mod10 = number % 10;
  const suffix = suffixes[(mod10 < 4 && mod100 !== 11 && mod100 !== 12 && mod100 !== 13) ? mod10 : 0];
  return number + suffix;
}

export const handleNetworkError = (error, returnMessage=false, useToast=true) => {
  console.log('error handler error', error, error.response);
  if (
    error.response
    &&
    error.response.data?.non_field_errors
    &&
    error.response.data?.non_field_errors[0]
  ) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    let message = error.response.data?.non_field_errors[0]

    if(useToast) {
      toast.error(message);
    }
    else if(returnMessage) {
      return message;
    }

  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js

    let message = 'An error occurred communicating with the server, ' +
      'please check your internet connection.'

    if(useToast) {
      toast.error(message);
    }
    else if(returnMessage) {
      return message;
    }
  } else {
    // Something happened in setting up the request that triggered an Error

    let message = 'A network error occurred.';

    console.log('Error', error.message);

    if(useToast) {
      toast.error(message);
    }
    else if(returnMessage) {
      return message;
    }
  }
  console.log('network error', error);
}
