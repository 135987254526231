const StripeFormStyle = theme => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    width: '100%'
  },

  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    width: "100%",
  },

  formInput: {
    backgroundColor: "transparent",
    color: 'white',
    borderColor: "#f44336",
    marginBottom: "6px",
    marginLeft: "12px",

    '&:first-of-type': {
      marginLeft: "0px",
    },

    '& .Input': {
      color: 'white'
    },

    '& .MuiInputBase-input': {
      padding: '18px'
    },

    '& .MuiSelect-icon': {
      fill: 'white'
    },

    '& .MuiSvgIcon-root': {
      fill: 'white'
    },


    '&.MuiTextField-root': {
      // '& fieldset': {
      //   borderColor: '#f44336',
      // },
      // '&:hover fieldset': {
      //   borderColor: '#f44336',
      // },
      // '&.Mui-focused fieldset': {
      //   borderColor: '#f44336',
      // },
      // '& .MuiInputBase-input': {
      //   color: "#f44336",
      // },
    },

    [theme.breakpoints.down('575')]: {
      flex: '0',
      flexBasis: '100%',
      margin: '8px 0'
    },
  },

  formInputContainer: {
    width: "100%",
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: 'center',
    margin: '6px 0',


    [theme.breakpoints.down('575')]: {
      flexWrap: "wrap",
      margin: '0'
    },
  },

  input: {
    color: "white",
    fontSize: "16px",
    padding: "11px",

    '&.Mui-disabled fieldset': {
      borderColor: 'white'
    },
  },

  purpleButton: {
    '&.MuiButtonBase-root': {
      textTransform: "none",
      width:"100%",
      height: "52px",
      fontSize: "17px",
      transition: "0.3s",
      marginBottom: ({isInDialog}) => isInDialog ? "16px" : "0px",
      marginTop: "16px",
    },
  },

  cardNumber: {
    flex: 3,

    [theme.breakpoints.down('575')]: {
      flex: '1',
      flexBasis: '100%',
    },
  },

  expDate: {
    flex: 1,

    [theme.breakpoints.down('575')]: {
      flex: '1',
      flexBasis: 'calc(50% - 8px)',
    },
  },

  cvc: {
    flex: 1,

    [theme.breakpoints.down('575')]: {
      flex: '1',
      flexBasis: 'calc(50% - 8px)',
      marginLeft: '16px'
    },
  },

  city: {
    flex: 3,

    [theme.breakpoints.down('575')]: {
      flex: '1',
      flexBasis: 'calc(50% - 8px)',
      marginLeft: '16px'
    },
  },

  state: {
    flex: 2,

    [theme.breakpoints.down('575')]: {
      flex: '1',
      flexBasis: 'calc(50% - 8px)',
      marginLeft: '16px'
    },
  },

  zipCode: {
    flex: 2,

    [theme.breakpoints.down('575')]: {
      flex: '1',
      flexBasis: '100%',
    },
  },

  formErrorMessage: {
    color: "red",
    marginBottom: "16px"
  },

  inputRoot: {
    '&.Mui-error fieldset': {
      borderColor: '#ED3E3B !important'
    },
    '&.Mui-disabled:not(.Mui-error) fieldset': {
      borderColor: 'white !important',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
    '& .MuiInputBase-input': {
      color: "black",
    },
  },

});

export default StripeFormStyle;
