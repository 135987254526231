const HostCreateAccountPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    justifyContent: 'center',
  },
  logo: {
    maxWidth: 300,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: `${theme.spacing(4)} !important`,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    maxWidth: 400,
  },
  formInputContainer: {
    marginBottom: theme.spacing(2),
  },
  formInput: {
    '& .MuiInputBase-input': {
      fontSize: '1.2rem',
    },
  },
  createAccountButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  goBackButton: {
    marginTop: '16px !important',
  }
});

export default HostCreateAccountPageStyle;