import React, {useContext, useEffect, useState} from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CreateSessionPageStyle from './CreateSessionPageStyle';
import { Button, IconButton, MenuItem, Switch, TextField, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import cn from "classnames";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {SessionContext} from "context/SessionContext";
import * as yup from "yup";
import dayjs from "dayjs";
import {MobileTimePicker} from "@mui/x-date-pickers";
import { Session, Venue } from 'services';
import { UserContext } from 'context/UserContext';
import { handleNetworkError } from 'utils/utils';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreateSessionPage = props => {
  const { classes } = props;

  const [venues, setVenues] = useState([]);
  const [allVenues, setAllVenues] = useState([]);
  const [busy, setBusy] = useState(false);

  const {sessionData} = useContext(SessionContext);
  const {userData} = useContext(UserContext);

  const history = useHistory();

  const types = ['Karaoke', 'Other'];

  useEffect(() => {
    Venue.listAll()
      .then(response => {
        console.log('List all venue success', response)
        setVenues(response.data.map((elem)=> {
          return elem.name
        }))
        setAllVenues(response.data)
      })
      .catch(error => {
        console.log('List all venue error', error)

      })
  }, [])

  const handleNumericChange = (setFieldValue, fieldName) => (e) => {
    const val = e.target.value;
    const numericVal = val.replace(/[^0-9]/g, '');
    if (!isNaN(numericVal) && numericVal.length > 0) {
      const formattedValue = formatNumber(numericVal);
      setFieldValue(fieldName, formattedValue)
    } else {
      setFieldValue(fieldName, '')
    }
  };

  const handleCreateVenue = () => {
    history.push('/create-venue');
  }

  const formatNumber = (value) => {
    // ensures the value is a valid integer, returns a string representation with "$" prefix
    if(value === undefined || value === null || value === '') {
      return '';
    }

    // parse as an integer to remove any leading zeroes
    const number = parseInt(value, 10);

    // check if the parsing succeeded, if not return an empty string
    if (isNaN(number)) {
      return '';
    }

    // return the number as a string with a "$" prefix
    return "$" + number.toString();
  };


  const validationSchema = Yup.object().shape({
    date: yup.date().required('Date is required'),
    startTime: yup.date().required('Start time is required'),
    endTime: yup.date()
      .required('End time is required')
      .test('is-greater', 'End time must be later than start time', function(value) {
        const { startTime } = this.parent;
        return dayjs(value).isAfter(dayjs(startTime));
      }),
    venue: yup.string().required('Venue is required'),
    type: yup.string().required('Type is required'),
  });

  const onSubmit = async (values, {setErrors}) => {
    setBusy(true);

    console.log('values', values);

    console.log('userData', userData);

    let data = {};

    let timeFields = ['startTime', 'endTime'];
    let datefields = ['date'];
    let currencyFields = ['bumpToTopFee', 'bumpUpFee', 'bumpDownFee', 'entryFee'];

    for(let key of Object.keys(values)) {
      if(timeFields.includes(key)) {
        data[key] = values[key].format('HH:mm:ss');
      }
      else if(datefields.includes(key)) {
        data[key] = values[key].format('YYYY-MM-DD');
      }
      else if(currencyFields.includes(key)) {
        let parsedValue = parseFloat(values[key].replace(/[^0-9]/g, ''));

        console.log('currency parsed value', parsedValue);
        data[key] = parsedValue;
      }
      else if(key === 'venue') {
        let venue = allVenues.find((elem) => elem.name === values[key]);
        data[key] = venue?.id;
      }
      else {
        data[key] = values[key];
      }
    }

    data.hostUser = userData?.id;

    // rename the startTime field to time
    data.time = data.startTime;
    delete data.startTime;

    console.log('create session', data);

    if (data?.type === 'Karaoke' && userData?.hostSongList?.length === 0) {
      toast.error('You must have at least one song in your song list to create a karaoke session. ' +
        'Add songs to your song list under your account settings or select "Other" as the type.');
      return;
    }

    Session.create(data)
      .then(response => {
        console.log('create session success', response);
        toast.success('Session created successfully');
        history.push('/host-queue', { data: response.data });
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false));
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerContent}>
        <Typography
          variant="h4"
          className={classes.title}
          sx={{
            marginBottom: '32px'
          }}
        >
          Create New Session
        </Typography>

       <Formik
           initialValues={{
             date: sessionData?.date || dayjs(),
             venue: sessionData?.venue || null,
             entryFee: sessionData?.entryFee || "",
             bumpUpFee: sessionData?.bumpUpFee || "",
             bumpDownFee: sessionData?.bumpDownFee || "",
             bumpToTopFee: sessionData?.bumpToTopFee || "",
             type: sessionData?. type || '',
             startTime: sessionData?.date ? dayjs(sessionData?.date) : dayjs(),
             endTime:sessionData?.date || dayjs().add(3, 'hour'),
             feeToggled: false,
             allowTipsToHost: false,
             allowMessagesToUsers: false,
             allowMessagesToHost: false,
             sameSongToggled: false,
             maximumNumberOfBumps: 3,
             maximumNumberOfTimesAUserCanJoin: 20,
             enableScoreboard: true,
             enableSessionId: false,
           }}
           onSubmit={onSubmit}
           validateOnChange={false}
           validationSchema={validationSchema}
         >
           {({
               values,
               errors,
               handleChange,
               setFieldValue,
               handleSubmit,
           }) => (
           <Form className={classes.form}>
             <div>
               <div className={classes.formInputContainer}>
                 <MobileDatePicker
                   label="Enter session date"
                   inputFormat="MM/DD/YYYY"
                   variant="outlined"
                   color={"secondary"}
                   disablePast={true}
                   value={values.date}
                   onChange={(newValue) => {
                     console.log('on change new value', newValue)
                     setFieldValue('date', newValue)
                   }}
                   renderInput={(params) => (
                     <TextField
                       className={classes.formInput}
                       error={false}
                       fullWidth
                       variant="outlined"
                     />
                   )}
                 />
               </div>
               <div className={cn(classes.formInputContainer, classes.rowContainer)}>
                 <Autocomplete
                   freeSolo
                   options={venues}
                   value={values.venue}
                   className={classes.fullWidthAutocomplete}
                   onChange={(event, newValue) => {
                     setFieldValue('venue', newValue)
                   }}
                   renderInput={(params) => (
                     <TextField
                       {...params}
                       name="venue"
                       label="Venue"
                       placeholder="Enter venue or choose from list"
                       className={classes.formInput}
                       error={!!errors.venue}
                       helperText={errors.venue}
                       fullWidth
                       variant="outlined"
                     />
                   )}
                 />

                 <IconButton color={'primary'} onClick={handleCreateVenue}>
                   <AddBoxIcon/>
                 </IconButton>
               </div>
               <div className={classes.formInputContainer}>
                 <MobileTimePicker
                   label="Enter session start time"
                   inputFormat="hh:mm A"
                   variant="outlined"
                   color={"secondary"}
                   value={values.startTime}
                   onChange={(newValue) => {
                     setFieldValue('startTime', newValue)
                   }}
                   renderInput={(params) => (
                     <TextField
                       className={classes.formInput}
                       error={!!errors.startTime}
                       helperText={errors.startTime}
                       fullWidth
                       variant="outlined"
                     />
                   )}
                 />
               </div>
               <div className={classes.formInputContainer}>
                 <MobileTimePicker
                   label="Enter session end time"
                   inputFormat="hh:mm A"
                   variant="outlined"
                   color={"secondary"}
                   value={values.endTime}
                   onChange={(newValue) => {
                     setFieldValue('endTime', newValue)
                   }}
                   renderInput={(params) => (
                     <TextField
                       className={classes.formInput}
                       error={!!errors.endTime}
                       helperText={errors.endTime}
                       fullWidth
                       variant="outlined"
                     />
                   )}
                 />
               </div>
               <div className={classes.formInputContainer}>
                 <TextField
                   name="type"
                   label="Type"
                   placeholder="Choose session type from list"
                   className={classes.formInput}
                   error={!!errors.type}
                   helperText={errors.type}
                   value={values.type}
                   onChange={handleChange}
                   fullWidth
                   variant="outlined"
                   select
                 >
                   {types.map((type) => (
                     <MenuItem key={type} value={type}>
                       {type}
                     </MenuItem>
                   ))}
                 </TextField>

                 {values.type === 'Karaoke' && (
                   <div className={classes.songSwitchContainer}>
                     <Switch
                       checked={values.sameSongToggled}
                       onChange={() => setFieldValue('sameSongToggled', !values.sameSongToggled)}
                     />
                     <Typography variant="subtitle1" className={classes.subtitle}>
                       Allow same song to be performed more than once?
                     </Typography>
                   </div>
                 )}
               </div>
               <div className={classes.formInputContainer}>
                 <TextField
                   name="maximumNumberOfBumps"
                   label="Maximum Number of Bumps"
                   placeholder="3"
                   className={classes.formInput}
                   error={!!errors.maximumNumberOfBumps}
                   helperText={errors.maximumNumberOfBumps}
                   value={values.maximumNumberOfBumps}
                   onChange={handleChange}
                   fullWidth
                   variant="outlined"
                 />
               </div>
               <div className={classes.formInputContainer}>
                 <TextField
                   name="maximumNumberOfTimesAUserCanJoin"
                   label="Maximum Number of Times a User Can Join"
                   placeholder="3"
                   className={classes.formInput}
                   error={!!errors.maximumNumberOfTimesAUserCanJoin}
                   helperText={errors.maximumNumberOfTimesAUserCanJoin}
                   value={values.maximumNumberOfTimesAUserCanJoin}
                   onChange={handleChange}
                   fullWidth
                   variant="outlined"
                 />
               </div>
               <div className={cn(classes.formInputContainer, classes.formInputSwitchContainer)}>
                 <Switch
                   checked={values.enableSessionId}
                   onChange={() => setFieldValue('enableSessionId', !values.enableSessionId)}
                 />
                 <Typography variant="subtitle1" className={classes.subtitle}>
                   Enable session ID?
                 </Typography>
               </div>
               <div className={cn(classes.formInputContainer, classes.formInputSwitchContainer)}>
                 <Switch
                   checked={values.enableScoreboard}
                   onChange={() => setFieldValue('enableScoreboard', !values.enableScoreboard)}
                 />
                 <Typography variant="subtitle1" className={classes.subtitle}>
                   Enable the scoreboard?
                 </Typography>
               </div>

               <div className={classes.sectionContainer}>
                 <Typography
                   variant="h4"
                   className={classes.title}
                   sx={{
                     marginBottom: '32px'
                   }}
                 >
                   Fees
                 </Typography>

                 <div className={classes.formInputContainer}>
                   <TextField
                     name="entryFee"
                     label="Entry fee"
                     placeholder="Enter fee"
                     className={classes.formInput}
                     error={!!errors.entryFee}
                     helperText={errors.entryFee}
                     value={values.entryFee}
                     //onChange={handleChange}
                     onChange={handleNumericChange(setFieldValue, 'entryFee')}
                     fullWidth
                     variant="outlined"
                   />
                 </div>
                 <div className={classes.switchContainer}>
                   <Switch
                     checked={values.feeToggled}
                     onChange={() => setFieldValue('feeToggled', !values.feeToggled)}
                   />
                   <Typography variant="subtitle1" className={classes.subtitle}>
                     Allow bump fees?
                   </Typography>
                 </div>
                 {values.feeToggled && (
                   <div className={classes.columnContainer}>
                     <div className={classes.formInputContainer}>
                       <TextField
                         name="bumpUpFee"
                         label="Bump up"
                         placeholder="Enter fee"
                         className={classes.formInput}
                         error={!!errors.bumpUpFee}
                         helperText={errors.bumpUpFee}
                         value={values.bumpUpFee}
                         onChange={handleNumericChange(setFieldValue, 'bumpUpFee')}
                         fullWidth
                         variant="outlined"
                       />
                     </div>
                     <div className={classes.formInputContainer}>
                       <TextField
                         name="bumpDownFee"
                         label="Bump down"
                         placeholder="Enter fee"
                         className={classes.formInput}
                         error={!!errors.bumpDownFee}
                         helperText={errors.bumpDownFee}
                         value={values.bumpDownFee}
                         onChange={handleNumericChange(setFieldValue, 'bumpDownFee')}
                         fullWidth
                         variant="outlined"
                       />
                     </div>
                     <div>
                       <TextField
                         name="bumpToTopFee"
                         label="Bump to top"
                         placeholder="Enter fee"
                         className={classes.formInput}
                         error={!!errors.bumpToTopFee}
                         helperText={errors.bumpToTopFee}
                         value={values.bumpToTopFee}
                         onChange={handleNumericChange(setFieldValue, 'bumpToTopFee')}
                         fullWidth
                         variant="outlined"
                       />
                     </div>
                   </div>
                 )}

               </div>

               <div className={classes.sectionContainer}>
                 <Typography
                   variant="h4"
                   className={classes.title}
                   sx={{
                     marginBottom: '32px'
                   }}
                 >
                   Tipping
                 </Typography>

                 <div className={classes.switchContainer}>
                   <Switch
                     checked={values.allowTipsToHost}
                     onChange={() => setFieldValue('allowTipsToHost', !values.allowTipsToHost)}
                   />
                   <Typography variant="subtitle1" className={classes.subtitle}>
                     Allow tipping to Host?
                   </Typography>
                 </div>

               </div>

               {/* TODO: re-activate in step 2 */}
               {/*<div className={classes.sectionContainer}>*/}
               {/*  <Typography*/}
               {/*    variant="h4"*/}
               {/*    className={classes.title}*/}
               {/*    sx={{*/}
               {/*      marginBottom: '32px'*/}
               {/*    }}*/}
               {/*  >*/}
               {/*    Messaging*/}
               {/*  </Typography>*/}

               {/*  <div className={classes.switchContainer}>*/}
               {/*    <Switch*/}
               {/*      checked={values.allowMessagesToUsers}*/}
               {/*      onChange={() => setFieldValue('allowMessagesToUsers', !values.allowMessagesToUsers)}*/}
               {/*    />*/}
               {/*    <Typography variant="subtitle1" className={classes.subtitle}>*/}
               {/*      Allow messages to users?*/}
               {/*    </Typography>*/}
               {/*  </div>*/}

               {/*  <div className={cn(classes.switchContainer, classes.marginTopContainer)}>*/}
               {/*    <Switch*/}
               {/*      checked={values.allowMessagesToHost}*/}
               {/*      onChange={() => setFieldValue('allowMessagesToHost', !values.allowMessagesToHost)}*/}
               {/*    />*/}
               {/*    <Typography variant="subtitle1" className={classes.subtitle}>*/}
               {/*      Allow messages from users?*/}
               {/*    </Typography>*/}
               {/*  </div>*/}
               {/*</div>*/}

               <div className={classes.buttonContainer}>
                 <Button
                   type="submit"
                   variant="contained"
                   className={classes.button}
                   disabled={busy}
                   onClick={handleSubmit}
                 >
                   Create Session
                 </Button>
               </div>
             </div>
           </Form>
             )}
       </Formik>

      </div>

    </div>
);
};

CreateSessionPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(CreateSessionPageStyle)(CreateSessionPage);
