const HostLoginPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: theme.spacing(2),
  },
  logo: {
    maxWidth: 300,
    marginBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 400,
  },
  formInputContainer: {
    marginBottom: theme.spacing(2),
  },
  formInput: {
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: theme.spacing(1),
    width: '100%',
  },
  loginButton: {
    backgroundColor: '#f04124',
    color: 'white',
    borderRadius: 4,
    padding: theme.spacing(1),
    width: '100%',
    border: 'none',
    '&:hover': {
      backgroundColor: '#a12010',
      cursor: 'pointer',
    },
  },
  signUpButton: {
    marginTop: '16px !important'
  },
});

export default HostLoginPageStyle;