import React, { useContext, useEffect, useState } from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import TipHostPageStyle from './TipHostPageStyle';
import {Button, Typography} from "@mui/material";
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { SessionContext } from 'context/SessionContext';

const TipHostPage = props => {
  const { classes } = props;

  const [currentSessionData, setCurrentSessionData] = useState(null);
  const [tipCounted, setTipCounted] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const {
    sessionState,
    sessionActions,
  } = useContext(SessionContext);

  const userData = useContext(UserContext);

  useEffect(() => {
    // get the session data from the location
    const data = location?.state?.data;
    setCurrentSessionData(data);
    console.log('current session data', data)
  }, [location])

  useEffect(() => {
    if(currentSessionData && !tipCounted) {
      console.log('count tip')
      sessionActions.countTip(currentSessionData.id);
      setTipCounted(true);
    }
  }, [currentSessionData])

  const handleClose = () => {
    history.push('/performer-queue', { data: currentSessionData });
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerContent}>
        <Typography variant="h4" className={classes.title}>
          Send a Tip
        </Typography>

        <Typography variant="h4" className={classes.title}>
          To {currentSessionData?.host}
        </Typography>

        <Typography variant="subtitle1" className={classes.subtitle}>
          You can tip the host via these links:
        </Typography>

        <div className={classes.linkContainer}>
          {currentSessionData?.hostVenmoLink && (
            <a href={currentSessionData?.hostVenmoLink} target="_blank" rel="noreferrer">
              Venmo
            </a>
          )}

          {currentSessionData?.hostPaypalLink && (
            <a href={currentSessionData?.hostPaypalLink} target="_blank" rel="noreferrer">
              PayPal
            </a>
          )}
        </div>


        <div className={classes.buttonContainer}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

TipHostPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(TipHostPageStyle)(TipHostPage);
