import React, {createContext, useEffect, useState} from 'react';
import { User } from 'services';
import { handleNetworkError } from 'utils/utils';
import Cookies from "universal-cookie";
import {useHistory} from "react-router-dom";


export const UserContext = createContext();

const UserProvider = ({children}) => {
  const [userData, setUserData] = useState(null);
  const [isLogin, setIsLogin] = useState(false);
  const history = useHistory();

  useEffect(() => {
    console.log('user data changed in context', userData);
    if(isLogin) {
      setIsLogin(false);
      history.push('/lobby');
    }
  }, [userData])

  useEffect(() => {
    console.log('attempting to read cookies...')

    User.getMe()
      .then(response => {
        console.log('user data response', response);
        setUserData(response.data);
      })
      .catch(error => {
        setUserData(false);
        handleNetworkError(error, false, false)
      })
  }, []);

  const userLogin = (response) => {
    console.log('context login success!', response);
    setIsLogin(true);

    setUserData(response.data.user);

    const cookies = new Cookies();
    cookies.set('token', response.data.user.token, { path: '/' });

    //history.push('/lobby');
  }

  const values = {
    userData,
    setUserData,
    userLogin
  }

  return (
    <UserContext.Provider value={values}>
      {children}
    </UserContext.Provider>
  );
};


export default UserProvider;
