import React, {useContext, useEffect, useState} from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import HostAccountSettingsPageStyle from './HostAccountSettingsPageStyle';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button, FormControlLabel,
  IconButton, Radio,
  TextField,
  Typography
} from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import {Form, Formik} from "formik";
import CloseIcon from "@mui/icons-material/Close";
import {UserContext} from "context/UserContext";
import {useHistory} from "react-router-dom";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { User } from 'services';
import { handleNetworkError } from 'utils/utils';
import InputMask from 'react-input-mask';

const HostAccountSettingsPage = props => {
  const { classes } = props;

  const {userData, setUserData} = useContext(UserContext);
  const history = useHistory();

  const [stageName, setStageName] = useState('');
  const [socialMediaLink, setSocialMediaLink] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [avatarURL, setAvatarURL] = useState(userData?.profilePicture);

  // for account details
  const [accountDetailsPhoneEditable, setAccountDetailsPhoneEditable] = useState(false);
  const [accountDetailsEmailEditable, setAccountDetailsEmailEditable] = useState(false);
  const [accountDetailsPayPalEditable, setAccountDetailsPayPalEditable] = useState(false);
  const [accountDetailsVenmoEditable, setAccountDetailsVenmoEditable] = useState(false);

  const hiddenFileInput = React.useRef(null);


  useEffect(() => {
    console.log('account settings user data', userData);

    if(userData) {
      setStageName(userData.stageName);
      setSocialMediaLink(userData.socialMediaLink);

      if(userData?.profilePicture) {
        setAvatarURL(userData.profilePicture);
      }
    }
    else if(userData === false) {
      history.push('/host');
    }
  }, [userData])

  const tippingValidationSchema = Yup.object().shape({
    paypal: Yup.string()
      .required('Email is required'),
    venmo: Yup.string()
      .required('Email is required'),
  });

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .min(17, 'Phone number is not filled out correctly')
      .max(17, 'Phone number is not filled out correctly'),
    email: Yup.string()
      .email('Invalid email address'),
    currentPassword: Yup.string(),
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .max(30, 'Password must be at most 30 characters long'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  });

  const handleFileUploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setAvatarURL(reader.result);
    };
    reader.readAsDataURL(file);

    sendFileToServer(file);
  };

  const sendFileToServer = (file) => {
    const formData = new FormData();
    formData.append('profile_picture', file);

    console.log('send to server', formData)

    User.changeMyProfilePicture(formData)
      .then(response => {
        console.log('change my profile picture response: ', response)
        toast.success('Profile picture updated successfully');
      })
      .catch(handleNetworkError)
  };

  const handleSavePersonalDetails = () => {
    User.updateMe({stageName, socialMediaLink})
      .then(response => {
        toast.success('Personal details updated successfully');

        let newUserData = {...userData};

        if(stageName) {
          newUserData.stageName = stageName;
        }

        if(socialMediaLink) {
          newUserData.socialMediaLink = socialMediaLink;
        }
      })
      .catch(handleNetworkError)
  };

  const handlePhoneEdit = () => {
    console.log('edit phoneNumber')
    setAccountDetailsPhoneEditable(!accountDetailsPhoneEditable)
  }

  const handleEmailEdit = () => {
    console.log('edit email')
    setAccountDetailsEmailEditable(!accountDetailsEmailEditable)
  }

  const handlePayPalEdit = () => {
    console.log('edit paypal')
    setAccountDetailsPayPalEditable(!accountDetailsPayPalEditable)
  }

  const handleVenmoEdit = () => {
    console.log('edit venmo')
    setAccountDetailsVenmoEditable(!accountDetailsVenmoEditable)
  }

  const onSubmitAccountDetails = async (values, {setErrors}) => {
    console.log('submit account details: ', values)
    User.updateMe(values)
      .then(response => {
        toast.success('Account details updated successfully');

        let newUserData = {...userData};

        if(values.phoneNumber) {
          newUserData.phoneNumber = values.phoneNumber;
        }

        if(values.email) {
          newUserData.email = values.email;
        }

        setUserData(newUserData);
      })
      .catch(handleNetworkError)
  };

  const onSubmitTipping = async (values, {setErrors}) => {
    console.log('submit tipping details: ', values)
    User.updateMe(values)
      .then(response => {
        toast.success('Tipping details updated successfully');
      })
      .catch(handleNetworkError)
  };

  return (
    <div className={classes.root}>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{display: 'none'}}
      />

      <Typography variant="h4" className={classes.title}>
        Account Settings
      </Typography>

      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          <Typography variant="subtitle1" className={classes.accordionTitle}>
            Personal Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.avatarContainer}>
            <IconButton
              className={classes.avatarEditButton}
              size={'small'}
              onClick={handleFileUploadClick}
            >
              <EditIcon sx={{color: 'white', fontSize: '18px'}}/>
            </IconButton>
            <Avatar
              alt="Profile picture"
              src={avatarURL}
              className={classes.avatar}
            />
          </div>

          <div className={classes.inputContainer}>
            <TextField
              label="Stage Name"
              variant="outlined"
              fullWidth
              value={stageName}
              onChange={(e) => {setStageName(e.target.value)}}
              className={classes.textField}
            />
            <TextField
              label="Social Media Link"
              variant="outlined"
              fullWidth
              value={socialMediaLink}
              onChange={(e) => {setSocialMediaLink(e.target.value)}}
              className={classes.textField}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSavePersonalDetails}
              className={classes.saveButton}
            >
              Save
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          <Typography variant="subtitle1" className={classes.accordionTitle}>
            Account Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Formik
            initialValues={{
              phoneNumber: userData?.phoneNumber || '',
              email: userData?.email || '',
              password: '',
              newPassword: '',
              confirmNewPassword: '',
            }}
            enableReinitialize
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={onSubmitAccountDetails}
          >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
              <Form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.textFieldContainer}>
                  {accountDetailsPhoneEditable ? (
                    <InputMask
                      mask={'+1 (999) 999-9999'}
                      onChange={handleChange}
                      value={values.phoneNumber}
                      className={classes.textField}
                    >
                      {() => (
                        <TextField
                          label="Phone"
                          variant="outlined"
                          fullWidth
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                className={classes.editButton}
                                size={'small'}
                                onClick={handlePhoneEdit}
                              >
                                {accountDetailsPhoneEditable ?
                                  <CloseIcon sx={{color: 'white', fontSize: '18px'}}/>
                                  :
                                  <EditIcon sx={{color: 'white', fontSize: '18px'}}/>
                                }
                              </IconButton>
                            ),
                          }}
                          className={classes.textField}
                        />
                      )}
                    </InputMask>
                  ) : (
                    <TextField
                      name={"phoneNumber"}
                      type="text"
                      label="Phone Number"
                      fullWidth
                      variant="outlined"
                      disabled
                      className={classes.textField}
                      value={values.phoneNumber}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            className={classes.editButton}
                            size={'small'}
                            onClick={handlePhoneEdit}
                          >
                            <EditIcon sx={{color: 'white', fontSize: '18px'}}/>
                          </IconButton>
                        ),
                      }}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber}
                    />
                  )}
                </div>
                <div className={classes.textFieldContainer}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    disabled={!accountDetailsEmailEditable}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          className={classes.editButton}
                          size={'small'}
                          onClick={handleEmailEdit}
                        >
                          {accountDetailsEmailEditable ?
                            <CloseIcon sx={{color: 'white', fontSize: '18px'}}/>
                            :
                            <EditIcon sx={{color: 'white', fontSize: '18px'}}/>
                          }
                        </IconButton>
                      ),
                    }}
                    className={classes.textField}
                  />
                </div>
                <div className={classes.textFieldContainer}>
                  <TextField
                    label="Current Password"
                    variant="outlined"
                    fullWidth
                    name="password"
                    type={'password'}
                    value={values.password}
                    onChange={handleChange}
                    className={classes.textField}
                    error={!!errors.password}
                    helperText={errors.password}
                  />
                </div>
                <div className={classes.textFieldContainer}>
                  <TextField
                    label="New Password (optional)"
                    variant="outlined"
                    fullWidth
                    name="newPassword"
                    type={'password'}
                    value={values.newPassword}
                    onChange={handleChange}
                    className={classes.textField}
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                  />
                </div>
                <div className={classes.textFieldContainer}>
                  <TextField
                    label="Re-enter New Password (optional)"
                    variant="outlined"
                    fullWidth
                    name="confirmNewPassword"
                    type={'password'}
                    value={values.confirmNewPassword}
                    onChange={handleChange}
                    className={classes.textField}
                    error={!!errors.confirmNewPassword}
                    helperText={errors.confirmNewPassword}
                  />
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  type={'submit'}
                  size={'small'}
                  className={classes.saveButton}
                >
                  Save
                </Button>
              </Form>
            )}
          </Formik>

        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          <Typography variant="subtitle1" className={classes.accordionTitle}>
            Song Management
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Button
            variant={'contained'}
            onClick={() => {history.push('/manage-song')}}
          >
            Manage Songs
          </Button>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          <Typography variant="subtitle1" className={classes.accordionTitle}>
            Tipping Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Formik
            initialValues={{
              paypal: userData?.paypal || '',
              venmo: userData?.venmo || '',
            }}
            enableReinitialize
            validateOnChange={false}
            validationSchema={tippingValidationSchema}
            onSubmit={onSubmitTipping}
          >
            {({
                values,
                errors,
                handleChange,
                setFieldValue
              }) => (
              <Form  className={classes.form}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Enter your details that Performers and Voters will see when tipping you.
                </Typography>

                <div className={classes.textFieldContainer}>
                  <TextField
                    label="PayPal"
                    variant="outlined"
                    fullWidth
                    disabled={!accountDetailsPayPalEditable}
                    name="paypal"
                    value={values.paypal}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          className={classes.editButton}
                          size={'small'}
                          onClick={handlePayPalEdit}
                        >
                          {accountDetailsPayPalEditable ?
                            <CloseIcon sx={{color: 'white', fontSize: '18px'}}/>
                            :
                            <EditIcon sx={{color: 'white', fontSize: '18px'}}/>
                          }
                        </IconButton>
                      ),
                    }}
                    className={classes.textField}
                  />
                </div>

                <div className={classes.textFieldContainer}>
                  <TextField
                    label="Venmo"
                    variant="outlined"
                    fullWidth
                    disabled={!accountDetailsVenmoEditable}
                    name="venmo"
                    value={values.venmo}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          className={classes.editButton}
                          size={'small'}
                          onClick={handleVenmoEdit}
                        >
                          {accountDetailsVenmoEditable ?
                            <CloseIcon sx={{color: 'white', fontSize: '18px'}}/>
                            :
                            <EditIcon sx={{color: 'white', fontSize: '18px'}}/>
                          }
                        </IconButton>
                      ),
                    }}
                    className={classes.textField}
                  />
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  type={'submit'}
                  size={'small'}
                  className={classes.saveButton}
                >
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </AccordionDetails>
      </Accordion>

    </div>
  );
};

HostAccountSettingsPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(HostAccountSettingsPageStyle)(HostAccountSettingsPage);
