const CreateAccountPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    justifyContent: 'center',
  },
  logo: {
    maxWidth: 300,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: `${theme.spacing(4)} !important`,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    maxWidth: 400,
    marginBottom: '40px',
    paddingBottom: '40px',
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
  },
  formInputContainer: {
    marginBottom: theme.spacing(2),
  },
  formInput: {
    '& .MuiInputBase-input': {
      fontSize: '1.2rem',
    },
  },
  createAccountButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  votersSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  continueButton: {
    backgroundColor: '#83c75d',
    color: 'white',
    borderRadius: 4,
    padding: theme.spacing(1),
    width: '100%',
    border: 'none',
    '&:hover': {
      backgroundColor: '#619d3f',
      cursor: 'pointer',
    },
  },
  goBackButton: {
    marginTop: '16px !important',
  }
});

export default CreateAccountPageStyle;
