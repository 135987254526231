import React, {useContext} from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import HostCreateAccountPageStyle from './HostCreateAccountPageStyle';
import {useHistory} from "react-router-dom";
import {UserContext} from "context/UserContext";
import * as Yup from "yup";
import {User} from "services";
import Cookies from "universal-cookie";
import {handleNetworkError} from "utils/utils";
import logo from 'assets/duq-logo.jpeg';
import {Button, TextField, Typography} from "@mui/material";
import {Form, Formik} from "formik";

const HostCreateAccountPage = props => {
  const { classes } = props;
  const history = useHistory();

  const {userData, setUserData} = useContext(UserContext);

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    stageName: ''
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
    password: Yup.string()
      .min(8, 'Must be at least 8 characters')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    console.log(values);

    User.create(values.email, values.password, values.stageName, true)
      .then(response => {
        console.log('create account success!', response);

        const cookies = new Cookies();
        cookies.set('token', response.data.user.token, { path: '/' });

        //TODO: NEED TO ROUTE TO THE CORRECT PLACE

        history.push('/lobby');

        setUserData(response.data.user);

        history.push('/lobby');
      })
      .catch((error) => handleNetworkError(error));

    setSubmitting(false);
  };

  return (
    <div className={classes.root}>
      <img src={logo} alt="Logo" className={classes.logo} />
      <Typography variant="h5" className={classes.title}>
        Host Sign Up
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, isSubmitting }) => (
          <Form className={classes.form}>
            <div className={classes.formInputContainer}>
              <TextField
                type="text"
                name="email"
                label="Email"
                placeholder="Email"
                className={classes.formInput}
                error={!!errors.email}
                helperText={errors.email}
                value={values.email}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </div>

            <div className={classes.formInputContainer}>
              <TextField
                type={'text'}
                name={'stageName'}
                label={'Stage Name'}
                placeholder={'Stage Name'}
                className={classes.formInput}
                error={!!errors.stageName}
                helperText={errors.stageName}
                value={values.stageName}
                onChange={handleChange}
                fullWidth
                variant={'outlined'}
              />
            </div>

            <div className={classes.formInputContainer}>
              <TextField
                type="password"
                name="password"
                label="Password"
                placeholder="Password"
                className={classes.formInput}
                error={!!errors.password}
                helperText={errors.password}
                value={values.password}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </div>

            <div className={classes.formInputContainer}>
              <TextField
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Confirm Password"
                className={classes.formInput}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                value={values.confirmPassword}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </div>

            <Button
              type="submit"
              variant="contained"
              className={classes.createAccountButton}
              disabled={isSubmitting}
            >
              CREATE ACCOUNT
            </Button>

            <Button
              variant="contained"
              className={classes.goBackButton}
              disabled={isSubmitting}
              onClick={() => history.push('/host')}
            >
              GO BACK
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

HostCreateAccountPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(HostCreateAccountPageStyle)(HostCreateAccountPage);
