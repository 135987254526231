import {TextField} from "@mui/material";
import StripeInput from "components/StripeInput/StripeInput";
import React from 'react';
import PropTypes from 'prop-types';

const StripeTextField = props => {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    labelErrorMessage,
    stripeElement,
    ...other
  } = props;

  return (
    <TextField
      fullWidth
      variant={'outlined'}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true
      }}
      error={error}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement
        },
        inputComponent: StripeInput
      }}
      helperText={error ? labelErrorMessage : helperText}
      {...other}
    />
  );
};

StripeTextField.propTypes = {
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  error: PropTypes.string,
  helperText: PropTypes.string,
  labelErrorMessage: PropTypes.string,
  stripeElement: PropTypes.func,
};

export default StripeTextField;
