import React, { useContext, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardCvcElement, CardElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { Button, TextField, makeStyles } from '@mui/material';
import { withStyles } from '@mui/styles';

import BillingFormStyles from './BillingFormStyles';
import { StripeContext } from 'context/StripeContext';

const BillingForm = props => {
  const { classes, stripe, elements } = props;

  useEffect(() => {
    console.log('billing form props changed', props);
  }, [props]);

  const handleSubmit = async (event, stripe, elements) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    // TODO: Implement stripe checkout logic
  };

  return (
    <form onSubmit={(event) => handleSubmit(event, stripe, elements)} className={classes.form}>
      <TextField label="Name on card" className={classes.textField} />
      <CardElement options={{style: {base: {fontSize: '16px'}}}} />
      <Button type="submit" disabled={!stripe} className={classes.button}>
        Pay
      </Button>
    </form>
  );
};

export default withStyles(BillingFormStyles)(BillingForm);
