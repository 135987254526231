import React, { useState, useEffect, useRef, useContext } from 'react';

import {
  withStyles
} from '@mui/styles';
import PropTypes from 'prop-types';

import NewSessionTokenDialogStyle from './NewSessionTokenDialogStyle';
import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Session } from 'services';
import { handleNetworkError } from 'utils/utils';
import { useHistory } from 'react-router-dom';
import { SessionContext } from 'context/SessionContext';

const NewSessionTokenDialog = props => {
  const {
    classes,
    open,
    setOpen,
    onSuccess,
  } = props;

  const [sessionCode, setSessionCode] = useState('');
  const [sessionId, setSessionId] = useState(null);

  const { sessionState, setSessionState, currentSessionData } = useContext(SessionContext);
  const history = useHistory();

  const inputRef = useRef(null);

  useEffect(() => {
    console.log('new session token dialog', {currentSessionData})

    if(currentSessionData?.id) {
      setSessionId(currentSessionData?.id);
    }
  }, [currentSessionData])

  useEffect(() => {
    if(sessionCode.length === 4 && currentSessionData) {
      attemptToEnterSession();
    }
  }, [sessionCode, currentSessionData]);

  const attemptToEnterSession = () => {
    console.log('dialog verify session code', {sessionCode, sessionId: sessionId});

    Session.verifyCode(sessionCode, sessionId).then(response => {
      console.log('dialog verify code response', response);

      setSessionState(response.data);

      console.log('code verification success')
      setOpen(false);
      onSuccess();
    }).catch(error => {
      console.log('dialog verify code error', error);
    });
  }

  const handleInput = event => {
    const { value } = event.target;
    setSessionCode(value.toUpperCase().slice(0, 4));
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <DialogTitle sx={{paddingLeft: 0}}>
          Session Code
        </DialogTitle>
        <div>
          The session code for this session has changed. Please enter the new code to proceed.

          <div>
            <TextField
              className={classes.input}
              value={sessionCode}
              inputProps={{ maxLength: 4, style: {textAlign: 'center', paddingLeft: '30px'} }}
              onChange={handleInput}
              placeholder={'0000'}
              inputRef={inputRef}
              fullWidth
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

NewSessionTokenDialog.propTypes = {
  classes: PropTypes.object
};

export default withStyles(NewSessionTokenDialogStyle)(NewSessionTokenDialog);
