const EnterSessionPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    maxWidth: '800px',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '18px',
    marginTop: '8px !important',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  cardContainer: {
    width: '100%',
    margin: '32px auto',
    borderRadius: 8,
    padding: '0 !important',
    overflow: 'hidden',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  },
  cardContainerInner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  },
  cardTitle: {
    fontSize: '20px',
    width: '100%',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    paddingLeft: '16px'
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1, 2),
  },
  cardSubtitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: 400,
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    top: '1px'
  },
  searchInputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    width: '100%',
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(2),
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  joinButton: {
    marginTop: '12px !important',
  },
  hiddenInput: {
    display: 'none',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  inputBox: {
    display: 'flex',
    border: 'solid 1px #000',
    height: '70px',
    width: '70px',
    margin: '0 6px'
  },
  sessionIdContainer: {
    marginTop: '16px',
    display: 'flex !important',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  input: {
    marginTop: '12px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    letterSpacing: '20px'
  },
  enterSessionButton: {
    margin: '0 auto',
    marginTop: '24px !important',
  },
  submitPaymentContainer: {
    display: 'flex !important',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  submitPaymentButton: {
    margin: '16px !important',
  }
});

export default EnterSessionPageStyle;
