const MuiTheme = {
  palette: {
    primary: {
      main: '#f44336',
      light: '#F7F0F0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FFD886',

      //main: '#FFD886',
      contrastText: '#000',
    },
    error: {
      main: '#ff0000',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#666',
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  shape: {
    borderRadius: 4,
  },
};

const table = {
  overflowY: 'auto',
  height: 'calc(100vh - 250px)'
};

export {
  MuiTheme,
  table
};
