import {Button, MenuItem, TextField, Typography} from "@mui/material";
import {withStyles} from "@mui/styles";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import cn from "classnames";
import StripeFormStyle from "components/StripeForm/StripeFormStyle";
import StripeTextField from "components/StripeTextField/StripeTextField";
import {UserContext} from "context/UserContext";
//import {State} from 'country-state-city';
import {Form, Formik} from "formik";
import PropTypes from 'prop-types';
import React, {useContext, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {CountryData, User} from "services";
import {handleNetworkError} from "utils/utils";
import * as yup from "yup";
import { useTheme } from '@mui/material/styles';

const StripeForm = props => {
  const {
    classes,
    onSuccess,
    isEditingCard=false
  } = props;

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const {userData, setUserData} = useContext(UserContext);

  const [busy, setBusy] = useState(false)
  const [formError, setFormError] = useState("");

  const onSubmit = async (values, {setErrors, setFieldError}) => {
    let tokenData = {
      'address_line1': values.address1,
      'address_line2': values.address2,
      'address_city': values.city,
      'address_state': values.state,
      'address_zip': values.zipCode,
      'address_country': values.country
    }

    // stripe will check all elements, just needs one at random
    const cardNumberElement = elements.getElement(CardNumberElement);

    const result = await stripe.createToken(cardNumberElement, tokenData);

    if (result.token) {
      // move to next page
      let data = {
        clientIp: result.token['client_ip'],
        livemode: result.token['livemode'],
        paymentType: result.token['type'],
        card: {
          brand: result.token.card['brand'],
          country: result.token.card['country'],
          funding: result.token.card['funding'],
          last4: result.token.card['last4'],
          stripeId: result.token.card['id'],
          stripeToken: result.token['id'],
        }
      }

      console.log('data to send', data);

      // send token to django
      setFormError('')
      if(isEditingCard) {

        setBusy(true)

        User.changePaymentMethod(data)
          .then(response => {
            console.log('payment method changed', response);

            toast.success('Card successfully changed!')

            let newUserData = {...userData};
            newUserData.last4 = response.data.last4;
            setUserData(newUserData);

            if(onSuccess) {
              onSuccess(data);
            }
          })
          .catch(handleNetworkError)
          .finally(() => {
            setBusy(false)
          })
      }
      else {

        setBusy(true)

        User.changePaymentMethod(data)
          .then(response => {
            console.log('payment method changed', response);

            toast.success('Card successfully changed!')

            let newUserData = {...userData};
            newUserData.last4 = response.data.last4;
            setUserData(newUserData);

            if(onSuccess) {
              onSuccess(data);
            }
          })
          .catch(handleNetworkError)
          .finally(() => {
            setBusy(false)
          })
      }
    }
    else {
      setFormError(result?.error.message);
    }
  };

  return (
    <>
      {formError && (
        <Typography className={classes.formErrorMessage}>
          {formError}
        </Typography>
      )}
      <Formik
        initialValues={{
          cardName: "",
        }}
        onSubmit={onSubmit}
        validateOnChange={false}
      >
        {({
            values,
            errors,
            handleChange,
            setFieldError,
            setFieldValue,
            handleSubmit
          }) => (
          <Form className={classes.form}>
            <div className={classes.formContainer}>
              <div className={classes.formInputContainer}>
                <TextField
                  label={'Name on card'}
                  name={'cardName'}
                  color={'primary'}
                  className={classes.formInput}
                  fullWidth
                  sx={{
                    marginBottom: '6px',
                    color: 'black',
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        color: '#666666'
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      color: 'rgba(0, 0, 0, 0.54)'
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      input: classes.input
                    }}
                  }
                />
              </div>

              <div className={classes.formInputContainer}>
                <StripeTextField
                  label={'Credit Card Number'}
                  stripeElement={CardNumberElement}
                  name={'cardNumber'}
                  className={cn(classes.formInput, classes.cardNumber)}
                  placeholder={'1234 1234 1234 1234'}
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      input: classes.input
                    },
                  }}
                />
              </div>

              <div className={classes.formInputContainer}>
                <StripeTextField
                  label={'Expiration'}
                  stripeElement={CardExpiryElement}
                  name={'expDate'}
                  className={cn(classes.formInput, classes.expDate)}
                  placeholder={'MM/YY'}
                  sx={{
                    marginRight: '6px',
                    marginTop: '6px'
                  }}
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      input: classes.input
                    },
                  }}
                />

                <StripeTextField
                  label={'CVC'}
                  stripeElement={CardCvcElement}
                  name={'cvc'}
                  sx={{
                    marginLeft: '6px',
                    marginTop: '6px'
                  }}
                  placeholder={'CVC'}
                  className={cn(classes.formInput, classes.cvc)}
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      input: classes.input
                    },
                  }}
                />
              </div>


              <Button
                variant='contained'
                type="submit"
                onClick={handleSubmit}
                disabled={busy}
                className={classes.purpleButton}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

StripeForm.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(StripeFormStyle)(StripeForm);
