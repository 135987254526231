const HostManageQueueEntryPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%',
    height: 'calc(100vh - 85px)',
  },
  innerContent: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 400,
  },
  button: {
    backgroundColor: '#f04124',
    color: 'white',
    width: '147px',
    borderRadius: 4,
    padding: theme.spacing(1),
    border: 'none',
    '&:hover': {
      backgroundColor: '#a12010',
      cursor: 'pointer',
    },
  },
  cardContainer: {
    width: '100%',
    borderRadius: 8,
    padding: '0 !important',
    overflow: 'hidden',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  },
  cardTitle: {
    fontSize: '20px',
    width: '100%',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    paddingLeft: '16px'
  },
  cardContainerInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '16px'
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    paddingBottom: '16px',
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
  },
  cardLeftCol: {
    width: '50%'
  },
  cardRightCol: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  buttonContainer: {
    marginBottom: '12px'
  },
  cardFooterRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    paddingTop: '16px'
  },
  messagesContainer: {
    margin: '32px 0'
  },
  bubbleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: '10px',
  },
  bubbleContainerRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    marginBottom: '10px',
  },
  bubble: {
    position: 'relative',
    backgroundColor: '#F7F0F0',
    borderRadius: '10px 10px 10px 0px',
    padding: '10px',
    maxWidth: '80%',
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '0',
      bottom: '-5px',
      borderTop: '10px solid #F7F0F0',
      borderRight: '10px solid transparent',
    },
  },
  bubbleRight: {
    position: 'relative',
    backgroundColor: '#F7F0F0',
    borderRadius: '10px 10px 0px 10px',
    padding: '10px',
    maxWidth: '80%',
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    '&::after': {
      content: '""',
      position: 'absolute',
      right: '0',
      bottom: '-5px',
      borderTop: '10px solid #F7F0F0',
      borderLeft: '10px solid transparent',
    },
  },
  timeText: {
    '&.MuiTypography-root': {
      color: 'rgb(0,0,0)',
      fontWeight: 'bold',
      fontSize: '12px',
      marginTop: '6px'
    }
  },
  replyButton: {
    right: '0',
    bottom: '-5px',
  },
  sendButton: {
    right: '0',
    bottom: '-10px',
  },
  replyContaienr: {
    width: '100%',
    marginBottom: '10px'
  },
  formInputContainer: {
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

export default HostManageQueueEntryPageStyle;