const PerformerQueuePageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%'
  },
  innerContent: {
    width: '90%',
    margin: '0 auto',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:  '12px',

    '& > *:first-of-type': {
      marginRight: '8px'
    }
  },
  cardContainer: {
    width: '100%',
    margin: '32px auto',
    borderRadius: 8,
    padding: '0 !important',
    overflow: 'hidden',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  },
  cardTitle: {
    fontSize: '20px',
    width: '100%',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    paddingLeft: '16px'
  },
  headerContainer: {
    width: '100%',
    marginBottom: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editIconContainer: {
    marginLeft: '12px'
  },
  tableCellRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cardSubtitle: {
    // fontSize: '16px',
    // fontWeight: 500,
    // color: theme.palette.text.secondary,
    // marginRight: theme.spacing(2),
  },
  cardContainerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '16px'
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '16px'
  },
  column: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeRow: {
    backgroundColor: 'rgba(200, 255, 197, 0.4)',
    cursor: 'pointer'
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    textAlign: 'left'
  },
  avatar: {
    width: '110px !important',
    height: '110px !important'
  },
  userSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '12px'
  },
  smallAvatar: {
    marginRight: '8px'
  },
  tableContainer: {
    width: 'calc(100% - 32px)',
    marginBottom: theme.spacing(2),
    borderRadius: 8,
    maxHeight: '300px',
  },
  flashingEffect: {
    animation: `$flash 2s ease infinite`,
  },

  "@keyframes flash": {
    "0%, 100%": {
      backgroundColor: "transparent",
    },
    "50%": {
      backgroundColor: "rgba(200, 255, 197, 0.8)",
    },
  },
});

export default PerformerQueuePageStyle;
