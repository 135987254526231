const AddGuestPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%',
    height: 'calc(100vh - 85px)',
  },
  innerContent: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '18px',
    color: theme.palette.text.secondary,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 400,
  },
  formInputContainer: {
    marginBottom: '32px',
    width: '100%'
  },
  formInput: {
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: theme.spacing(1),
    width: '100%',
  },
  textContainer: {
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '32px'
  },
  button: {
    width: '100%',
    height: '48px',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '32px'
  },
  queueOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderTop: `solid 1px ${theme.palette.primary.main}`,
    paddingTop: '32px'
  }
});

export default AddGuestPageStyle;