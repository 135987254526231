import React, { useContext, useEffect, useState } from 'react';

import {
  withStyles
} from '@mui/styles';
import PropTypes from 'prop-types';

import PerformerQueuePageStyle from './PerformerQueuePageStyle';
import {
  Avatar,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { UserContext } from 'context/UserContext';
import { formatForWebsocket, handleNetworkError } from 'utils/utils';
import { useHistory, useLocation } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import cn from "classnames";
import { DEFAULT_SESSION_STATE, SessionContext } from 'context/SessionContext';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import { Session } from 'services';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const PerformerQueuePage = props => {
  const {
    classes,
  } = props;

  const {userData, setUserData} = useContext(UserContext);
  const {
    sessionState,
    sessionActions,
    currentSessionData,
    setCurrentSessionData,
    setSessionState,
  } = useContext(SessionContext);

  const [connected, setConnected] = useState(false);
  const [isVoter, setIsVoter] = useState(false);
  const [userInQueue, setUserInQueue] = useState(false);

  const [initialized, setInitialized] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const [busy, setBusy] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    return () => {
      let newSessionState = {...sessionState, connected: false};
      setSessionState(newSessionState);
    }
  }, [])

  useEffect(() => {
    console.log('DEBUG ONLY', {sessionState, sessionActions, currentSessionData});

    // check if the user is already in queue and if their score is -1 meaning they haven't gone yet
    const user = sessionState.queue.find(item => item.user.id === userData?.id);
    console.log('user in queue?', user);

    if(user) {
      if(user.score === -1) {
        setUserInQueue(true);
      }
      else {
        setUserInQueue(false);
      }
    }
    else {
      setUserInQueue(false);
    }

    if(sessionState?.connected === false) {
      setConnected(false);
    }
  }, [sessionState, sessionActions])

  useEffect(() => {
    // get the session data from the location
    const data = location?.state?.data;

    setSessionId(data?.id);

    console.log('get session')
    Session.get(data.id)
      .then(response => {
        console.log('refresh session data', response);
        setCurrentSessionData(response.data);
        setInitialized(true);
      })
      .catch(handleNetworkError)
  }, [location, sessionState])

  useEffect(() => {
    if(userData === false || userData === 'voter') {
      setIsVoter(true);
    }
    else {
      // join the session
      setIsVoter(false);
    }

    console.log('should attempt connection?', {sessionState, userData, connected, currentSessionData})
    if(!sessionState.wsConnection) {
      console.log('no connection found, trying to reconnect...')
      sessionActions.setupWebSocket()
    }

    if(sessionState.wsConnection && !connected && currentSessionData) {
      console.log('attempt join session', {currentSessionData})
      sessionActions.joinSession(currentSessionData.sessionId, currentSessionData.id);
      setConnected(true);
    }
  }, [sessionState, userData, currentSessionData])

  useEffect(() => {
    console.log('testing 123456', {
      test: sessionState?.currentlyPerforming ? (
          sessionState?.queue?.[1]?.user?.social_media_link
      ) : sessionState?.queue?.[0]?.user?.social_media_link,

      sessionState,
      currentSessionData
    })
  }, [sessionState, currentSessionData])

  const getScore = (row) => {
    console.log('get score', sessionState.scoreboard)

    if(!isVoter) {
      if(currentSessionData.scores.some(item => item.queueEntry === row.id && item.scoringUser === userData?.id)) {

        const scores = currentSessionData.scores.filter(item => item.queueEntry === row.id && item.scoringUser === userData?.id);

        console.log('scores found test', {scores, allScores: currentSessionData.scores})

        const score = scores[scores.length - 1];

        if(score === undefined) {
          return null;
        }

        console.log('score found', score);
        return score.score;
      }

      return null;
    }
    else {
      let voterScoreList = JSON.parse(sessionStorage.getItem('voterScoreList'));

      if(voterScoreList === null) {
        return null;
      }
      else {
        const score = voterScoreList.find(item => item.entryId === row.id);

        if(score === undefined) {
          return null;
        }

        console.log('score found', score);
        return score.score;
      }
    }
  }

  const openScoringScreen = (row) => {
    console.log('open scoring screen', row);

    let voterScoreList = JSON.parse(sessionStorage.getItem('voterScoreList'));
    console.log('voterScoreList', voterScoreList, typeof voterScoreList);

    if(isVoter && voterScoreList !== null && voterScoreList.findIndex(elem => elem.entryId === row.id) !== -1) {
      toast.error('You have already scored this performer.');
      return;
    }

    history.push('/performer-score', {data: currentSessionData, userToScore: row, isVoter})
  }

  const handleMessage = () => {
    history.push('/message-host', {data: currentSessionData});
  };

  const handleTip = () => {
    if(currentSessionData?.hostVenmoLink || currentSessionData?.hostPaypalLink) {
      history.push('/tip-host', {data: currentSessionData});
    }
    else {
      toast.success('Thank you! Please go talk to the host to get their tip information.');
    }
  };

  const joinQueue = () => {

    if(!currentSessionData) {
      toast.error('An error has occurred, please refresh.');
      return;
    }

    if(currentSessionData.entryFee > 0 && !userData?.last4) {
      toast.error('This session has an entry fee and you must have a card on file to join.');
      history.push('/account-settings', {'dropdownToOpen': 'billing', 'reason': 'no_card'});
      return;
    }

    if(isVoter) {
      toast.error('You must be a performer to join the queue');
    }
    else {
      if(currentSessionData?.type === 'Karaoke') {
        history.push('/choose-song', {data: currentSessionData});
      }
      else {
        setBusy(true);
        sessionActions.joinQueue(currentSessionData.sessionId, currentSessionData.id, null)
          .then(response => {
            console.log('join queue response', response);
          })
          .catch(handleNetworkError)
          .finally(() => setBusy(false))
      }
    }
  };

  const handleRowClick = (row) => {
    if(row?.user?.id === userData?.id) {
      history.push('/edit-bump-song', {data: currentSessionData});
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerContent}>
        <div className={classes.headerContainer}>
          <Typography variant={'h5'} sx={{marginBottom: '12px'}}>
            {currentSessionData?.venue?.name}
          </Typography>

          <div className={classes.userSection}>
            <Avatar
              alt="Profile picture"
              src={userData?.profilePicture}
              className={classes.smallAvatar}
            />

            <Typography>
              {userData?.stageName}
            </Typography>
          </div>

          <Button
              variant={'contained'}
              color={'primary'}
              onClick={joinQueue}
              disabled={userInQueue || isVoter || sessionState?.paused || busy}
          >
            {sessionState?.paused ? 'Queue is Paused' : 'Join Queue'}
          </Button>

          {(currentSessionData?.allowMessagesToHost || currentSessionData?.allowTipsToHost) && (
            <div className={classes.buttonContainer}>
              {currentSessionData?.allowMessagesToHost && (
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={handleMessage}
                >
                  Message Host
                </Button>
              )}

              {currentSessionData?.allowTipsToHost && (
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={handleTip}
                >
                  Tip Host
                </Button>
              )}
            </div>
          )}

        </div>

        <div className={classes.cardContainer}>
          <Typography variant="h5" className={classes.cardTitle}>
            {/*TODO: SWITCH BETWEEN CALL TO STAGE AND NOW PERFORMING DEPENDING ON STATE*/}
            Call to Stage
          </Typography>

          <div className={
            cn(
                classes.cardContainerInner,
                (sessionState?.currentlyPerforming && sessionState?.queue?.[0]?.user?.id === userData.id) ? classes.flashingEffect : null
            )
          }>
            <div className={classes.column}>
              <Avatar
                alt="Profile picture"
                src={sessionState?.currentPerformance?.user?.profile_picture}
                className={classes.avatar}
              />
            </div>
            <div className={classes.column}>
              <Typography
                className={classes.cardSubtitle}
                sx={{
                  fontSize: '24px',
                }}
              >
                {sessionState?.currentPerformance?.user?.stage_name}
              </Typography>

              {currentSessionData?.type === 'Karaoke' && (
                <>
                  <Typography
                    className={classes.cardSubtitle}
                    sx={{
                      marginTop: '8px'
                    }}
                  >
                    Performing:
                  </Typography>
                  <Typography
                    className={classes.cardSubtitle}
                    sx={{
                      fontWeight: 'bold'
                    }}
                  >
                    {sessionState?.currentPerformance?.song?.song_name}
                  </Typography>
                  <Typography
                    className={classes.cardSubtitle}
                    sx={{
                      marginTop: '8px'
                    }}
                  >
                    As recorded by:
                  </Typography>
                  <Typography
                    className={classes.cardSubtitle}
                    sx={{
                      fontWeight: 'bold'
                    }}
                  >
                    {sessionState?.currentPerformance?.song?.artist}
                  </Typography>
                </>
              )}
            </div>
          </div>
        </div>

        <div className={classes.cardContainer}>
          <Typography variant="h5" className={classes.cardTitle}>
            Up Next
          </Typography>

          <div className={classes.cardRow}>
            <Typography>
              {sessionState?.currentlyPerforming ? (
                sessionState?.queue?.[1]?.user?.stage_name
              ) : (
                sessionState?.queue?.[0]?.user?.stage_name
              )}
            </Typography>

            {sessionState?.type === 'Karaoke' && (
              <Typography>
                {sessionState?.currentlyPerforming  ? (
                  <>
                    <strong>{sessionState?.queue?.[1]?.song?.song_name}</strong>
                    <br/> by <br/>
                    <strong>{sessionState?.queue?.[1]?.song?.artist}</strong>
                  </>
                ) : (
                  <>
                    <strong>{sessionState?.queue?.[0]?.song?.song_name}</strong>
                    <br/> by <br/>
                    <strong>{sessionState?.queue?.[0]?.song?.artist}</strong>
                  </>
                )}
              </Typography>
            )}

            <a href={sessionState?.currentlyPerforming ? (
              sessionState?.queue?.[1]?.user?.social_media_link
            ) : sessionState?.queue?.[0]?.user?.social_media_link} target={'_blank'}>
              <IconButton>
                <LinkIcon/>
              </IconButton>
            </a>
          </div>

        </div>

        <div className={classes.cardContainer}>
          <div className={classes.cardTitle}>
            <Typography variant={'h5'}>
              In Line
            </Typography>

            <Button
              variant={'contained'}
              color={'primary'}
              onClick={joinQueue}
              disabled={userInQueue || isVoter || sessionState?.paused || busy}
            >
              {sessionState?.paused ? 'Queue is Paused' : 'Join Queue'}
            </Button>
          </div>

          <div className={classes.cardRow}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Name</TableCell>
                    {sessionState?.type === 'Karaoke' && (
                      <TableCell>Song</TableCell>
                    )}
                    <TableCell>Bump</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {sessionState?.queue?.map((row, index) => {
                    if (index === 0 || (index === 1 && sessionState?.currentlyPerforming === true)) return null;

                    return (
                      <TableRow
                        key={index}
                        onClick={() => handleRowClick(row)}
                      >
                        <TableCell
                          className={row?.user?.id === userData?.id ? classes.activeRow : classes.inactiveRow}
                        >
                          {sessionState?.currentlyPerforming ? index - 1 : index}
                        </TableCell>
                        <TableCell
                          className={row?.user?.id === userData?.id ? classes.activeRow : classes.inactiveRow}
                        >
                          {row.user?.stage_name}
                        </TableCell>
                        {sessionState?.type === 'Karaoke' && (
                          <TableCell
                            className={row?.user?.id === userData?.id ? classes.activeRow : classes.inactiveRow}
                          >
                            {row.song?.song_name}
                          </TableCell>
                        )}
                        <TableCell
                          className={row?.user?.id === userData?.id ? classes.activeRow : classes.inactiveRow}
                        >
                          {row?.user?.id === userData?.id && (
                            <div className={classes.tableCellRow}>
                              <div className={classes.editIconContainer}>
                                <EditIcon sx={{fontSize: '17px', position: 'relative', top: '2px'}}/>
                              </div>

                              <div className={classes.editIconContainer}>
                                <AttachMoneyIcon sx={{fontSize: '17px', position: 'relative', top: '2px'}}/>
                              </div>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

        </div>

        {currentSessionData?.type === 'Karaoke' && currentSessionData?.enableScoreboard && (
          <div className={classes.cardContainer}>
            <Typography variant="h5" className={classes.cardTitle}>
              Scoreboard
            </Typography>

            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Name</TableCell>
                    {sessionState?.type === 'Karaoke' && (
                      <TableCell>Song</TableCell>
                    )}
                    <TableCell>Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {sessionState?.scoreboard.map((row, index) =>  {
                    return (
                      <TableRow key={index} onClick={() => openScoringScreen(row)}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.user?.stage_name}</TableCell>
                        {sessionState?.type === 'Karaoke' && (
                          <TableCell>{row.song?.song_name}</TableCell>
                        )}
                        <TableCell>{getScore(row) !== null ? getScore(row) : 0}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};

PerformerQueuePage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(PerformerQueuePageStyle)(PerformerQueuePage);
