import * as React from "react";
import { useTheme } from "@mui/material/styles";

const StripeInput = React.forwardRef(
  function StripeInput(props, ref) {
    const { component: Component, options, ...other } = props;
    const theme = useTheme();
    const [mountNode, setMountNode] = React.useState(null);

    React.useImperativeHandle(
      ref,
      () => ({
        focus: () => mountNode.focus()
      }),
      [mountNode]
    );

    return (
      <Component
        onReady={setMountNode}
        options={{
          ...options,
          style: {
            base: {
              fontSize: `${theme.typography.fontSize}px`,
              color: theme.palette.text.primary,
              fontFamily: theme.typography.fontFamily,
              borderColor: 'red',
              "::placeholder": {
                color: theme.palette.text.secondary,
              },
            },
            invalid: {
              color: theme.palette.error.main,
              iconColor: theme.palette.error.main,
            },
          }
        }}
        {...other}
      />
    );
  }
);

export default StripeInput;
