import React, { useContext, useEffect, useState } from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import PerformerScorePageStyle from './PerformerScorePageStyle';
import {Avatar, Button, Slider, Typography} from '@mui/material';
import {Form, Formik} from "formik";
import { useHistory, useLocation } from 'react-router-dom';
import cn from "classnames";
import { Session, User } from 'services';
import { handleNetworkError } from 'utils/utils';
import { SessionContext } from 'context/SessionContext';
import { toast } from 'react-toastify';
import { UserContext } from 'context/UserContext';

const PerformerScorePage = props => {
  const {
    classes,
  } = props;

  const [value, setValue] = useState(1);
  const [currentSessionData, setCurrentSessionData] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [isVoter, setIsVoter] = useState(false);

  const {sessionState, sessionActions} = useContext(SessionContext);
  const {userData} = useContext(UserContext);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // get the session data from the location
    const data = location?.state?.data;

    console.log('location data', location?.state);

    setIsVoter(data?.isVoter);
    console.log('is voter', location?.state?.isVoter);

    setSessionId(data?.id);

    if(!initialized) {
      console.log('get session', data)
      Session.get(data.id)
        .then(response => {
          console.log('refresh session data', response);
          setCurrentSessionData(response.data);
          setInitialized(true);
        })
        .catch(handleNetworkError)
    }
  }, [location, sessionState, initialized])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSubmit = () => {
    console.log('user to score test', {isVoter, entryId: location?.state?.userToScore?.id, currentSessionData});

    if(userData === 'voter' || userData === false) {
      console.log('save voter score list to session storage')

      let voterScoreList = sessionStorage.getItem('voterScoreList');
      if(voterScoreList) {
        voterScoreList = JSON.parse(voterScoreList);

        voterScoreList.push({
          entryId: location?.state?.userToScore?.id,
          score: value
        });

        console.log('set voter score list', voterScoreList);
        sessionStorage.setItem('voterScoreList', JSON.stringify(voterScoreList));
      }
      else {
        console.log('set voter score list', voterScoreList);
        sessionStorage.setItem('voterScoreList', JSON.stringify([{
          entryId: location?.state?.userToScore?.id,
          score: value
        }]));
      }
    }

    sessionActions.setScore(userData?.token, currentSessionData?.sessionId, location?.state?.userToScore?.id, value)

    history.goBack();
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerContent}>
        <Typography variant="h4" className={classes.title}>
          Enter Score
        </Typography>

        <Typography variant="subtitle1" className={classes.subtitle}>
          Enter a score between 1 and 10 for {location?.state?.userToScore?.user?.stage_name}
        </Typography>

        <div className={classes.cardContainer}>
          <div className={classes.cardContainerInner}>
            <div className={classes.column}>
              <Avatar
                alt="Profile picture"
                src="/path/to/profile-picture.jpg"
                className={classes.avatar}
              />
            </div>
            <div className={cn(classes.column, classes.rightCol)}>
              <Typography
                className={classes.cardSubtitle}
                sx={{
                  fontSize: '24px',
                }}
              >
                {location?.state?.userToScore?.user?.stage_name}
              </Typography>
              <Typography
                className={classes.cardSubtitle}
                sx={{
                  marginTop: '8px'
                }}
              >
                Performing:
              </Typography>
              <Typography
                className={classes.cardSubtitle}
                sx={{
                  fontWeight: 'bold'
                }}
              >
                {location?.state?.userToScore?.song?.song_name}
              </Typography>
              <Typography
                className={classes.cardSubtitle}
                sx={{
                  marginTop: '8px'
                }}
              >
                As recorded by:
              </Typography>
              <Typography
                className={classes.cardSubtitle}
                sx={{
                  fontWeight: 'bold'
                }}
              >
                {location?.state?.userToScore?.song?.artist}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.sliderContainer}>
          <Typography
            sx={{
              fontWeight: 'bold',
              marginRight: '16px'
            }}
          >
            1
          </Typography>
          <Slider
            value={value}
            onChange={handleChange}
            color={'primary'}
            valueLabelDisplay="on"
            min={0}
            max={10}
          />
          <Typography
            sx={{
              fontWeight: 'bold',
              marginLeft: '16px'
            }}
          >
            10
          </Typography>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            variant={'contained'}
            color={'primary'}
            type={'submit'}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

PerformerScorePage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(PerformerScorePageStyle)(PerformerScorePage);
