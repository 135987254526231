const EnterBillingDetailsStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    padding: theme.spacing(4, 0),
    width: '90%'
  },
  saveButton: {
    width: '100%',
    height: '48px',
    marginTop: theme.spacing(2),
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
  },
  form: {
    width: '100%'
  },
  textFieldContainer: {
    margin: '32px 0 !important',
  },
  title: {
    marginBottom: '12px !important'
  }
});

export default EnterBillingDetailsStyle;