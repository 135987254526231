const LoginPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: theme.spacing(2),
  },
  logo: {
    maxWidth: 300,
    marginBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 400,
    marginBottom: '40px',
    paddingBottom: '40px',
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
  },
  formInputContainer: {
    marginBottom: theme.spacing(2),
  },
  formInput: {
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: theme.spacing(1),
    width: '100%',
  },
  loginButton: {
    backgroundColor: '#f04124',
    color: 'white',
    borderRadius: 4,
    padding: theme.spacing(1),
    width: '100%',
    border: 'none',
    '&:hover': {
      backgroundColor: '#a12010',
      cursor: 'pointer',
    },
  },
  signUpButton: {
    marginTop: '16px !important'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  continueButton: {
    backgroundColor: '#83c75d',
    color: 'white',
    borderRadius: 4,
    padding: theme.spacing(1),
    width: '100%',
    border: 'none',
    '&:hover': {
      backgroundColor: '#619d3f',
      cursor: 'pointer',
    },
  },
  forgotPasswordLink: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  }
});

export default LoginPageStyle;
