const PerformerScorePageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%'
  },
  innerContent: {
    width: '90%',
    margin: '0 auto',
  },
  cardContainer: {
    width: '100%',
    margin: '32px auto',
    marginBottom: '64px',
    borderRadius: 8,
    padding: '0 !important',
    overflow: 'hidden',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  },
  cardTitle: {
    fontSize: '20px',
    width: '100%',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    paddingLeft: '16px'
  },
  cardSubtitle: {
    // fontSize: '16px',
    // fontWeight: 500,
    // color: theme.palette.text.secondary,
    // marginRight: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '18px',
    marginTop: '8px !important',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  cardContainerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '16px'
  },
  column: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    textAlign: 'left'
  },
  avatar: {
    width: '100px !important',
    height: '100px !important'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '32px'
  },
  sliderContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

export default PerformerScorePageStyle;