const EnterStageNameAndPhotoStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    padding: theme.spacing(4, 0),
    width: '90%'
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '80px !important',
    height: '80px !important',
    marginBottom: '16px'
  },
  avatarEditButton: {
    position: 'absolute !important',
    right: '-10px !important',
    bottom: '0 !important',
    background: theme.palette.primary.main + ' !important',
    zIndex: 100,
  },
  avatar: {
    width: '80px !important',
    height: '80px !important',
    margin: theme.spacing(2, 0),
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(4),

    '& > *': {
      margin: '16px 0 !important'
    }
  },
  input: {
    marginBottom: theme.spacing(2),
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '14px 14px',
      fontSize: '14px',
      color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
      '& fieldset': {
        borderColor: '#F44336',
      },
      '&:hover fieldset': {
        borderColor: '#F44336',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F44336',
        borderWidth: '2px',
      },
    },
  },
  saveButton: {
    width: '100%',
    height: '48px',
    marginTop: theme.spacing(2),
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
  },
  title: {
    marginBottom: '12px !important'
  }
});

export default EnterStageNameAndPhotoStyle;