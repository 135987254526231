const APPLICATION_SETTINGS = {
  DEBUG: process.env.REACT_APP_DEBUG === 'true',


  // TODO: this is the test key, use a real key in production!
  //STRIPE_API_KEY: "pk_test_51NSaD4B4thxbJruhaJYCfWpDm4uzVNvzU1OnYCEbJAlF8lgKOXtHUwk6EijKtJHoHH639nhy0D2qrVThjYvmo9h000vzXWhVij",
  STRIPE_API_KEY: "pk_live_51NSaD4B4thxbJruh0QRANbGY2RSHtoWTo3GmtF0E8B71I6SsoGYRHl6jRgukfbc7Op3idb65KHyXKsImvNmlDzzI006RlsXPmj",

  // USE STAGING URL NOT THE LIVE SERVER
  //DJANGO_API_URL: 'http://localhost:8020/api',
  DJANGO_API_URL: "https://theduq-930c8f63ce6b.herokuapp.com/api",

  //DJANGO_WEBSOCKET_URL: "ws://localhost:8020/karaoke_queue/",
  DJANGO_WEBSOCKET_URL: "wss://theduq-930c8f63ce6b.herokuapp.com/karaoke_queue/",

  CONSTANTS: {

  },
};

export default APPLICATION_SETTINGS;
