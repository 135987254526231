
const RequestPasswordResetPageStyle = theme => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  resetPassword: {
    marginTop: '0 !important'
  },
  cardContent: {
    padding: '25px 40px',
    paddingBottom: '0',
    maxWidth: '400px',

    '& > *': {
      marginTop: '30px'
    }
  },
  cardForm: {
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-around',
  },
  cardActions: {
    padding: '25px 40px'
  },
  submitButton: {
    marginTop: '25px !important',
    backgroundColor: theme.palette.secondary.main,

    '&:hover': {
      backgroundColor: theme.palette.secondary.hover
    }
  }
});

export default RequestPasswordResetPageStyle;
