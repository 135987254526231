const TipHostPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%'
  },
  innerContent: {
    width: '90%',
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '18px',
    marginTop: '8px !important',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '32px'
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& a': {
      fontSize: '18px',
      display: 'block',
      marginTop: '8px',
    }
  }
});

export default TipHostPageStyle;
