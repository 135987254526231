import React, { useEffect, useState, useRef } from 'react';
import RequestPasswordResetPageStyle from './RequestPasswordResetPageStyle';
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  TextField,
  Button
} from '@mui/material';
import { withStyles } from '@mui/styles';

import {Form, Formik} from "formik";
import { handleNetworkError } from 'utils/utils';
import { User } from 'services';

const RequestPasswordResetPage = (props) => {
  const {classes} = props;
  const [passwordRequested, setPasswordRequested] = useState(false);

  const onSubmit = async (values, {setErrors}) => {
    User.requestPasswordReset(values.email).then(response => {
      setPasswordRequested(true);
    }).catch(error => handleNetworkError(error, setErrors));
  };

  const cardContent = (
    <>
      <Typography variant={'body1'} align={'center'}>
        Enter your email address to request a password reset.{' '}
        If it matches we'll send an email.
      </Typography>
      <div className={classes.cardForm}>
        <Formik
          initialValues={{
            email: ''
          }}

          onSubmit={onSubmit}
        >
          {({
              values,
              errors,
              handleChange
            }) => (
            <Form className={classes.form}>
              <TextField
                fullWidth
                name={'email'}
                label={'Email Address'}
                variant={'outlined'}
                value={values.email}
                error={!!errors.email}
                helperText={errors.email}
                onChange={handleChange}
              />

              <Button
                variant={'contained'}
                className={classes.submitButton}
                fullWidth
                type={'submit'}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );

  const requestedMessage = (
    <>
      <Typography variant={'body1'} align={'center'}>
        Password reset requested, check your email for further instructions.
        If you don't get an email, check you entered a valid email for an account on this site.
      </Typography>
    </>
  );

  return (
    <div className={classes.root}>
      <Card>
        <CardContent className={classes.cardContent}>
          <Typography variant={'h6'} align={'center'} className={classes.resetPassword}>
            Request Password Reset
          </Typography>

          {passwordRequested ? requestedMessage : cardContent}
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(RequestPasswordResetPageStyle)(RequestPasswordResetPage);
