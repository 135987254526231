const NewSessionTokenDialogStyle = theme => ({
  input: {
    marginTop: '12px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    letterSpacing: '20px'
  },
});

export default NewSessionTokenDialogStyle;
