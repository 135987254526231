import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import MainPageLayoutStyle from './MainPageLayoutStyle';
import Navbar from "components/Navbar/Navbar";

const MainPageLayout = props => {
  const {
    classes,
    children,
  } = props;

  return (
    <div className={classes.root}>
      <Navbar/>

      <div className={classes.contentRoot}>
        {children}
      </div>
    </div>
  );
};

MainPageLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.element.isRequired

};

export default withStyles(MainPageLayoutStyle)(MainPageLayout);
