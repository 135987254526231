import axios from 'axios';
import Cookies from 'universal-cookie';
import APIConfig from 'settings';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import APPLICATION_SETTINGS from "settings";

const responseBody = (response, camelCase=true) => camelCase ? camelcaseKeys(response, {deep: true}) : response;
const responseError = (error) => {
  console.error(error);
  throw error;
};

axios.defaults.baseURL = APPLICATION_SETTINGS.DJANGO_API_URL;
// token interceptor
axios.interceptors.request.use(
  (config) => {
    const cookies = new Cookies();
    const token = cookies.get('token');

    if (token) config.headers.Authorization = `Token ${token}`;

    return config;
  },
  (error) => {
    console.log('response rejected', error)
    return Promise.reject(error);
  },
);

// error interceptors
axios.interceptors.response.use(undefined, (error) => {
  const cookies = new Cookies();
  const status = error.response ? error.response.status : null;
  const data = error.response ? error.response.data : null;
  if (status === 403 && data && data['detail'] === 'Invalid token.') {
    console.log('cookie removed due to invalid token');
    cookies.remove('token', { path: '/' });
    // this was causing infinite loops with stale cookies
    //window.location.reload();
  }
  throw error;
});

const requests = {
  get: (url, camelCase=true) => axios.get(url).then(response => responseBody(response, camelCase)).catch(responseError),
  post: (url, body={}, headers={}, snakeCase=true, camelCase=true) =>
    axios.post(url, snakeCase ? snakecaseKeys(body, { deep: true }) : body, {...headers})
      .then(response => responseBody(response, camelCase)).catch(responseError),
  put: (url, body) => axios.put(url, snakecaseKeys(body, { deep: true })).then(responseBody).catch(responseError),
  delete: (url, camelCase=true) => axios.delete(url).then(response => responseBody(response, camelCase)).catch(responseError),
  patch: (url, body, headers={}, snakeCase=true) => {
    return axios.patch(url, (snakeCase ? snakecaseKeys(body, { deep: true }) : body), {...headers})
      .then(responseBody).catch(responseError)
  }
};

const User = {
  get: (id) => requests.get(`/users/${id}/`),
  getPerformance: (id, sessionId) => requests.get(`/users/get-user-performance/?userId=${id}&sessionId=${sessionId}`),
  login: (email, password) => requests.post('/users/authenticate/', {
    email,
    password
  }),
  changePaymentMethod: (data) => requests.post('/users/me/change-payment-method/', {...data}),
  hostLogin: (email, password) => requests.post('/users/host-authenticate/', {
    email,
    password
  }),
  create: (email, password, stageName, isHost=false) => requests.post('/users/', {
    email: email,
    password,
    stageName,
    isHost
  }),
  getMySongList: () => requests.get(`/users/me/get-song-list/`),
  updateMe: (data) => requests.patch(`/users/me/update-user/`, data),
  changeMyProfilePicture: (data) => requests.post('/users/me/update-user/', data, {'Content-Type': 'multipart/form-data'}, false),
  getMe: () => requests.get('/users/me/'),
  requestPasswordReset: (email) => requests.post('/users/me/request-password-reset/', {
    email
  }),
  verifyPasswordResetToken: (passwordResetToken) => requests.post('/users/me/verify-password-token/', {
    token: passwordResetToken
  }),
  confirmPasswordReset: (password, passwordResetToken) => requests.post('/users/me/confirm-password/', {
    token: passwordResetToken,
    password
  }),
  authenticatedPasswordReset: (password) => requests.post('/users/me/authenticated-change-password/', {
    password
  }),
  createScore: (userToScoreId, score) => requests.post('/user-scores/', {queue_entry: userToScoreId, score})
};

const Session = {
  get: (id) => requests.get(`/sessions/${id}/`),
  create: (data) => requests.post('/sessions/', data),
  listAll: (hostId=null) => requests.get(hostId ? `/sessions/?host=${hostId}` : '/sessions/'),
  verifyCode: (code, sessionId) => requests.post('/sessions/verify-code/', {
    code,
    session: sessionId
  }, {}, false, false),
  getSongList: (sessionId) => requests.get(`/sessions/${sessionId}/songs/`),
  activate: (sessionId) => requests.post(`/sessions/${sessionId}/activate/`),
}

const SessionMessage = {
  clearMessages: (sessionId, toUserId, fromUserId,) => requests.post(`/session-messages/clear-messages/`, {sessionId, toUserId, fromUserId}),
  listAllForSession: (sessionId, selectedUserId) =>
    requests.get(`/session-messages/?session=${sessionId}&selectedUserId=${selectedUserId}`),
}

const Venue = {
  listAll: () => requests.get('/venues/'),
  create: (data) => requests.post('/venues/', data),
}

const Song = {
  listAll: () => requests.get('/songs/'),
  create: (data) => requests.post('/songs/', data),
  uploadCsv: (fd) => requests.post('/songs/upload-csv/', fd, {'Content-Type': 'multipart/form-data'}, false),
  setSongs: (songsToAdd, songsToDelete) => requests.post('/songs/set-songs/', {songsToAdd, songsToDelete}),
  edit: (id, data) => requests.patch(`/songs/${id}/`, data),
  delete: (id) => requests.delete(`/songs/${id}/`),
}

export {
  requests,
  User,
  Session,
  Venue,
  SessionMessage,
  Song
}
