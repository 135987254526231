import React, {useEffect, useState} from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import LoadingStyle from './LoadingStyle';
import {CircularProgress} from "@mui/material";

const Loading = (props) => {
  const {
    classes,
    background='light'
  } = props;

  const [show, setShow] = useState(props.show);

  useEffect(() => {
    console.log('loading test')
    setShow(props.show);
  }, [props.show]);

  if(!show) {
    return null;
  }

  return (
    <div className={classes.root}>
      <CircularProgress color={'secondary'}/>
    </div>
  )
};

Loading.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(LoadingStyle)(Loading);