const ManageSongPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%',
    height: 'calc(100vh - 85px)',
  },
  innerContent: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '85%',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  outerTableContainer: {
    height: '100%',
    minHeight: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '& .MuiTable-root': {
      maxHeight: '100%'
    }
  },
  tableContainer: {
    height: '85%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  addSongContainer: {
    padding: '32px 0',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderTop: `solid 1px ${theme.palette.primary.main}`,
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
  },
  formInputContainer: {
    marginBottom: '32px'
  },
  tableCell: {
    padding: '8px !important'
  },
  formInput: {
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: theme.spacing(1),
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  saveButton: {
    width: '100%',
    height: '48px',
    marginTop: theme.spacing(2),
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
  },
  importListContainer: {
    padding: '32px 0',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '18px',
    marginTop: '8px !important',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  notificationMethodRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  notificationMethodContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '32px !important'
  },
  form: {
    width: '100%'
  },
  fileButton: {
    background: theme.palette.primary.main + ' !important',
  },
});

export default ManageSongPageStyle;
