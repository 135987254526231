import { MuiTheme } from 'assets/theme';

const ConfirmPasswordResetPageStyle = {
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  resetPassword: {
    marginTop: '0 !important'
  },
  inputContainer: {
    margin: '20px 0',
    minWidth: '270px'
  },
  cardContent: {
    padding: '25px 40px',
    paddingBottom: '0',

    '& > *': {
      marginTop: '30px'
    }
  },
  cardForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-around'
  },
  cardActions: {
    padding: '25px 40px'
  },
  submitButton: {
    backgroundColor: MuiTheme.palette.secondary.main,

    '&:hover': {
      backgroundColor: MuiTheme.palette.secondary.hover
    }
  }
};

export default ConfirmPasswordResetPageStyle;
