import React, { Suspense, lazy, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import {MuiTheme} from 'assets/theme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import UserProvider from 'context/UserContext';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import Loading from 'components/Loading/Loading';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './index.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import StripeProvider, { StripeContext } from 'context/StripeContext';

import LoginPage from 'pages/LoginPage/LoginPage';
import CreateAccountPage from 'pages/CreateAccountPage/CreateAccountPage';
import Cookies from "universal-cookie";
import MainPageLayout from 'layout/MainPageLayout/MainPageLayout';
import LobbyPage from 'pages/LobbyPage/LobbyPage';
import EnterSessionPage from 'pages/EnterSessionPage/EnterSessionPage';
import SessionProvider from 'context/SessionContext';
import AccountSettingsPage from 'pages/AccountSettingsPage/AccountSettingsPage';
import PerformerQueuePage from 'pages/PerformerQueuePage/PerformerQueuePage';
import PerformerScorePage from "pages/PerformerScorePage/PerformerScorePage";
import MessageHostPage from "pages/MessageHostPage/MessageHostPage";
import TipHostPage from "pages/TipHostPage/TipHostPage";
import ChooseSongPage from "pages/ChooseSongPage/ChooseSongPage";
import EditBumpSongPage from "pages/EditBumpSongPage/EditBumpSongPage";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HostLoginPage from "pages/HostLoginPage/HostLoginPage";
import HostCreateAccountPage from "pages/HostCreateAccountPage/HostCreateAccountPage";
import ManageSongPage from "pages/ManageSongPage/ManageSongPage";
import EditSongPage from "pages/EditSongPage/EditSongPage";
import CreateVenuePage from "pages/CreateVenuePage/CreateVenuePage";
import CreateSessionPage from "pages/CreateSessionPage/CreateSessionPage";
import AddGuestPage from "pages/AddGuestPage/AddGuestPage";
import HostQueuePage from "pages/HostQueuePage/HostQueuePage";
import HostManageQueueEntryPage from "pages/HostManageQueueEntry/HostManageQueueEntryPage";
import HostAccountSettingsPage from "pages/HostAccountSettingsPage/HostAccountSettingsPage";
import RequestPasswordResetPage from "pages/RequestPasswordResetPage/RequestPasswordResetPage";
import ConfirmPasswordResetPage from "pages/ConfirmPasswordResetPage/ConfirmPasswordResetPage";

import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import EnterStageNameAndPhoto from "pages/EnterStageNameAndPhoto/EnterStageNameAndPhoto";
import EnterNotificationDetails from "pages/EnterNotificationDetails/EnterNotificationDetails";
import EnterBillingDetails from "pages/EnterBillingDetails/EnterBillingDetails";

const theme = createTheme(MuiTheme);

const App = () => {
  useEffect(() => {
    console.log('app starting...')
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
        <UserProvider>
          <StripeProvider>
            <SessionProvider>

                  <ScrollToTop />
                  <Suspense fallback={<Loading background={'dark'} show={true}/>}>
                    <Switch>
                      <Route exact path={'/'} component={LoginPage}/>
                      <Route exact path={'/host'} component={HostLoginPage}/>
                      <Route exact path={'/create-account'} component={CreateAccountPage}/>
                      <Route exact path={'/host-create-account'} component={HostCreateAccountPage}/>
                      <Route exact path={'/request-password-reset'} component={RequestPasswordResetPage}/>
                      <Route exact path={'/password-reset'} component={ConfirmPasswordResetPage}/>
                      <MainPageLayout>
                        <Switch>
                          <Route exact path={'/enter-billing-details'} component={EnterBillingDetails}/>
                          <Route exact path={'/enter-notification-details'} component={EnterNotificationDetails}/>
                          <Route exact path={'/enter-stage-name-and-photo'} component={EnterStageNameAndPhoto}/>
                          <Route exact path={'/lobby'} component={LobbyPage}/>
                          <Route exact path={'/enter-session'} component={EnterSessionPage}/>
                          <Route exact path={'/account-settings'} component={AccountSettingsPage}/>
                          <Route exact path={'/performer-queue'} component={PerformerQueuePage}/>
                          <Route exact path={'/performer-score'} component={PerformerScorePage}/>
                          <Route exact path={'/message-host'} component={MessageHostPage}/>
                          <Route exact path={'/tip-host'} component={TipHostPage}/>
                          <Route exact path={'/choose-song'} component={ChooseSongPage}/>
                          <Route exact path={'/manage-song'} component={ManageSongPage}/>
                          <Route exact path={'/edit-song'} component={EditSongPage}/>
                          <Route exact path={'/edit-bump-song'} component={EditBumpSongPage}/>
                          <Route exact path={'/create-venue'} component={CreateVenuePage}/>
                          <Route exact path={'/create-session'} component={CreateSessionPage}/>
                          <Route exact path={'/add-guest'} component={AddGuestPage}/>
                          <Route exact path={'/host-queue'} component={HostQueuePage}/>
                          <Route exact path={'/host-manage-queue'} component={HostManageQueueEntryPage}/>
                          <Route exact path={'/host-account-settings'} component={HostAccountSettingsPage}/>
                        </Switch>
                      </MainPageLayout>
                    </Switch>
                  </Suspense>

                <ToastContainer />
            </SessionProvider>
          </StripeProvider>
        </UserProvider>
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
