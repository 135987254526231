import React, { useEffect, useContext, useState } from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import ChooseSongPageStyle from 'pages/ChooseSongPage/ChooseSongPageStyle';
import {Typography} from "@mui/material";
import ChooseSongTable from "components/ChooseSongTable/ChooseSongTable";
import { Session } from 'services';
import { SessionContext } from 'context/SessionContext';
import { useHistory, useLocation } from 'react-router-dom';
import {UserContext} from "context/UserContext";

const ChooseSongPage = props => {
  const { classes } = props;

  const [currentSessionData, setCurrentSessionData] = useState();
  const [songList, setSongList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [returnTo, setReturnTo] = useState(null);
  const [connected, setConnected] = useState(false);

  const {userData} = useContext(UserContext);
  const {sessionState, setSessionState, sessionActions} = useContext(SessionContext);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // get the current session data from the location
    const data = location?.state?.data;
    setCurrentSessionData(data);

    // check for isEditing
    const isEditing = location?.state?.isEditing;
    setIsEditing(isEditing);

    const returnTo = location?.state?.returnTo;
    setReturnTo(returnTo);

  }, [location])

  useEffect(() => {
    console.log('choose song page', currentSessionData);
    if(currentSessionData?.id) {
      Session.getSongList(currentSessionData?.id).then((response) => {
        console.log('songs response', response);

        setSongList(response.data);
      }).catch(error => {
        console.log('error', error);
      });
    }

  }, [currentSessionData]);

  useEffect(() => {
    console.log('should attempt connection?', {sessionState, userData, connected, currentSessionData})
    if(!sessionState.wsConnection) {
      console.log('no connection found, trying to reconnect...')
      sessionActions.setupWebSocket()
    }

    if(sessionState.wsConnection && !connected && currentSessionData) {
      console.log('attempt join session', {currentSessionData})
      sessionActions.joinSession(currentSessionData.sessionId, currentSessionData.id);
      setConnected(true);
    }
  }, [sessionState, userData, currentSessionData])

  const onSuccess = (song) => {
    console.log('choose song success', song[0]);

    if(returnTo) {
      history.push(returnTo, {song, data: currentSessionData});
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerContent}>
        <Typography variant="h4" className={classes.title}>
          Choose Song
        </Typography>

        <div className={classes.tableContainer}>
          <ChooseSongTable
            songs={songList}
            currentSessionData={currentSessionData}
            isEditing={isEditing}
            selectedSongs={[]}
            onSuccess={onSuccess}
            chooseForGuest={!!returnTo}
          />
        </div>

      </div>
    </div>
  );
};

ChooseSongPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ChooseSongPageStyle)(ChooseSongPage);
