import React, { useContext, useEffect, useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@mui/material';
import {useHistory, useLocation} from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import EnterBillingDetailsStyle from "pages/EnterBillingDetails/EnterBillingDetailsStyle";
import StripeForm from "components/StripeForm/StripeForm";
import {User} from "services";

const EnterBillingDetails = ({ classes }) => {
  const location = useLocation();

  const history = useHistory();

  const { userData, setUserData } = useContext(UserContext);

  const onSuccess = () => {
    User.updateMe({
      'newUser': false
    }).then(response => {
      console.log('response', response);

      setUserData(response.data);

      history.push('/lobby');
    }).catch(error => {
      console.error('error', error);
    })
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>Enter Billing Details</Typography>

      <StripeForm onSuccess={onSuccess}/>
    </div>
  );
};

EnterBillingDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(EnterBillingDetailsStyle)(EnterBillingDetails);
