import React, {useState, useEffect, useContext} from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import HostManageQueueEntryPageStyle from './HostManageQueueEntryPageStyle';
import {Avatar, Button, TextField, Typography} from "@mui/material";
import {Form, Formik } from "formik";
import * as Yup from "yup";
import { Session, SessionMessage, User } from 'services';
import { handleNetworkError } from 'utils/utils';
import { SessionContext } from 'context/SessionContext';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import {toast} from "react-toastify";

const HostManageQueueEntryPage = props => {
  const { classes } = props;

  const [sortNewest, setSortNewest] = useState(true);
  const [isReceived, setIsReceived] = useState(false);
  const [showReplyField, setShowReplyField] = useState(false);
  const [replyMessageIndex, setReplyMessageIndex] = useState(null);
  const [showNewMessageField, setShowNewMessageField] = useState(false);

  const [currentQueuePosition, setCurrentQueuePosition] = useState(0);
  const [queueEntry, setQueueEntry] = useState(null);

  const [currentPerformer, setCurrentPerformer] = useState(null);
  const [messages, setMessages] = useState([]);

  const [initialized, setInitialized] = useState(false);
  const [currentSessionData, setCurrentSessionData] = useState(null);

  const [pendingBumpFee, setPendingBumpFee] = useState(0);

  const [busy, setBusy] = useState(false);

  const {
    sessionState,
    sessionData,
    sessionActions,
    setCurrentSessionData: setContextSessionData
  } = useContext(SessionContext);

  const {
    userData
  } = useContext(UserContext);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // Sort the messages based on the value of sortNewest
    const sortedMessages = [...messages];
    sortedMessages.reverse(); // Reverse the order if sortNewest is true


    // Update the state with the sorted messages
    setMessages(sortedMessages);
  }, [sortNewest]);

  useEffect(() => {
    console.log('new host message', sessionState.hostMessages);

    let newMessages = [...messages];

    for(let message in sessionState.hostMessages) {
      newMessages = [{
        id: sessionState.hostMessages[message].id,
        text: sessionState.hostMessages[message].message,
        time: sessionState.hostMessages[message].created_at_time,
        isReceived: sessionState.hostMessages[message].from_user.id !== userData.id
      }, ...newMessages]
    }

    setMessages(newMessages);
  }, [sessionState?.hostMessages])

  useEffect(() => {
    // find the selected user's index in sessionState.queue, add 1 to it, and set it as the queue position
    const userIndex = sessionState.queue.findIndex(item => item.id === currentPerformer?.id);

    console.log('USER INDEX', userIndex);

    if(userIndex === -1) {
      setCurrentQueuePosition('N/A');
    }
    else {
      setCurrentQueuePosition(userIndex + 1);

      console.log('queue entry', sessionState.queue[userIndex])
      setQueueEntry(sessionState.queue[userIndex]);
    }
  }, [sessionState, currentPerformer])

  useEffect(() => {
    // get the session data from the location
    const data = location?.state?.data;

    console.log('manage queue entry location state', location?.state);

    if (!initialized && userData) {
      Promise.all([
        Session.get(data.id),
        SessionMessage.listAllForSession(data.id, location?.state?.performer?.id),
        User.getPerformance(location?.state?.performer?.id, data.id)
      ])
        .then(([sessionResponse, messageResponse, userResponse]) => {
          console.log('refresh session data', sessionResponse);
          setCurrentSessionData(sessionResponse.data);
          setContextSessionData(sessionResponse.data);

          console.log('session messages', { messageResponse, userData });

          let newMessages = [];

          for (let message in messageResponse.data) {
            newMessages.push({
              id: messageResponse.data[message].id,
              text: messageResponse.data[message].message,
              time: messageResponse.data[message].createdAtTime,
              isReceived: messageResponse.data[message].fromUser.id !== userData.id
            })
          }

          setMessages(newMessages);

          console.log('performer user data', userResponse.data);
          setCurrentPerformer({...userResponse.data.userPerformance});

          // set initialized to true after all requests complete without errors
          setInitialized(true);
        })
        .catch(handleNetworkError)
    }

    console.log('current session data', data)
  }, [location, sessionState, userData])


  const validationSchema = Yup.object({

  });

  const handleSubmit = async (values, {setErrors}) => {

  };

  const newMessage = () => {
    setShowNewMessageField(true);
  };

  const clearMessages = () => {
    console.log('test clear messages')
    SessionMessage.clearMessages(currentSessionData.sessionId, userData?.id, currentPerformer?.user?.id)
      .then(response => {
        console.log('clear messages response', response);
        setMessages([]);
      })
      .catch(handleNetworkError)
  };

  const replyMessages = (index) => {
    setShowReplyField(true);
    setReplyMessageIndex(index);
  }

  const sendMessage = (index) => {
    setShowNewMessageField(false);
  }

  const handleSort = () => {
    setSortNewest(!sortNewest);
  };

  const handleMoveUp = () => {
    setBusy(true)

    console.log('move up', {id: currentSessionData.sessionId, currentPerformer});
    sessionActions.bumpUp(currentSessionData.sessionId, currentPerformer?.user?.id, true)
      .then(response => {
        console.log('bump up response', response);
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false))
  }

  const handleMoveDown = () => {
    setBusy(true)

    console.log('move down', {id: currentSessionData.sessionId, currentPerformer});
    sessionActions.bumpDown(currentSessionData.sessionId, currentPerformer?.user?.id, true)
      .then(response => {
        console.log('bump down response', response);
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false))
  }

  const handleMoveToTop = () => {
    console.log('move to top', {id: currentSessionData.sessionId, currentPerformer});

    if(currentPerformer?.user?.id === sessionState.queue[1]?.user?.id) {
      toast.error('This performer is already at the top of the queue.')
      return;
    }

    setBusy(true)

    sessionActions.bumpToTop(currentSessionData.sessionId, currentPerformer?.user?.id, true)
      .then(response => {
        console.log('bump to top response', response);
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false))
  }

  const handleRemoveFromQueue = () => {
    setBusy(true)

    console.log('remove from queue', {id: currentSessionData.sessionId, currentPerformer});
    sessionActions.hostRemoveFromQueue(currentSessionData.sessionId, currentPerformer?.user?.id)
      .then(response => {
        console.log('remove from queue response', response);
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false))
  }

  const refundUser = () => {
    setBusy(true)

    console.log('refund user', {id: currentSessionData.sessionId, currentPerformer});
    sessionActions.refundUser(currentSessionData.sessionId, currentPerformer?.id)
      .then(response => {
        console.log('refund user response', response);
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false))
  }

  const banPerformer = () => {
    setBusy(true)

    console.log('ban performer', {id: currentSessionData.sessionId, currentPerformer});
    sessionActions.banUser(currentSessionData.sessionId, currentPerformer?.id)
      .then(response => {
        console.log('ban performer response', response);
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false))
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerContent}>
        <Typography
          variant="h4"
          className={classes.title}
          sx={{
            marginBottom: '32px'
          }}
        >
          Manage Queue Entry
        </Typography>

        <Formik
            initialValues={{

            }}

            onSubmit={() => {}}
            validateOnChange={false}
          >
            {({
              values,
              errors,
              handleChange
            }) => (
            <Form className={classes.form}>
              <div className={classes.cardContainer}>
                <Typography variant="h5" className={classes.cardTitle}>
                  Queue Position: {currentQueuePosition}
                </Typography>

                <div className={classes.cardContainerInner}>
                  <div className={classes.cardRow}>
                    <div className={classes.cardLeftCol}>
                      {currentQueuePosition !== 'N/A' ? (
                        <>
                          <Typography
                            className={classes.cardSubtitle}
                            sx={{
                              fontSize: '24px',
                            }}
                          >
                            {currentPerformer?.user?.stageName}
                          </Typography>
                          {currentSessionData?.type === 'Karaoke' && (
                            <>
                              <Typography
                                className={classes.cardSubtitle}
                                sx={{
                                  marginTop: '16px',
                                  fontWeight: 'bold'
                                }}
                              >
                                {currentPerformer?.song?.songName}
                              </Typography>

                              <Typography className={classes.cardSubtitle}>
                                by:
                              </Typography>

                              <Typography
                                className={classes.cardSubtitle}
                                sx={{
                                  fontWeight: 'bold'
                                }}
                              >
                                {currentPerformer?.song?.artist}
                              </Typography>
                            </>
                          )}
                        </>
                      ) : (
                        <Typography
                          className={classes.cardSubtitle}
                          sx={{
                            fontSize: '24px',
                          }}
                        >
                          {location?.state?.performer?.stage_name}
                        </Typography>
                      )}
                    </div>

                    <div className={classes.cardRightCol}>
                      <div className={classes.buttonContainer}>
                        <Button
                          variant={'contained'}
                          className={classes.button}
                          onClick={banPerformer}
                          disabled={busy}
                        >
                          Ban Performer
                        </Button>
                      </div>
                      <div className={classes.buttonContainer}>
                        <Button
                          variant={'contained'}
                          className={classes.button}
                          onClick={handleMoveUp}
                          disabled={busy}
                        >
                          Move Up
                        </Button>
                      </div>
                      <div className={classes.buttonContainer}>
                        <Button
                          variant={'contained'}
                          className={classes.button}
                          onClick={handleMoveDown}
                          disabled={busy}
                        >
                          Move Down
                        </Button>
                      </div>
                      <div className={classes.buttonContainer}>
                        <Button
                          variant={'contained'}
                          className={classes.button}
                          onClick={handleMoveToTop}
                          disabled={busy}
                        >
                          Move to Top
                        </Button>
                      </div>
                      <div className={classes.buttonContainer}>
                        <Button
                          variant={'contained'}
                          className={classes.button}
                          disabled={busy}
                          onClick={handleRemoveFromQueue}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className={classes.cardFooterRow}>
                    <div className={classes.cardLeftCol}>
                      <Typography className={classes.cardSubtitle}>
                        Pending Bump Fee: ${queueEntry?.pending_bump_fee ? parseFloat(queueEntry?.pending_bump_fee/100).toFixed(2) : '0'}
                      </Typography>
                    </div>
                    <div className={classes.cardRightCol}>
                      <Button
                        variant={'contained'}
                        className={classes.button}
                        onClick={refundUser}
                      >
                        Refund
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              {/*<div className={classes.messagesContainer}>*/}
              {/*  <div className={classes.cardContainer}>*/}
              {/*    <div className={classes.cardTitle}>*/}
              {/*      <Typography variant={'h5'}>*/}
              {/*        Messages*/}
              {/*      </Typography>*/}

              {/*      <Button*/}
              {/*        variant={'contained'}*/}
              {/*        color={'primary'}*/}
              {/*        size={'small'}*/}
              {/*        onClick={newMessage}*/}
              {/*      >*/}
              {/*        New*/}
              {/*      </Button>*/}
              {/*      <Button*/}
              {/*        variant={'contained'}*/}
              {/*        color={'primary'}*/}
              {/*        size={'small'}*/}
              {/*        onClick={clearMessages}*/}
              {/*      >*/}
              {/*        Clear*/}
              {/*      </Button>*/}
              {/*      <Button*/}
              {/*        variant={'contained'}*/}
              {/*        color={'primary'}*/}
              {/*        size={'small'}*/}
              {/*        onClick={handleSort}*/}
              {/*        style={{minWidth: '72px'}}*/}
              {/*      >*/}
              {/*        {sortNewest ? 'Newest' : 'Oldest'}*/}
              {/*      </Button>*/}
              {/*    </div>*/}
              {/*    <div className={classes.cardContainerInner}>*/}

              {/*      {messages.map((data, index) => (*/}
              {/*        <div*/}
              {/*          className={data.isReceived ?*/}
              {/*            classes.bubbleContainer : classes.bubbleContainerRight}*/}
              {/*          key={data?.id}*/}
              {/*        >*/}
              {/*          <div  className={data.isReceived ? classes.bubble : classes.bubbleRight}>*/}
              {/*            <Typography className={classes.cardSubtitle}>*/}
              {/*              {data.text}*/}
              {/*            </Typography>*/}
              {/*            {data.isReceived ?*/}
              {/*              <Typography className={classes.timeText}>*/}
              {/*                Received at {data.time}*/}
              {/*              </Typography>*/}
              {/*              : (*/}
              {/*                <Typography className={classes.timeText}>*/}
              {/*                  Sent at {data.time}*/}
              {/*                </Typography>*/}
              {/*              )*/}
              {/*            }*/}
              {/*          </div>*/}

              {/*          {data.isReceived && (*/}
              {/*            <div className={classes.replyContaienr}>*/}
              {/*              {showReplyField && replyMessageIndex === index ?*/}
              {/*                <div className={classes.formInputContainer}>*/}
              {/*                  <TextField*/}
              {/*                    name="message"*/}
              {/*                    label="Message"*/}
              {/*                    placeholder="Message"*/}
              {/*                    className={classes.formInput}*/}
              {/*                    error={!!errors.message}*/}
              {/*                    helperText={errors.message}*/}
              {/*                    value={values.message}*/}
              {/*                    onChange={handleChange}*/}
              {/*                    fullWidth*/}
              {/*                    variant="outlined"*/}
              {/*                    multiline*/}
              {/*                    minRows={5}*/}
              {/*                  />*/}

              {/*                  <Button*/}
              {/*                    variant={'contained'}*/}
              {/*                    color={'primary'}*/}
              {/*                    onClick={sendMessage}*/}
              {/*                    className={classes.sendButton}*/}
              {/*                  >*/}
              {/*                    Send*/}
              {/*                  </Button>*/}
              {/*                </div>*/}
              {/*                :*/}
              {/*                null*/}
              {/*                // <Button*/}
              {/*                //   variant={'contained'}*/}
              {/*                //   color={'primary'}*/}
              {/*                //   onClick={() => replyMessages(index)}*/}
              {/*                //   className={classes.replyButton}*/}
              {/*                // >*/}
              {/*                //   Reply*/}
              {/*                // </Button>*/}
              {/*              }*/}
              {/*            </div>*/}
              {/*          )}*/}

              {/*        </div>*/}
              {/*      ))}*/}

              {/*      {showNewMessageField &&*/}
              {/*      <div className={classes.formInputContainer}>*/}
              {/*        <TextField*/}
              {/*          name="newMessage"*/}
              {/*          label="New Message"*/}
              {/*          placeholder="Type your message..."*/}
              {/*          className={classes.formInput}*/}
              {/*          error={!!errors.newMessage}*/}
              {/*          helperText={errors.newMessage}*/}
              {/*          value={values.newMessage}*/}
              {/*          onChange={handleChange}*/}
              {/*          fullWidth*/}
              {/*          variant="outlined"*/}
              {/*          multiline*/}
              {/*          minRows={5}*/}
              {/*        />*/}

              {/*        <Button*/}
              {/*          variant={'contained'}*/}
              {/*          color={'primary'}*/}
              {/*          onClick={sendMessage}*/}
              {/*          className={classes.sendButton}*/}
              {/*        >*/}
              {/*          Send*/}
              {/*        </Button>*/}
              {/*      </div>*/}
              {/*      }*/}

              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}


            </Form>
          )}
          </Formik>
      </div>
    </div>
  );
};

HostManageQueueEntryPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(HostManageQueueEntryPageStyle)(HostManageQueueEntryPage);
