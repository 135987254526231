const HostAccountSettingsPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    padding: theme.spacing(4, 0),
    width: '90%'
  },
  accordionSummary: {
    width: '100%'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: '16px !important',
    color: theme.palette.primary.main,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '18px',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  performanceHistoryTable: {
    marginTop: theme.spacing(2),
    '& th': {
      fontWeight: 'bold'
    },
    '& td': {
      borderBottom: 'none'
    }
  },
  tableHeaderCell: {
    fontSize: '13px !important',
    padding: '8px !important'
  },
  tableCell: {
    fontSize: '11px !important',
    padding: '8px !important'
  },
  performanceHistoryHeader: {
    marginBottom: theme.spacing(1)
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '80px !important',
    height: '80px !important',
    marginBottom: '16px'
  },
  avatarEditButton: {
    position: 'absolute !important',
    right: '-10px !important',
    bottom: '0 !important',
    background: theme.palette.primary.main + ' !important',
    zIndex: 100,
  },
  avatar: {
    width: '80px !important',
    height: '80px !important',
    margin: theme.spacing(2, 0),
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',

    '& > *': {
      margin: '16px 0 !important'
    }
  },
  input: {
    marginBottom: theme.spacing(2),
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '14px 14px',
      fontSize: '14px',
      color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.dark,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      },
    },
  },
  saveButton: {
    width: '100%',
    height: '48px',
    marginTop: theme.spacing(2),
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
  },
  notificationMethodContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: `${theme.spacing(2)}px 0 !important`,
    marginBottom: '16px !important'
  },
  notificationMethodTitle: {
    marginTop: '16px !important',
    width: '85%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  notificationMethodLabel: {
    marginRight: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  notificationMethodButton: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontSize: '14px',
    borderRadius: '4px',
    fontWeight: 600,
    textTransform: 'none',
  },
  notificationMethodRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  accordion: {
    width: '100%',

    marginTop: '0 !important',
    marginBottom: '0 !important',
    marginRight: '0 !important',
    marginLeft: '0 !important',

    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',

    '&:first-of-type': {
      marginTop: '0 !important',
    },
    '& .MuiAccordionSummary-root': {
      padding: theme.spacing(2, 3),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      '& .MuiAccordionSummary-content': {
        alignItems: 'center',
        '& .MuiTypography-root': {
          fontWeight: 600,
        },
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: theme.spacing(2, 3, 4),
    },
  },
  sectionTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  editButton: {
    background: theme.palette.primary.main + ' !important',
  },
  textFieldContainer: {
    margin: '32px 0 !important',
  },
  form: {
    width: '100%'
  }
});

export default HostAccountSettingsPageStyle;