import React, { useContext, useEffect, useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  Typography,
  TextField, Button, Avatar, FormControlLabel, Radio
} from '@mui/material';
import { IconButton } from '@mui/material';
import {useHistory, useLocation} from 'react-router-dom';
import { SessionContext } from 'context/SessionContext';
import {Session, User} from 'services';
import { UserContext } from 'context/UserContext';
import { handleNetworkError } from 'utils/utils';
import { toast } from 'react-toastify';
import EditIcon from "@mui/icons-material/Edit";
import {Form, Formik} from "formik";
import InputMask from "react-input-mask";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import EnterNotificationDetailsStyle from "pages/EnterNotificationDetails/EnterNotificationDetailsStyle";

const EnterNotificationDetails = ({ classes }) => {
  const location = useLocation();

  const history = useHistory();

  const { userData, setUserData } = useContext(UserContext);

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .min(17, 'Phone number is not filled out correctly')
      .max(17, 'Phone number is not filled out correctly'),
    email: Yup.string()
      .email('Invalid email address'),
  });

  const onSubmit = async (values, {setErrors}) => {
    console.log('submit account details: ', values)

    if(!values.phoneNumber && values.preferredNotificationForStageCall === 'phone') {
      toast.error('Please enter a phone number or select email for preferred notification method');
      return;
    }
    else if(!values.email && values.preferredNotificationForStageCall === 'email') {
      toast.error('Please enter an email or select phone for preferred notification method');
      return;
    }

    User.updateMe(values)
      .then(response => {
        console.log('update me response: ', response)
        toast.success('Account details updated successfully');

        let newUserData = {...userData};

        if(values.email) {
          newUserData.email = values.email;
        }

        if(values.phoneNumber) {
          newUserData.phoneNumber = values.phoneNumber;
        }

        setUserData(newUserData);

        history.push('/lobby');
      })
      .catch(handleNetworkError)
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>How would you like to be notified?</Typography>

      <Formik
        initialValues={{
          phoneNumber: userData?.phoneNumber || '',
          email: userData?.email || '',
          preferredNotificationForStageCall: userData?.preferredNotificationForStageCall || 'phone',
        }}
        enableReinitialize
        validateOnChange={false}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
          <Form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.textFieldContainer}>
              <InputMask
                mask={'+1 (999) 999-9999'}
                onChange={handleChange}
                value={values.phoneNumber}
                className={classes.textField}
              >
                {() => (
                  <TextField
                    label="Phone"
                    variant="outlined"
                    fullWidth
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                )}
              </InputMask>
            </div>
            <div className={classes.textFieldContainer}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                value={values.email}
                onChange={handleChange}
                className={classes.textField}
              />
            </div>

            <div className={classes.notificationMethodContainer}>
              <Typography variant="subtitle1" sx={{marginBottom: '20px', textAlign: 'center'}}>
                Preferred notification method for stage call:
              </Typography>
              <Typography variant={'body2'} style={{textAlign: 'center', marginBottom: '12px'}}>
                By selecting phone, you are opting in to receive SMS based messages. You can opt-out at any time by following the instructions in the messages.
              </Typography>
              <div className={classes.notificationMethodRow}>
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Phone"
                  labelPlacement="end"
                  value="phone"
                  name="preferredNotificationForStageCall"
                  onChange={handleChange}
                  checked={values.preferredNotificationForStageCall === 'phone'}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Email"
                  labelPlacement="end"
                  value="email"
                  name="preferredNotificationForStageCall"
                  onChange={handleChange}
                  checked={values.preferredNotificationForStageCall === 'email'}
                />
              </div>
            </div>

            <Button
              variant="contained"
              color="primary"
              type={'submit'}
              size={'small'}
              className={classes.saveButton}
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

EnterNotificationDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(EnterNotificationDetailsStyle)(EnterNotificationDetails);
