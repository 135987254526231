const MessageHostPageStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '600px',
    margin: '0 auto',
    width: '100%'
  },
  innerContent: {
    width: '90%',
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  cardContainer: {
    width: '100%',
    // height: '250px',
    margin: '32px auto',
    borderRadius: 8,
    padding: '0 !important',
    overflow: 'hidden',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  },
  cardTitle: {
    fontSize: '20px',
    width: '100%',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    paddingLeft: '16px'
  },
  cardContainerInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '16px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '32px'
  },
  formInput: {
    '& .MuiInputBase-input': {
      fontSize: '1.2rem',
      overflow: 'auto',
    },
  },
});

export default MessageHostPageStyle;