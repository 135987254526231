import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import AccountSettingsPageStyle from 'pages/AccountSettingsPage/AccountSettingsPageStyle';
import {
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton, FormControlLabel, Radio
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { UserContext } from 'context/UserContext';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import BillingForm from 'components/BillingForm/BillingForm';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeContext } from 'context/StripeContext';
import StripeForm from 'components/StripeForm/StripeForm';
import { handleNetworkError } from 'utils/utils';
import { User } from 'services';
import { toast } from 'react-toastify';
import InputMask from "react-input-mask";


const AccountSettings = props => {
  const { classes, location } = props;

  const {userData, setUserData} = useContext(UserContext);
  const history = useHistory();

  const [stageName, setStageName] = useState('');

  const [socialMediaLink, setSocialMediaLink] = useState('https://');
  const [socialMediaLinkError, setSocialMediaLinkError] = useState('');

  const [selectedFile, setSelectedFile] = useState(null);
  const [avatarURL, setAvatarURL] = useState(userData?.profilePicture);

  const [updatingCard, setUpdatingCard] = useState(false);

  // for account details
  const [accountDetailsPhoneEditable, setAccountDetailsPhoneEditable] = useState(false);
  const [accountDetailsEmailEditable, setAccountDetailsEmailEditable] = useState(false);

  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    if(userData) {
      setStageName(userData.stageName);
      setSocialMediaLink(userData.socialMediaLink);

      if(userData?.profilePicture) {
        setAvatarURL(userData.profilePicture);
      }
    }
    else if(userData === false) {
      history.push('/');
    }
  }, [userData])

  const eventUpdatesValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  });

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .min(17, 'Phone number is not filled out correctly')
      .max(17, 'Phone number is not filled out correctly'),
    email: Yup.string()
      .email('Invalid email address'),
    currentPassword: Yup.string(),
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .max(30, 'Password must be at most 30 characters long'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const handleSocialMediaLinkChange = (event) => {
    const link = event.target.value;
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      setSocialMediaLinkError('Link must start with "http://" or "https://"');
    } else {
      setSocialMediaLinkError('');
    }
    setSocialMediaLink(link);
  };

  const handleFileUploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setAvatarURL(reader.result);
    };
    reader.readAsDataURL(file);

    sendFileToServer(file);
  };

  const sendFileToServer = (file) => {
    const formData = new FormData();
    formData.append('profile_picture', file);

    console.log('send to server', formData)

    User.changeMyProfilePicture(formData)
      .then(response => {
        console.log('change my profile picture response: ', response)
        toast.success('Profile picture updated successfully');
      })
      .catch(handleNetworkError)
  };

  const handleSavePersonalDetails = () => {
    let values = {
      stageName: stageName,
      socialMediaLink: socialMediaLink
    }

    // make sure the social media link starts with "http://" or "https://" before saving
    if(!socialMediaLink.startsWith('http://') && !socialMediaLink.startsWith('https://')) {
      setSocialMediaLinkError('Link must start with "http://" or "https://"');
      return;
    }

    User.updateMe(values)
      .then(response => {
        console.log('update me response: ', response)
        toast.success('Personal details updated successfully');
      })
      .catch(handleNetworkError)
  };

  const handlePhoneEdit = () => {
    setAccountDetailsPhoneEditable(!accountDetailsPhoneEditable)
  }

  const handleEmailEdit = () => {
    setAccountDetailsEmailEditable(!accountDetailsEmailEditable)
  }

  useEffect(() => {
  }, [socialMediaLink])

  const onSubmit = async (values, {setErrors}) => {
    console.log('submit account details: ', values)
    User.updateMe(values)
      .then(response => {
        console.log('update me response: ', response)
        toast.success('Account details updated successfully');

        let newUserData = {...userData};

        if(values.email) {
          newUserData.email = values.email;
        }

        if(values.phoneNumber) {
          newUserData.phoneNumber = values.phoneNumber;
        }

        setUserData(newUserData);
      })
      .catch(handleNetworkError)
  };

  const onSubmitEventUpdates = async (values, {setErrors}) => {
    console.log('submit event updates details: ', values)
    User.updateMe(values)
      .then(response => {
        console.log('update me response: ', response)
        toast.success('Event details updated successfully');
      })
      .catch(handleNetworkError);
  }

  return (
    <div className={classes.root}>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{display: 'none'}}
      />

      <Typography variant="h4" className={classes.title}>
        Account Settings
      </Typography>

      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          <Typography variant="subtitle1" className={classes.accordionTitle}>
            Personal Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.avatarContainer}>
            <IconButton
              className={classes.avatarEditButton}
              size={'small'}
              onClick={handleFileUploadClick}
            >
              <EditIcon sx={{color: 'white', fontSize: '18px'}}/>
            </IconButton>
            <Avatar
              alt="Profile picture"
              src={avatarURL}
              className={classes.avatar}
            />
          </div>

          <div className={classes.inputContainer}>
            <TextField
              label="Stage Name"
              variant="outlined"
              fullWidth
              value={stageName}
              onChange={(e) => {setStageName(e.target.value)}}
              className={classes.textField}
            />
            <TextField
              label="Social Media Link"
              variant="outlined"
              fullWidth
              value={socialMediaLink}
              onChange={handleSocialMediaLinkChange}
              error={!!socialMediaLinkError}
              helperText={socialMediaLinkError}
              className={classes.textField}
            />
            <Button variant="contained" color="primary" onClick={handleSavePersonalDetails} className={classes.saveButton}>Save</Button>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          <Typography variant="subtitle1" className={classes.accordionTitle}>
            Performance History
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell}>Date</TableCell>
                  <TableCell className={classes.tableHeaderCell}>Venue</TableCell>
                  <TableCell className={classes.tableHeaderCell}>Time</TableCell>
                  <TableCell className={classes.tableHeaderCell}>Host</TableCell>
                  <TableCell className={classes.tableHeaderCell}>Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData?.performanceHistory?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCell}>{row.date}</TableCell>
                    <TableCell className={classes.tableCell}>{row.venue}</TableCell>
                    <TableCell className={classes.tableCell}>{row.time}</TableCell>
                    <TableCell className={classes.tableCell}>{row.host}</TableCell>
                    <TableCell className={classes.tableCell}>{row.score}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          <Typography variant="subtitle1" className={classes.accordionTitle}>
            Account Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Formik
            initialValues={{
              phoneNumber: userData?.phoneNumber || '',
              email: userData?.email || '',
              password: '',
              newPassword: '',
              confirmNewPassword: '',
              preferredNotificationForStageCall: userData?.preferredNotificationForStageCall || 'phone',
            }}
            enableReinitialize
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
              <Form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.textFieldContainer}>
                  {accountDetailsPhoneEditable ? (
                    <InputMask
                      mask={'+1 (999) 999-9999'}
                      onChange={handleChange}
                      value={values.phoneNumber}
                      className={classes.textField}
                    >
                      {() => (
                        <TextField
                          label="Phone"
                          variant="outlined"
                          fullWidth
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                className={classes.editButton}
                                size={'small'}
                                onClick={handlePhoneEdit}
                              >
                                {accountDetailsPhoneEditable ?
                                  <CloseIcon sx={{color: 'white', fontSize: '18px'}}/>
                                  :
                                  <EditIcon sx={{color: 'white', fontSize: '18px'}}/>
                                }
                              </IconButton>
                            ),
                          }}
                          className={classes.textField}
                        />
                      )}
                    </InputMask>
                  ) : (
                    <TextField
                      name={"phoneNumber"}
                      type="text"
                      label="Phone Number"
                      fullWidth
                      variant="outlined"
                      disabled
                      className={classes.textField}
                      value={values.phoneNumber}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            className={classes.editButton}
                            size={'small'}
                            onClick={handlePhoneEdit}
                          >
                            <EditIcon sx={{color: 'white', fontSize: '18px'}}/>
                          </IconButton>
                        ),
                      }}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber}
                    />
                  )}
                </div>
                <div className={classes.textFieldContainer}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    disabled={!accountDetailsEmailEditable}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          className={classes.editButton}
                          size={'small'}
                          onClick={handleEmailEdit}
                        >
                          {accountDetailsEmailEditable ?
                            <CloseIcon sx={{color: 'white', fontSize: '18px'}}/>
                            :
                            <EditIcon sx={{color: 'white', fontSize: '18px'}}/>
                          }
                        </IconButton>
                      ),
                    }}
                    className={classes.textField}
                  />
                </div>
                <div className={classes.textFieldContainer}>
                  <TextField
                    label="Current Password"
                    variant="outlined"
                    fullWidth
                    name="password"
                    type={'password'}
                    value={values.password}
                    onChange={handleChange}
                    className={classes.textField}
                    error={!!errors.password}
                    helperText={errors.password}
                  />
                </div>
                <div className={classes.textFieldContainer}>
                  <TextField
                    label="New Password (optional)"
                    variant="outlined"
                    fullWidth
                    name="newPassword"
                    type={'password'}
                    value={values.newPassword}
                    onChange={handleChange}
                    className={classes.textField}
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                  />
                </div>
                <div className={classes.textFieldContainer}>
                  <TextField
                    label="Re-enter New Password (optional)"
                    variant="outlined"
                    fullWidth
                    name="confirmNewPassword"
                    type={'password'}
                    value={values.confirmNewPassword}
                    onChange={handleChange}
                    className={classes.textField}
                    error={!!errors.confirmNewPassword}
                    helperText={errors.confirmNewPassword}
                  />
                </div>
                <div className={classes.notificationMethodContainer}>
                  <Typography variant="subtitle1" sx={{marginBottom: '20px', textAlign: 'center'}}>
                    Preferred notification method for stage call:
                  </Typography>
                  <Typography variant={'body2'} style={{textAlign: 'center', marginBottom: '12px'}}>
                    By selecting phone, you are opting in to receive SMS based messages. You can opt-out at any time by following the instructions in the messages.
                  </Typography>
                  <div className={classes.notificationMethodRow}>
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Phone"
                      labelPlacement="end"
                      value="phone"
                      name="preferredNotificationForStageCall"
                      onChange={handleChange}
                      checked={values.preferredNotificationForStageCall === 'phone'}
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Email"
                      labelPlacement="end"
                      value="email"
                      name="preferredNotificationForStageCall"
                      onChange={handleChange}
                      checked={values.preferredNotificationForStageCall === 'email'}
                    />
                  </div>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  type={'submit'}
                  size={'small'}
                  className={classes.saveButton}
                >
                  Save
                </Button>
              </Form>
            )}
          </Formik>

        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} defaultExpanded={location?.state?.dropdownToOpen === 'billing'}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          <Typography variant="subtitle1" className={classes.accordionTitle}>
            Billing Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {userData?.last4 && !updatingCard ? (
            <div>
              <Typography sx={{marginBottom: '8px'}}>
                Card ending in {userData?.last4}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size={'small'}
                onClick={() => setUpdatingCard(true)}
              >
                Update Card
              </Button>
            </div>
          ) : (
            <StripeForm onSuccess={() => {
              setUpdatingCard(false)
            }} />
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          <Typography variant="subtitle1" className={classes.accordionTitle}>
            Event Updates
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Formik
            initialValues={{
              email: userData?.newsletterEmail || '',
              subscribed: userData?.newsletterSubscribed || false,
            }}
            enableReinitialize
            validateOnChange={false}
            validationSchema={eventUpdatesValidationSchema}
            onSubmit={onSubmitEventUpdates}
          >
            {({
                values,
                errors,
                handleChange,
                setFieldValue
              }) => (
              <Form className={classes.form}>
                <Typography variant={'subtitle1'} sx={{marginBottom: '32px', textAlign: 'center'}}>
                  Receive mail about upcoming events?
                </Typography>

                <div className={classes.textFieldContainer}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    className={classes.textField}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </div>

                <div className={classes.notificationMethodContainer}>
                  <div className={classes.notificationMethodRow}>
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Subscribe"
                      labelPlacement="end"
                      value={values.subscribed === false}
                      name="subscribed"
                      onChange={() => setFieldValue('subscribed', false)}
                      checked={values.subscribed === false}
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Unsubscribe"
                      labelPlacement="end"
                      value={values.subscribed === true}
                      name="subscribed"
                      onChange={() => setFieldValue('subscribed', true)}
                      checked={values.subscribed === true}
                    />
                  </div>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  type={'submit'}
                  size={'small'}
                  className={classes.saveButton}
                >
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

AccountSettings.propTypes = {
  classes: PropTypes.object
};

export default withStyles(AccountSettingsPageStyle)(AccountSettings);
