import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Session, User } from 'services';
import { formatForWebsocket, handleNetworkError } from 'utils/utils';
import { UserContext } from 'context/UserContext';
import { toast } from 'react-toastify';
import APPLICATION_SETTINGS from 'settings';
import snakecaseKeys from 'snakecase-keys';
import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import NewSessionTokenDialogStyle from 'dialogs/NewSessionTokenDialog/NewSessionTokenDialogStyle';
import NewSessionTokenDialog from 'dialogs/NewSessionTokenDialog/NewSessionTokenDialog';
import { useHistory } from 'react-router-dom';


export const SessionContext = createContext();

const DEFAULT_SESSION_STATE = {
  connected: false,
  queue: [],
  scoreboard: [],
  wsConnection: null,
  disableBump: false,
  paused: false,
  currentlyPerforming: false,
  currentPerformance: null,
  amountSpentOnBumps: 0,
  hostMessages: [],
  unreadMessageUsers: [],
  amountOfTips: 0,
  bumps: [],
}

const SessionProvider = ({children}) => {
  const [sessionData, setSessionData] = useState(null);
  const [webSocket, setWebSocket] = useState(null);

  const [currentSessionData, setCurrentSessionData] = useState(null);

  const [websocketInitialized, setWebsocketInitialized] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const {userData} = useContext(UserContext);

  const sessionDataRef = useRef(sessionData);
  const userDataRef = useRef(userData);
  const currentSessionDataRef = useRef(currentSessionData);



  const [sessionState, setSessionState] = useState(DEFAULT_SESSION_STATE);

  const history = useHistory();

  // used for the dialog that pops up when the session code changes
  const [showNewSessionTokenDialog, setShowNewSessionTokenDialog] = useState(false);

  const sessionStateRef = useRef(sessionState);

  useEffect(() => {
    userDataRef.current = userData;
  }, [userData]);

  useEffect(() => {
    sessionDataRef.current = sessionData;
  }, [sessionData]);

  useEffect(() => {
    sessionStateRef.current = sessionState;
  }, [sessionState]);

  useEffect(() => {
    currentSessionDataRef.current = currentSessionData;
  }, [currentSessionData])

  useEffect(() => {
    if(userData !== null && currentSessionData && webSocket && !websocketInitialized && initialized && userData?.isHost && sessionState.connected === true) {
      console.log('attempt join session', {sessionData, userData})
      sessionActions.hostJoinSession(currentSessionData?.sessionId, currentSessionData.id, userData?.token);

      setWebsocketInitialized(true);

      let newSessionState = {...sessionState};
      newSessionState.connected = true;
      setSessionState(newSessionState);
    }
  }, [sessionState, userData, sessionData, initialized, webSocket, websocketInitialized])

  useEffect(() => {
    if (!sessionData && !initialized && userData) {
      console.log('userdata', userData);
      console.log('requesting session data for ', userData);
      if(userData?.isHost) {
        Session.listAll(userData?.id)
          .then(response => {
            console.log('session data response', response);
            setSessionData(response.data);
            setInitialized(true);
          })
          .catch(error => {
            setSessionData(false);
            handleNetworkError(error, false, false)
          })
      }
      else{
        Session.listAll()
          .then(response => {
            console.log('session data response', response);
            setSessionData(response.data);
            setInitialized(true);
          })
          .catch(error => {
            setSessionData(false);
            handleNetworkError(error, false, false)
          })
      }
    }
  }, [sessionData, userData]);

  let reconnectInterval;

  const setupWebSocket = (setWebSocket, setSessionState, sessionStateRef) => {
    // Clear existing interval, if any
    if (reconnectInterval) {
      clearInterval(reconnectInterval);
    }

    console.log(`attempt connection to websocket... ${APPLICATION_SETTINGS.DJANGO_WEBSOCKET_URL}`);
    const ws = new WebSocket(APPLICATION_SETTINGS.DJANGO_WEBSOCKET_URL);

    ws.onopen = () => {
      console.log('WebSocket connection established.');
      setWebSocket(ws);

      // Clear the reconnection interval once successfully connected
      if (reconnectInterval) {
        clearInterval(reconnectInterval);
      }

      let newSessionState = {...sessionStateRef.current};
      newSessionState.wsConnection = ws;
      newSessionState.connected = true;
      setSessionState(newSessionState);
    };

    ws.onmessage = (event) => {
      console.log('message from django', {data: event.data, newSessionState: sessionStateRef.current});

      const data = JSON.parse(event.data);

      let newSessionState = {...sessionStateRef.current};
      let newSessionData

      if (sessionDataRef.current) {
        newSessionData =  [...sessionDataRef.current];
      }
      else {
        newSessionData = [];
      }


      switch (data.type) {
        case 'queue_update':
        case 'session_joined':
          newSessionState.queue = data.content.queue;
          newSessionState.scoreboard = data.content.scoreboard;
          newSessionState.wsConnection = ws;
          newSessionState.removedUsers = data.content?.removed_users;
          newSessionState.amountSpentOnBumps = data.content?.amount_spent_on_bumps;
          newSessionState.totalNumberOfBumps = data.content?.total_number_of_bumps;
          newSessionState.currentlyPerforming = data.content.currently_performing;
          newSessionState.currentPerformance = data.content.current_performance;
          newSessionState.amountOfTips = data.content.amount_of_tips;
          newSessionState.bumps = data.content.bumps;
          newSessionState.connected = true;

          break;

        case 'host_session_joined':
          console.log('host joined session');
          newSessionState.queue = data.content.queue;
          newSessionState.scoreboard = data.content.scoreboard;
          newSessionState.wsConnection = ws;
          newSessionState.currentlyPerforming = data.content.currently_performing;
          newSessionState.currentPerformance = data.content.current_performance;
          newSessionState.removedUsers = data.content?.removed_users;
          newSessionState.amountSpentOnBumps = data.content?.amount_spent_on_bumps;
          newSessionState.totalNumberOfBumps = data.content?.total_number_of_bumps;
          newSessionState.unreadMessageUsers = data.content?.unread_message_users;
          newSessionState.amountOfTips = data.content.amount_of_tips;
          newSessionState.bumps = data.content.bumps;

          // Update state, show confirmation message to the host, or do other necessary operations.
          break;

        case 'host_user_removed':
          console.log('host removed user');
          toast.success('User has been removed from the session.')
          break;

        case 'banned':
          console.log('banned', {data, newSessionState});

          if(data.content.user_id === userDataRef.current.id) {
            toast.error(`You have been banned from this session.`);
            history.push('/lobby');
          }

          break;

        case 'refund_user':
          console.log('receive refund message host', {data, newSessionState});

          if(data.content.user_id === userDataRef.current.id && data.content.amount > 0) {
            toast.success(`You have been refunded $${parseFloat(data.content.amount/100).toFixed(2)} for your bumps by the host.`);

          }

          newSessionState.amountSpentOnBumps = data.content?.amount_spent_on_bumps;
          newSessionState.totalNumberOfBumps = data.content?.total_number_of_bumps;
          newSessionState.amountOfTips = data.content?.amount_of_tips;
          newSessionState.queue = data?.content?.queue;
          newSessionState.scoreboard = data?.content?.scoreboard;

          break;

        case 'receive_message_host':
          let parsedData = JSON.parse(data.message);


          console.log('receive message host', {parsedData, data, newSessionState});


          newSessionState.unreadMessageUsers.push(parsedData.content.from_user.id)
          newSessionState.hostMessages.push(parsedData.content);

          break;

        case 'session_toggled':
          console.log('session toggled', {newSessionState, data});

          newSessionState.paused = data.content.paused;

          break;

        case 'session_ended':
          newSessionState = DEFAULT_SESSION_STATE;

          toast.success('The host has ended your session and you have been returned to the lobby.');

          if(sessionDataRef.current) {
            newSessionData = [...sessionDataRef.current];
          }
          else {
            newSessionData = [];
          }

          try {
            // delete found session from newSessionData
            newSessionData = newSessionData.filter(session => session.id !== data.content.id);

            setSessionData(newSessionData);
          }
          catch (e) {
            console.log('error finding session', e);
          }


          history.push('/lobby');

          break;

        case 'session_performance_changed':
          console.log('session performance changed', {newSessionState, data, test: data.content.id === newSessionState.id});

          newSessionState.currentlyPerforming = data.content.currently_performing;
          newSessionState.queue = data.content.queue;
          newSessionState.scoreboard = data.content.scoreboard;
          newSessionState.currentPerformance = data.content.current_performance;

          break;

        case 'session_id_changed':
          console.log('session id changed', {sessionData, data, currentSessionData: sessionDataRef.current});
          // TODO: make dialogs appear here if user is in a session to confirm the code

          if(!userDataRef?.current?.isHost) {
            setShowNewSessionTokenDialog(true);
          }

          // find the session in the list of sessions and change the code to match
          if(sessionDataRef.current) {
            newSessionData = [...sessionDataRef.current];
          }
          else {
            newSessionData = [];
          }

          console.log('initial new session data', newSessionData);

          const sessionDataObj = newSessionData.find(elem => elem.id === data.content['session_id'])

          console.log('session data obj', sessionDataObj);

          sessionDataObj.sessionId = data.content['new_session_code'];

          console.log('new session data', newSessionData);
          console.log('old session data', sessionData);

          setSessionData(newSessionData);

          if(sessionDataObj?.id === currentSessionDataRef.current?.id) {
            setCurrentSessionData(sessionDataObj);
          }

          sessionDataRef.current = newSessionData;

          break;

        case 'tip_counted':
          console.log('tip counted', {newSessionState, data});

          newSessionState.amountOfTips = data.content.amount_of_tips;
          break;

        case 'error':
          // TODO: figure this out
          console.log('error', data);
          break;

        default:
          // Handle other message types, if necessary.
          break;
      }

      console.log('new session state', newSessionState);
      setSessionState(newSessionState);
    };

    ws.onclose = () => {
      console.log('WebSocket closed, attempting to reconnect...');
      setWebSocket(null);

      let newSessionState = {...sessionState, connected: false, wsConnection: null};
      setSessionState(newSessionState);

      // Poll every 30 seconds to try to re-establish the connection
      reconnectInterval = setInterval(() => setupWebSocket(setWebSocket, setSessionState, sessionStateRef), 10000);
    };
  };

  useEffect(() => {
    if (!webSocket || !sessionState?.wsConnection) {
      setupWebSocket(setWebSocket, setSessionState, sessionStateRef);
    }

    return () => {
      // Clear the interval when the component unmounts
      if (reconnectInterval) {
        clearInterval(reconnectInterval);
      }
    };
  }, [sessionData, webSocket]);

  useEffect(() => {
    return () => {
      if(webSocket) {
        console.log('disconnecting websocket');
        webSocket.close();
      }
    }
  }, [])

  const sessionActions = {
    setupWebSocket: () => setupWebSocket(setWebSocket, setSessionState, sessionStateRef),

    banUser: async (sessionId, queueEntryId) => {
      if(!webSocket) return;

      const message = formatForWebsocket('ban_user', {
        token: userData?.token,
        session_id: sessionId,
        queue_entry_id: queueEntryId,
      });

      console.log('ban user message', message);

      webSocket.send(message);

      return true;
    },

    refundUser: async (sessionId, queueEntryId) => {
      if(!webSocket) return;

      const message = formatForWebsocket('refund_user', {
        token: userData?.token,
        session_id: sessionId,
        queue_entry_id: queueEntryId,
      });

      webSocket.send(message);

      return true;
    },

    countTip: async (sessionId) => {
      if(!webSocket) return;

      const message = formatForWebsocket('count_tip', {
        token: userData?.token,
        session_id: sessionId,
      })

      webSocket.send(message);

      return true;
    },

    verifyCode: async (code) => {
      if(!webSocket) return;

      const message = formatForWebsocket('verify_code', {
        token: userData?.token,
        session_code: code,
      })

      webSocket.send(message);

      return true;
    },

    joinSession: async (sessionCode, sessionId) => {
      if(!webSocket) return;

      console.log('join session', {sessionCode, sessionId})

      const message = formatForWebsocket('join_session', {
        token: userData?.token,
        session_code: sessionCode,
        session_id: sessionId,
      })

      webSocket.send(message);

      return true;
    },

    joinQueue: async (sessionCode, sessionId, songId) => {
      if(!webSocket) return;

      console.log('join session', {sessionCode, sessionId, songId})

      const message = formatForWebsocket('join_queue', {
        token: userData?.token,
        session_code: sessionCode,
        song_id: songId,
      });

      webSocket.send(message);

      return true;
    },

    messageHost: async (sessionCode, message) => {
      if(!webSocket) return;

      const wsMsg = formatForWebsocket('message_host', {
        token: userData?.token,
        session_code: sessionCode,
        message: message,
      })

      console.log('send to websocket', {
        token: userData?.token,
        session_code: sessionCode,
        message: message,
      })

      webSocket.send(wsMsg);

      return true;
    },

    hostRemoveFromQueue: async (sessionCode, userId) => {
      if (!webSocket) return;

      console.log("host remove from queue", { sessionCode });

      if(!userId) {
        userId = userData?.id;
      }

      const message = formatForWebsocket("host_remove_from_queue", {
        user: userId,
        session_code: sessionCode,
      });

      webSocket.send(message);

      return true;
    },

    removeFromQueue: async (sessionCode, userId) => {
      if (!webSocket) return;

      console.log("remove from queue", { sessionCode });

      if(!userId) {
        userId = userData?.id;
      }

      const message = formatForWebsocket("remove_from_queue", {
        user: userId,
        session_code: sessionCode,
      });

      webSocket.send(message);

      return true;
    },

    changeSong: async (sessionCode, songId) => {
      if (!webSocket) return;

      console.log("change song", { sessionCode, songId });

      const message = formatForWebsocket("change_song", {
        token: userData?.token,
        session_code: sessionCode,
        song_id: songId,
      });

      webSocket.send(message);

      return true;
    },

    // userToken should be used if you want to target a user other than the current user
    // e.g. hosts use this a lot. If you want to target another user, just provide their token
    bumpToTop: async (sessionCode, userId, isHost=false) => {
      if (!webSocket) return;

      if(sessionState.disableBump) {
        toast.error('Bumps are currently disabled.');
        return;
      }

      console.log("bump to top", { sessionCode });

      if(!userId) userId = userData?.id;

      const message = formatForWebsocket("bump_to_top", {
        user: userId,
        session_code: sessionCode,
        is_host: isHost
      });

      webSocket.send(message);

      return true;
    },

    // userToken should be used if you want to target a user other than the current user
    // e.g. hosts use this a lot. If you want to target another user, just provide their token
    bumpUp: async (sessionCode, userId, isHost=false) => {
      if (!webSocket) return;

      if(sessionState.disableBump) {
        toast.error('Bumps are currently disabled.');
        return;
      }

      console.log("bump up", { sessionCode });

      if(!userId) userId = userData?.id;

      const message = formatForWebsocket("bump_up", {
        user: userId,
        session_code: sessionCode,
        is_host: isHost
      });

      webSocket.send(message);

      return true;
    },

    // userToken should be used if you want to target a user other than the current user
    // e.g. hosts use this a lot. If you want to target another user, just provide their token
    bumpDown: async (sessionCode, userId, isHost=false) => {
      if (!webSocket) return;

      if(sessionState.disableBump) {
        toast.error('Bumps are currently disabled.');
        return;
      }

      console.log("bump down", { sessionCode });

      if(!userId) userId = userData?.id;

      const message = formatForWebsocket("bump_down", {
        user: userId,
        session_code: sessionCode,
        is_host: isHost
      });

      webSocket.send(message);

      return true;
    },

    // HOST ACTIONS
    hostJoinSession: async (sessionCode, sessionId, token) => {
      if(!webSocket) return;

      console.log('host join session', {sessionCode, sessionId, userData})

      const message = formatForWebsocket('host_join_session', {
        token: token || userData?.token,
        session_code: sessionCode,
        session_id: sessionId,
      })

      webSocket.send(message);

      return true;
    },

    rerollCode: async (sessionCode) => {
      console.log('reroll code action', webSocket)
      if (!webSocket) return;

      console.log("reroll code", { sessionCode });

      const message = formatForWebsocket("reroll_code", {
        token: userData?.token,
        session_code: sessionCode,
      });

      webSocket.send(message);

      return true;
    },

    toggleSessionActive: async (sessionCode) => {
      if (!webSocket) return;

      console.log("toggle session active", { sessionCode });

      const message = formatForWebsocket("toggle_session_active", {
        token: userData?.token,
        session_code: sessionCode,
      });

      webSocket.send(message);

      return true;
    },

    startNewPerformance: async (sessionCode) => {
      if (!webSocket) return;

      console.log("toggle current performance", { sessionCode });

      const message = formatForWebsocket("start_new_performance", {
        token: userData?.token,
        session_code: sessionCode,
      });

      webSocket.send(message);

      return true;
    },

    completePerformance: async (sessionCode) => {
      if (!webSocket) return;

      console.log("complete performance", { sessionCode });

      const message = formatForWebsocket("complete_performance", {
        token: userData?.token,
        session_code: sessionCode,
      });

      webSocket.send(message);

      return true;
    },

    cancelPerformance: async (sessionCode) => {
      if (!webSocket) return;

      console.log("cancel performance", { sessionCode });

      const message = formatForWebsocket("cancel_performance", {
        token: userData?.token,
        session_code: sessionCode,
      });

      webSocket.send(message);

      return true;
    },

    addGuest: async (sessionCode, guestData) => {
      if (!webSocket) return;

      let data = {
        token: userData?.token,
        session_code: sessionCode,
        ...snakecaseKeys(guestData, {deep: true}),
      }

      console.log('add guest', data)

      const message = formatForWebsocket("add_guest", data);

      webSocket.send(message);

      return true;
    },

    setScore: async (token, sessionCode, entryId, score) => {
      if (!webSocket) return;

      let data = {
        token: token,
        session_code: sessionCode,
        queue_entry_id: entryId,
        score
      };

      console.log('set score', data);

      const message = formatForWebsocket('set_score', data);

      webSocket.send(message);

      return true;
    },

    endSession: async (sessionCode, token) => {
      if (!webSocket) return;

      console.log("end session", { sessionCode });

      const message = formatForWebsocket("end_session", {
        token: token || userData?.token,
        session_code: sessionCode,
      });

      webSocket.send(message);

      return true;
    }
  }

  useEffect(() => {
    console.log('debug test', currentSessionData)
  }, [currentSessionData])

  const values = {
    sessionData: sessionDataRef.current,
    setSessionData,

    sessionState: sessionState,
    setSessionState,

    setCurrentSessionData,
    currentSessionData,

    sessionActions
  }

  return (
    <SessionContext.Provider value={values}>
      <>
        {children}

        <NewSessionTokenDialog
          open={showNewSessionTokenDialog}
          setOpen={setShowNewSessionTokenDialog}
          currentSessionData={sessionDataRef.current}
          onSuccess={() => {
            setShowNewSessionTokenDialog(false);
          }}
        />
      </>
    </SessionContext.Provider>
  );
};


export default SessionProvider;

export { DEFAULT_SESSION_STATE };
