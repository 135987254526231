const BillingFormStyles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textField: {
    margin: theme.spacing(2),
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
  },
});

export default BillingFormStyles;
