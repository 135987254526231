import React, { useContext, useEffect, useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  Typography,
  TextField, Button, Avatar
} from '@mui/material';
import { IconButton } from '@mui/material';
import {useHistory, useLocation} from 'react-router-dom';
import { SessionContext } from 'context/SessionContext';
import {Session, User} from 'services';
import { UserContext } from 'context/UserContext';
import { handleNetworkError } from 'utils/utils';
import { toast } from 'react-toastify';
import EnterStageNameAndPhotoStyle from "pages/EnterStageNameAndPhoto/EnterStageNameAndPhotoStyle";
import EditIcon from "@mui/icons-material/Edit";

const EnterStageNameAndPhoto = ({ classes }) => {
  const location = useLocation();

  const history = useHistory();

  const { sessionData, setSessionData, currentSessionData, setCurrentSessionData } = useContext(SessionContext);
  const { userData } = useContext(UserContext);

  const [stageName, setStageName] = useState('');
  const [socialMediaLink, setSocialMediaLink] = useState('');
  const [socialMediaLinkError, setSocialMediaLinkError] = useState('');
  const [avatarURL, setAvatarURL] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    if(userData) {
      setStageName(userData.stageName);
      setSocialMediaLink(userData.socialMediaLink);

      if(userData?.profilePicture) {
        setAvatarURL(userData.profilePicture);
      }
    }
    else if(userData === false) {
      history.push('/');
    }
  }, [userData])

  const handleSocialMediaLinkChange = (event) => {
    const link = event.target.value;
    setSocialMediaLink(link);
  };

  const handleFileUploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setAvatarURL(reader.result);
    };
    reader.readAsDataURL(file);

    sendFileToServer(file);
  };

  const sendFileToServer = (file) => {
    const formData = new FormData();
    formData.append('profile_picture', file);

    console.log('send to server', formData)

    User.changeMyProfilePicture(formData)
      .then(response => {
        console.log('change my profile picture response: ', response)
        toast.success('Profile picture updated successfully');
      })
      .catch(handleNetworkError)
  };

  const handleSavePersonalDetails = () => {
    let values = {
      stageName: stageName,
      socialMediaLink: socialMediaLink
    }

    if(!stageName) {
      toast.error('Please fill in stage name.');
      return;
    }

    // make sure the social media link starts with "http://" or "https://" before saving
    if(socialMediaLink && !socialMediaLink.startsWith('http://') && !socialMediaLink.startsWith('https://')) {
      //setSocialMediaLinkError('Link must start with "http://" or "https://"');
      //return;
      values.socialMediaLink = 'https://' + socialMediaLink;
    }

    User.updateMe(values)
      .then(response => {
        console.log('update me response: ', response)
        toast.success('Personal details updated successfully');
        history.push('/enter-notification-details');
      })
      .catch(handleNetworkError)
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>Enter your stage name and photo</Typography>

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{display: 'none'}}
      />

      <div className={classes.avatarContainer}>
        <IconButton
          className={classes.avatarEditButton}
          size={'small'}
          onClick={handleFileUploadClick}
        >
          <EditIcon sx={{color: 'white', fontSize: '18px'}}/>
        </IconButton>
        <Avatar
          alt="Profile picture"
          src={avatarURL}
          className={classes.avatar}
        />
      </div>

      <div className={classes.inputContainer}>
        <TextField
          label="Stage Name"
          variant="outlined"
          fullWidth
          value={stageName}
          onChange={(e) => {
            setStageName(e.target.value)
          }}
          className={classes.textField}
        />
        <TextField
          label="Social Media Link"
          variant="outlined"
          fullWidth
          value={socialMediaLink}
          onChange={handleSocialMediaLinkChange}
          error={!!socialMediaLinkError}
          helperText={socialMediaLinkError}
          className={classes.textField}
        />
        <Button variant="contained" color="primary" onClick={handleSavePersonalDetails}
                className={classes.saveButton}>Save</Button>
      </div>
    </div>
  );
};

EnterStageNameAndPhoto.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(EnterStageNameAndPhotoStyle)(EnterStageNameAndPhoto);
