import React from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import CreateVenuePageStyle from './CreateVenuePageStyle';
import {Button, Checkbox, TextField, Typography} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import { Venue } from 'services';
import { handleNetworkError } from 'utils/utils';
import { toast } from 'react-toastify';

const CreateVenuePage = props => {
  const { classes } = props;

  const validationSchema = Yup.object({

  });

  const handleSubmit = async (values, {setErrors}) => {
    Venue.create(values)
      .then(response => {
        console.log('create venue success!', response);
        toast.success('Venue created successfully!');
      })
      .catch(handleNetworkError);
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerContent}>
        <Typography
          variant="h4"
          className={classes.title}
          sx={{
            marginBottom: '32px'
          }}
        >
          Create New Venue
        </Typography>

        <Formik
          initialValues={{
            name: '',
            address: '',
            website: '',
            phoneNumber: '',
            mapUrl: ''
          }}

          onSubmit={handleSubmit}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {({
              values,
              errors,
              handleChange,
              isSubmitting
            }) => (
            <Form className={classes.form}>
              <div className={classes.formInputContainer}>
                <TextField
                  type="text"
                  name="name"
                  label="Name"
                  placeholder="Enter venue name"
                  className={classes.formInput}
                  error={!!errors.name}
                  helperText={errors.name}
                  value={values.name}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div className={classes.formInputContainer}>
                <TextField
                  type="text"
                  name="address"
                  label="Address"
                  placeholder="Enter address"
                  className={classes.formInput}
                  error={!!errors.address}
                  helperText={errors.address}
                  value={values.address}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div className={classes.formInputContainer}>
                <TextField
                  type="text"
                  name="website"
                  label="Website"
                  placeholder="Website"
                  className={classes.formInput}
                  error={!!errors.website}
                  helperText={errors.website}
                  value={values.website}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div className={classes.formInputContainer}>
                <TextField
                  type="text"
                  name="phoneNumber"
                  label="Phone"
                  placeholder="Phone"
                  className={classes.formInput}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber}
                  value={values.phoneNumber}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div className={classes.formInputContainer}>
                <TextField
                  type="text"
                  name="mapUrl"
                  label="Map URL"
                  placeholder="Website"
                  className={classes.formInput}
                  error={!!errors.mapUrl}
                  helperText={errors.mapUrl}
                  value={values.mapUrl}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.button}
                  disabled={isSubmitting}
                >
                  Create Venue
                </Button>
              </div>
            </Form>
          )}
        </Formik>

      </div>
    </div>
  );
};

CreateVenuePage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(CreateVenuePageStyle)(CreateVenuePage);
