import React, {useContext, useEffect, useState} from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import HostQueuePageStyle from './HostQueuePageStyle';
import {
  Avatar,
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  IconButton,
  InputAdornment,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead, TableRow,
  TextField,
  Typography
} from '@mui/material';
import {SessionContext} from "context/SessionContext";
import {Form, Formik, Field } from "formik";
import * as Yup from "yup";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import RefreshIcon from '@mui/icons-material/Refresh';
import cn from "classnames";
import EditIcon from "@mui/icons-material/Edit";
import {UserContext} from "context/UserContext";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import { handleNetworkError } from 'utils/utils';
import { Session } from 'services';

const HostQueuePage = props => {
  const { classes } = props;

  const {userData, setUserData} = useContext(UserContext);
  const {
    sessionState,
    sessionActions,
    currentSessionData,
    setCurrentSessionData
  } = useContext(SessionContext);

  const [connected, setConnected] = useState(false);
  const [userInQueue, setUserInQueue] = useState(false);
  const [isVoter, setIsVoter] = useState(false);

  // the id of the session, not the 4 character code
  const [sessionId, setSessionId] = useState(null);

  const [initialized, setInitialized] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const [busy, setBusy] = useState(false);

  const history = useHistory();
  const location = useLocation();


  useEffect(() => {
    console.log('DEBUG ONLY HOST', {sessionState, sessionActions, currentSessionData});
    if(sessionState?.connected === false) {
      setConnected(false);
    }
  }, [sessionState, sessionActions])

  useEffect(() => {
    // get the session data from the location
    const data = location?.state?.data;

    setSessionId(data?.id);

    if(!initialized) {
      console.log('get session')
      Session.get(data.id)
        .then(response => {
          console.log('refresh session data', response);
          setCurrentSessionData(response.data);
          setInitialized(true);
        })
        .catch(handleNetworkError)
    }
  }, [location, sessionState, initialized])

  useEffect(() => {
    console.log('should attempt connection?', {sessionState, userData, connected, currentSessionData})

    if(sessionState.wsConnection && !connected && currentSessionData && userData) {
      console.log('attempt join session as host', {currentSessionData})
      sessionActions.hostJoinSession(currentSessionData.sessionId, currentSessionData.id, userData?.token);
      setConnected(true);
    }
  }, [sessionState, userData, currentSessionData])

  const addGuest = () => {
    history.push('/add-guest', {data: currentSessionData});
  };

  const handleOpenMessages = () => {
    console.log('open messages', {data: currentSessionData, performer: sessionState?.currentPerformance});
    history.push('/host-manage-queue', {data: currentSessionData, performer: sessionState?.currentPerformance?.user});
  }

  // for the running/paused button
  const handleToggleSessionActive = () => {
    console.log('toggle session active', currentSessionData);
    setBusy(true);
    sessionActions.toggleSessionActive(currentSessionData.sessionId)
      .then(response => {
        console.log('toggle session active response', response);
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false))
  }

  const handleCallNext = () => {
    console.log('call next', currentSessionData);
    setBusy(true);
    sessionActions.startNewPerformance(currentSessionData.sessionId)
      .then(response => {
        console.log('start new performance response', response);
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false))
  }

  const handleCancelButton = () => {
    console.log('cancel button', currentSessionData);
    setBusy(true);
    sessionActions.cancelPerformance(currentSessionData.sessionId)
      .then(response => {
        console.log('cancel performance response', response);
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false))
  }

  const handleCompleteButton = () => {
    console.log('complete button', currentSessionData);
    setBusy(true);
    sessionActions.completePerformance(currentSessionData.sessionId)
      .then(response => {
        console.log('complete performance response', response);
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false))
  }

  const handleRegenerateToken = () => {
  // TODO: If this is regenerated during a session, prompt all Performers/Voters with a modal dialog to enter the new token before continuing or cancel.
  //  The token is initially empty and requires the 'Generate' button to be pressed to start the session.
  //   This button flashes upon first entry into a non-active session.  It stops flashing when the first token has been generated.
    console.log('regenerate token', currentSessionData);
    setBusy(true);
    sessionActions.rerollCode(currentSessionData.sessionId)
      .then(response => {
        console.log('reroll code response', response);
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false))
    //setInitialized(false);
  };

  const handleRowClick = (row) => {
    console.log('open messages', row);
    history.push('/host-manage-queue', {data: currentSessionData, performer: row?.user});
  }

  const handleEndSession = () => {
    console.log('end session', currentSessionData);
    setBusy(true);
    sessionActions.endSession(currentSessionData.sessionId)
      .then(response => {
        console.log('end session response', response);
        history.push('/lobby');
      })
      .catch(handleNetworkError)
      .finally(() => setBusy(false))
  }

  return (
    <div className={classes.root}>
      <Dialog open={openConfirmationDialog}>
        <DialogTitle>End Session</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to end the session? This will remove all users and refund any pending bump fees.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} onClick={() => setOpenConfirmationDialog(false)}>Cancel</Button>
          <Button variant={'contained'} disabled={busy} onClick={handleEndSession}>End Session</Button>
        </DialogActions>
      </Dialog>

      <div className={classes.innerContent}>
        <Typography
          variant="h4"
          className={classes.title}
          sx={{
            marginBottom: '32px'
          }}
        >
          {currentSessionData?.venue?.name} - {currentSessionData?.date}
        </Typography>

        <Formik
            initialValues={{
              numberOfBumps: sessionState?.totalNumberOfBumps || '0',
              bumpTotalAmount: '$' + (sessionState?.amountSpentOnBumps || '0'),
              unreadMessages: sessionState?.unreadMessageUsers?.length || '0',
              token: '',
              tipAmount: sessionState?.amountOfTips || '0',
              queueLength: sessionState?.queue?.length,
              sessionStatus: sessionState?.paused ? 'paused' : 'running',
            }}
            enableReinitialize
            onSubmit={() => {}}
            validateOnChange={false}
          >
            {({
              values,
              errors,
              handleChange
            }) => (
            <Form className={classes.form}>
              <div className={classes.buttonRowContainer}>
                <Field name="sessionStatus">
                  {({ field, form }) => (
                    <ToggleButtonGroup
                      exclusive
                      value={field.value}
                      onChange={(_, newValue) => {
                        if(field.value === newValue || newValue === null) return;

                        form.setFieldValue(field.name, newValue);
                        handleToggleSessionActive();
                      }}
                    >
                      <ToggleButton
                        value="running"
                        style={field.value === "running" ? { backgroundColor: '#f04124', color: 'white' } : {}}
                        onClick={() => {}}
                      >
                        Running
                      </ToggleButton>
                      <ToggleButton
                        value="paused"
                        style={field.value === "paused" ? { backgroundColor: '#f04124', color: 'white' } : {}}
                        onClick={() => {}}
                      >
                        Paused
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </Field>

                <div className={classes.endButtonContainer}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => setOpenConfirmationDialog(true)}
                    disabled={busy}
                  >
                    End
                  </Button>
                </div>
              </div>

              <div className={classes.performerContainer}>
                <div className={classes.callContainer}>
                  <div className={classes.cardContainer}>
                    <Typography variant="h5" className={classes.cardTitle}>
                      {sessionState.currentlyPerforming ? 'Performing' : 'Call'}
                    </Typography>

                    <div className={classes.cardContainerInner}>
                      <div className={classes.cardLeftCol}>
                        <Typography
                          className={classes.cardSubtitle}
                          sx={{
                            fontSize: '24px',
                          }}
                        >
                          {sessionState?.currentPerformance?.user?.stage_name}
                        </Typography>
                        {currentSessionData?.type === 'Karaoke' && (
                          <>
                            <Typography
                              className={classes.cardSubtitle}
                              sx={{
                                marginTop: '8px'
                              }}
                            >
                              Performing:
                            </Typography>
                            <Typography
                              className={classes.cardSubtitle}
                              sx={{
                                fontWeight: 'bold'
                              }}
                            >
                              {sessionState?.currentPerformance?.song?.song_name}
                            </Typography>

                            <Typography
                              className={classes.cardSubtitle}
                              sx={{
                                marginTop: '8px'
                              }}
                            >
                              As recorded by:
                            </Typography>

                            <Typography
                              className={classes.cardSubtitle}
                              sx={{
                                fontWeight: 'bold'
                              }}
                            >
                              {sessionState?.currentPerformance?.song?.artist}
                            </Typography>
                          </>
                        )}
                        {/*<div className={classes.messageButtonContainer}>*/}
                        {/*  <Button*/}
                        {/*    variant={'contained'}*/}
                        {/*    className={classes.cardButton}*/}
                        {/*    onClick={() => handleOpenMessages()}*/}
                        {/*    disabled={!sessionState?.currentPerformance?.user?.id}*/}
                        {/*  >*/}
                        {/*    Messages*/}
                        {/*  </Button>*/}
                        {/*</div>*/}
                      </div>

                      <div className={classes.avatarContainer}>
                        <Avatar
                          alt="Profile picture"
                          src={sessionState?.currentPerformance?.user?.profile_picture}
                          className={classes.avatar}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={classes.performerButtonRowContainer}>
                    <div className={classes.buttonContainer}>
                      <Button
                        variant={'contained'}
                        className={classes.cardButton}
                        disabled={busy}
                        onClick={
                          sessionState?.currentlyPerforming ?
                            () => {} :
                            () => handleCallNext()
                        }
                        sx={{
                          backgroundColor: sessionState?.currentlyPerforming ? '#39FC2E !important' : '#F44336 !important',
                        }}
                      >
                        {sessionState?.currentlyPerforming ? 'Performing' : 'Call'}
                      </Button>
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button
                        variant={'contained'}
                        className={classes.cardButton}
                        disabled={!sessionState?.currentlyPerforming || busy}
                        onClick={handleCompleteButton}
                      >
                        Complete
                      </Button>
                    </div>
                    <Button
                      variant={'contained'}
                      className={classes.cardButton}
                      disabled={!sessionState?.currentlyPerforming || busy}
                      onClick={handleCancelButton}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                <div className={classes.upNextContainer}>
                  <div className={classes.cardContainer}>
                    <Typography variant="h5" className={classes.cardTitle}>
                      Up Next
                    </Typography>

                    <div className={classes.cardContainerInner}>
                      <div>
                        <Typography
                          className={classes.cardSubtitle}
                          sx={{
                            fontSize: '24px',
                          }}
                        >
                          {sessionState?.currentlyPerforming ? (
                            sessionState?.queue?.[1]?.user?.stage_name
                          ) : (
                            sessionState?.queue?.[0]?.user?.stage_name
                          )}
                        </Typography>
                        {sessionState?.type === 'Karaoke' && (
                          <>
                            <Typography
                              className={classes.cardSubtitle}
                              sx={{
                                marginTop: '8px'
                              }}
                            >
                              Performing:
                            </Typography>
                            <Typography
                              className={classes.cardSubtitle}
                              sx={{
                                fontWeight: 'bold'
                              }}
                            >
                              {sessionState?.currentlyPerforming ? (
                                sessionState?.queue?.[1]?.song?.song_name
                              ) : (
                                sessionState?.queue?.[0]?.song?.song_name
                              )}

                            </Typography>

                            <Typography
                              className={classes.cardSubtitle}
                              sx={{
                                marginTop: '8px'
                              }}
                            >
                              As recorded by:
                            </Typography>

                            <Typography
                              className={classes.cardSubtitle}
                              sx={{
                                fontWeight: 'bold'
                              }}
                            >
                              {sessionState?.currentlyPerforming ? (
                                sessionState?.queue?.[1]?.song?.artist
                              ) : (
                                sessionState?.queue?.[0]?.song?.artist
                              )}
                            </Typography>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.inLineContainer}>
                <div className={classes.cardContainer}>
                  <div className={classes.cardTitle}>
                    <Typography variant={'h5'}>
                      In Line
                    </Typography>

                    <Typography variant={'body2'}>
                      Tap to Manage
                    </Typography>

                    <Button
                      variant={'contained'}
                      color={'primary'}
                      onClick={addGuest}
                      disabled={userInQueue || isVoter || busy}
                    >
                      Add Guest
                    </Button>
                  </div>

                  <div className={classes.cardRow}>
                    <TableContainer className={classes.tableContainer}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Number</TableCell>
                            <TableCell>Name</TableCell>
                            {sessionState?.type === 'Karaoke' && (
                              <TableCell>Song</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                          {sessionState?.queue.map((row, index) => {
                            // skip the first and second row because of the up next box
                            if (index === 0 || (index === 1 && sessionState?.currentlyPerforming === true)) return null;

                            let unreadMessage = sessionState?.unreadMessageUsers?.includes(row?.user?.id)

                            return (
                              <TableRow
                                key={index}
                                onClick={() => handleRowClick(row)}
                                className={cn(classes.tableRow)}
                              >
                                {unreadMessage ? (
                                  <TableCell colSpan={3} className={classes.unreadCell}>
                                    UNREAD MESSAGE
                                  </TableCell>
                                ) : (
                                  <>
                                    <TableCell
                                      className={row?.user?.id === userData?.id ? classes.activeRow : classes.inactiveRow}
                                    >
                                      {sessionState?.currentlyPerforming ? index - 1 : index}
                                    </TableCell>
                                    <TableCell
                                      className={row?.user?.id === userData?.id ? classes.activeRow : classes.inactiveRow}
                                    >
                                      {sessionState?.type === 'Karaoke' ? (
                                        row.user?.stage_name
                                      ) : (
                                        <div className={classes.tableCellRow}>
                                          {row.user?.stage_name}

                                          {row?.user?.id === userData?.id && (
                                            <div className={classes.editIconContainer}>
                                              <EditIcon sx={{fontSize: '17px', position: 'relative', top: '2px'}}/>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </TableCell>
                                    {sessionState?.type === 'Karaoke' && (
                                      <TableCell
                                        className={row?.user?.id === userData?.id ? classes.activeRow : classes.inactiveRow}
                                      >
                                        <div className={classes.tableCellRow}>
                                          {row.song?.song_name}

                                          {row?.user?.id === userData?.id && (
                                            <div className={classes.editIconContainer}>
                                              <EditIcon sx={{fontSize: '17px', position: 'relative', top: '2px'}}/>
                                            </div>
                                          )}
                                        </div>
                                      </TableCell>
                                    )}
                                  </>
                                )}
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>


              <div className={classes.cardContainer}>
                <div className={cn(classes.cardTitle, classes.leftAlignedContent)}>
                  <Typography variant="h5">
                    {sessionState?.enableScoreboard ? 'Scoreboard' : 'History'}
                  </Typography>

                  <Typography variant={'body2'} sx={{
                    marginLeft: '24px'
                  }}>
                    Tap to Manage
                  </Typography>
                </div>

                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Number</TableCell>
                        <TableCell>Name</TableCell>
                        {sessionState?.type === 'Karaoke' && (
                          <TableCell>Song</TableCell>
                        )}
                        <TableCell>Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                      {sessionState?.scoreboard.map((row, index) => (
                        <TableRow key={index} onClick={() => handleRowClick(row)}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{row.user.stage_name}</TableCell>
                          {sessionState?.type === 'Karaoke' && (
                            <TableCell>{row.song?.song_name}</TableCell>
                          )}
                          <TableCell>{parseFloat(row.score).toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

              </div>

              <div className={classes.rowContainer}>
                <div className={cn(classes.colContainer, classes.marginRight)}>
                  <div className={classes.formInputContainer}>
                    <TextField
                      name="numberOfBumps"
                      label="Bumps"
                      className={classes.disabledFormInput}
                      error={!!errors.numberOfBumps}
                      helperText={errors.numberOfBumps}
                      value={values.numberOfBumps}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                    />
                  </div>
                  <div className={classes.formInputContainer}>
                    <TextField
                      name="bumpTotalAmount"
                      label="Bump Total"
                      className={classes.disabledFormInput}
                      error={!!errors.bumpTotalAmount}
                      helperText={errors.bumpTotalAmount}
                      value={values.bumpTotalAmount}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      disabled
                    />
                  </div>
                  <div className={classes.formInputContainer}>
                    <TextField
                      name="unreadMessages"
                      label="Unread Messages"
                      className={classes.disabledFormInput}
                      error={!!errors.unreadMessages}
                      helperText={errors.unreadMessages}
                      value={values.unreadMessages}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                    />
                  </div>
                </div>
                <div className={classes.colContainer}>
                  <div className={classes.formInputContainer}>
                    <TextField
                      name="token"
                      label="Session ID"
                      className={classes.disabledFormInput}
                      error={!!errors.token}
                      helperText={errors.token}
                      value={currentSessionData?.sessionId}
                      //onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleRegenerateToken}
                            >
                              <RefreshIcon color={'primary'}/>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      disabled
                    />
                  </div>
                  <div className={classes.formInputContainer}>
                    <TextField
                      name="tipAmount"
                      label="Tips"
                      className={classes.disabledFormInput}
                      error={!!errors.tipAmount}
                      helperText={errors.tipAmount}
                      value={values.tipAmount}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                    />
                  </div>
                  <div className={classes.formInputContainer}>
                    <TextField
                      name="queueLength"
                      label="Queue Length"
                      className={classes.disabledFormInput}
                      error={!!errors.queueLength}
                      helperText={errors.queueLength}
                      value={values.queueLength}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
          </Formik>
      </div>
    </div>
  );
};

HostQueuePage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(HostQueuePageStyle)(HostQueuePage);
