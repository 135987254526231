import React, { useContext, useEffect, useState } from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import MessageHostPageStyle from './MessageHostPageStyle';
import {Button, TextField, Typography} from "@mui/material";
import {Form, Formik} from "formik";
import { useHistory, useLocation } from 'react-router-dom';
import { SessionContext } from 'context/SessionContext';

const MessageHostPage = props => {
  const { classes } = props;

  const history = useHistory();
  const location = useLocation();

  const {sessionData, sessionState, sessionActions} = useContext(SessionContext);

  const [currentSessionData, setCurrentSessionData] = useState(null);

  useEffect(() => {
    // get the session data from the location
    const data = location?.state?.data;
    setCurrentSessionData(data);
    console.log('current session data', data)
  }, [location])

  const onSubmit = async (values, {setErrors}) => {
    console.log('session state 123', {sessionState, sessionData, currentSessionData});
    sessionActions.messageHost(currentSessionData?.sessionId, values.message);

    history.replace('/performer-queue', {data: currentSessionData});
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerContent}>
        <Typography variant="h4" className={classes.title}>
          Send a Message
        </Typography>

        <Typography variant="h4" className={classes.title}>
          To {currentSessionData?.host}
        </Typography>

        <Formik
            initialValues={{
              message: ''
            }}

            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              handleChange
            }) => (
            <Form>
              <div className={classes.cardContainer}>
                <Typography variant="h5" className={classes.cardTitle}>
                  Enter Message Here
                </Typography>

                <div className={classes.cardContainerInner}>
                  <TextField
                    type="text"
                    name="message"
                    className={classes.formInput}
                    error={!!errors.message}
                    helperText={errors.message}
                    value={values.message}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    maxRows={6}
                    minRows={6}
                  />
                </div>
              </div>

              <div className={classes.buttonContainer}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  type={'submit'}
                >
                  Send
                </Button>
              </div>
            </Form>
          )}
          </Formik>
      </div>
    </div>
  );
};

MessageHostPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(MessageHostPageStyle)(MessageHostPage);
