const MainPageLayoutStyle = theme => ({
  root: {
    minHeight: "100vh",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    width: '100%',
    padding: "0",
    justifyContent: "flex-start",
    alignItems: 'stretch',
  },

  contentRoot: {
    flex: 1,
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    width: '100%',
    padding: "12px",
    justifyContent: "flex-start",
    alignItems: 'stretch',
  }
});

export default MainPageLayoutStyle;
