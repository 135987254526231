import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import LoginPageStyle from './LoginPageStyle';
import logo from 'assets/duq-logo.jpeg';
import { Button, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { handleNetworkError } from 'utils/utils';
import { User } from 'services';
import Cookies from 'universal-cookie';
import { UserContext } from 'context/UserContext';
import {SessionContext} from "context/SessionContext";

const LoginPage = ({ classes }) => {
  const history = useHistory();

  const {userData, setUserData, userLogin} = useContext(UserContext);
  const {sessionData, setSessionData} = useContext(SessionContext);

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
    password: Yup.string()
      .min(8, 'Must be at least 8 characters')
      .required('Required'),
  });

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    console.log(values);

    setSessionData(null);

    User.login(values.email, values.password)
      .then(response => {
        userLogin(response);
      })
      .catch((error) => handleNetworkError(error));

    setSubmitting(false);
  };

  const continueAsVoter = () => {
    const cookies = new Cookies();
    cookies.remove('token', { path: '/' })

    setUserData('voter');

    history.push('/lobby');
  }

  return (
    <div className={classes.root}>
      <img src={logo} alt="Logo" className={classes.logo} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, isSubmitting }) => (
          <Form className={classes.form}>
            <div className={classes.formInputContainer}>
              <TextField
                type="text"
                name="email"
                label="Email"
                placeholder="Email"
                className={classes.formInput}
                error={!!errors.email}
                helperText={errors.email}
                value={values.email}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </div>

            <div className={classes.formInputContainer}>
              <TextField
                type="password"
                name="password"
                label="Password"
                placeholder="Password"
                className={classes.formInput}
                error={!!errors.password}
                helperText={errors.password}
                value={values.password}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </div>

            <Button
              type="submit"
              variant="contained"
              className={classes.loginButton}
              disabled={isSubmitting}
            >
              LOGIN
            </Button>

            <Button
              variant="contained"
              onClick={() => history.push('/create-account')}
              className={classes.signUpButton}
              disabled={isSubmitting}
            >
              SIGN UP
            </Button>

            <p
              className={classes.forgotPasswordLink}
              onClick={() => history.push('/request-password-reset')}
            >
              Forgot your password?
            </p>
          </Form>
        )}
      </Formik>

      <div className={classes.votersSection}>
        <p>Continue as a voter</p>
        <Button
          className={classes.continueButton}
          variant={'contained'}
          size={'large'}
          onClick={continueAsVoter}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(LoginPageStyle)(LoginPage);
