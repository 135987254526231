const ChooseSongTableStyle = theme => ({
  root: {
    width: '100%',
    height: '100%',
    margin: '32px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  table: {
    flexDirection: 'row',
    display: 'flex',
    overflowY: 'auto',
    height: '100%',
    width: '100%',
    flex: 1,
    borderRadius: 8,
    padding: '0 !important',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  },
  selectAllHeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  informationalHeaderText: {
    marginTop: '20px'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 0,
    margin: '32px 0',
  },
  formInputContainer: {
    flex: 0,
    width: '100%'
  },
  highlighted: {
    color: theme.palette.primary.main,
  },
  deletion: {
    backgroundColor: '#DE0D00',

    '& *':{
      color: 'white !important'
    }
  },
  button: {
    width: '100%',
    height: '48px',
    marginTop: theme.spacing(2),
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
  }
});

export default ChooseSongTableStyle;
