import React, { useState, useRef, useEffect, useContext } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import EnterSessionPageStyle from './EnterSessionPageStyle';
import { Button, Container, TextField, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { SessionContext } from 'context/SessionContext';
import { handleNetworkError } from 'utils/utils';
import { Session } from 'services';
import { UserContext } from 'context/UserContext';

const EnterSessionPage = props => {
  const { classes } = props;

  const [sessionCode, setSessionCode] = useState('');
  const {sessionState, setSessionState, sessionActions} = useContext(SessionContext);

  const inputRef = useRef(null);
  const location = useLocation();
  const history = useHistory();

  const [sessionData, setSessionData] = useState(null)
  const {userData} = useContext(UserContext);

  useEffect(() => {
    // read the id from the location
    const data = location?.state?.data;
    setSessionData(data);
    console.log('enter session data', data)
  }, [location])

  useEffect(() => {
    if(sessionCode.length === 4) {
      attemptToEnterSession();
    }
  }, [sessionCode]);

  const handleInput = event => {
    const { value } = event.target;
    setSessionCode(value.toUpperCase().slice(0, 4));
  };

  const attemptToEnterSession = () => {
    console.log('verify session code', {sessionCode, sessionId: sessionData?.id, sessionData, userData});

    Session.verifyCode(sessionCode, sessionData?.id).then(response => {
      console.log('verify code response', response);

      if(userData?.isHost && userData?.id === sessionData?.hostUser) {
        history.push('/host-queue', { data: sessionData });
      }
      else {
        history.push('/performer-queue', { data: sessionData });
      }

    }).catch(handleNetworkError);
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Enter Session ID
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        In order to join this session, you must enter the four character session ID supplied {' '}
        by the session's host and pay any entrance fee.
      </Typography>

      <Container maxWidth="sm" className={classes.cardContainer}>
        <Typography variant="h5" className={classes.cardTitle}>
          Session Information
        </Typography>

        <div className={classes.cardContainerInner}>
          <div className={classes.cardRow}>
            <span className={classes.cardSubtitle}>Venue:</span>
            <span className={classes.tableCell}>{sessionData?.venue?.name}</span>
          </div>
          <div className={classes.cardRow}>
            <span className={classes.cardSubtitle}>Host Name:</span>
            <span className={classes.tableCell}>{sessionData?.host}</span>
          </div>
          <div className={classes.cardRow}>
            <span className={classes.cardSubtitle}>Date:</span>
            <span className={classes.tableCell}>{sessionData?.date}</span>
          </div>
          <div className={classes.cardRow}>
            <span className={classes.cardSubtitle}>Time:</span>
            <span className={classes.tableCell}>{sessionData?.time}</span>
          </div>
        </div>
      </Container>

      {/*<Container maxWidth="sm" className={classes.submitPaymentContainer}>*/}
      {/*  <Button*/}
      {/*    variant={'contained'}*/}
      {/*    color={'primary'}*/}
      {/*    size={'large'}*/}
      {/*    className={classes.submitPaymentButton}*/}
      {/*  >*/}
      {/*    Pay Entry Fee*/}
      {/*  </Button>*/}
      {/*</Container>*/}

      <Container maxWidth="sm" className={classes.sessionIdContainer}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Enter session ID
        </Typography>

        <TextField
          className={classes.input}
          value={sessionCode}
          inputProps={{ maxLength: 4, style: {textAlign: 'center', paddingLeft: '30px'} }}
          onChange={handleInput}
          placeholder={'0000'}
          inputRef={inputRef}
          fullWidth
        />

        {/*<Button*/}
        {/*  variant={'contained'}*/}
        {/*  color={'primary'}*/}
        {/*  size={'large'}*/}
        {/*  className={classes.enterSessionButton}*/}
        {/*  onClick={attemptToEnterSession}*/}
        {/*>*/}
        {/*  Enter Session*/}
        {/*</Button>*/}
      </Container>
    </div>
  );
};

EnterSessionPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(EnterSessionPageStyle)(EnterSessionPage);
