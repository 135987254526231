import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  withStyles
} from '@mui/styles';
import PropTypes from 'prop-types';

import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import duqLogo from 'assets/duq-logo.jpeg'

import NavbarStyle from './NavbarStyle';
import {
  Button, Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Typography
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'universal-cookie';
import { UserContext } from 'context/UserContext';

const Navbar = props => {
  const { classes } = props;

  const history = useHistory();

  const {userData, setUserData} = useContext(UserContext);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [showSettingsIcon, setShowSettingsIcon] = React.useState(false);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('Are you sure you want to go back?');


  const pathNamesToShowClosedIcon = [
    { path: '/account-settings', confirm: false },
    { path: '/host-account-settings', confirm: false },
    { path: '/manage-song', confirm: false },
    { path: '/add-guest',
      confirm: true,
      confirmText: 'You will lose all entered data if you go back without saving. ' +
        'Are you sure you want to go back?'
    },
    { path: '/create-session',
      confirm: true,
      confirmText: 'You will lose all entered data if you go back without saving. ' +
        'Are you sure you want to go back?'
    },
    { path: '/create-venue',
      confirm: true,
      confirmText: 'You will lose all entered data if you go back without saving. ' +
        'Are you sure you want to go back?'
    }
  ]

  useEffect(() => {
    if(userData === false || userData === 'voter') {
      setShowSettingsIcon(false);
    }
    else {
      setShowSettingsIcon(true);
    }
  }, [userData])

  const navigateToAccountSettings = () => {
    if(userData.isHost) {
      history.push('/host-account-settings');
    }
    else {
      history.push('/account-settings');
    }
  }

  const navigateBack = () => {
    const currentPath = pathNamesToShowClosedIcon.find(p => p.path === history.location.pathname);

    if (currentPath?.confirm) {
      const confirmText = currentPath.confirmText || 'Are you sure you want to go back?';
      setConfirmDialogText(confirmText);  // Update the confirm dialog text
      setConfirmDialogOpen(true);  // Open the confirmation dialog
    } else {
      history.goBack();  // No confirmation needed, just go back
    }
  };

  const handleConfirmDialogClose = (confirm) => {
    setConfirmDialogOpen(false);
    if (confirm) {
      history.goBack();
    }
  }

  const toggleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  }

  const logOut = () => {
    const cookies = new Cookies();
    cookies.remove('token');

    setOpenDrawer(false);

    history.push('/');
  }

  return (
    <>
      <div className={classes.root}>
        <IconButton onClick={toggleOpenDrawer}>
          <MenuIcon />
        </IconButton>

        <div className={classes.logo}>
          <img src={duqLogo} alt={'Duq Logo'}/>
        </div>

        {/* if the url is /account-settings then show a close icon instead */}

        {pathNamesToShowClosedIcon.some(p => p.path === history.location.pathname) ?
          <IconButton onClick={navigateBack}>
            <CloseIcon />
          </IconButton>
          :
          showSettingsIcon &&
          <IconButton onClick={navigateToAccountSettings}>
            <SettingsIcon />
          </IconButton>
        }
      </div>

      <Drawer
        anchor={'left'}
        open={openDrawer}
        onClose={() => { setOpenDrawer(false) }}
      >
        <div className={classes.drawerContainer}>
          <List>
            <MenuItem onClick={logOut}>
              <Typography variant={'h6'}>
                Log Out
              </Typography>
            </MenuItem>
          </List>
        </div>
      </Drawer>


      <Dialog
        open={confirmDialogOpen}
        onClose={() => handleConfirmDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmDialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmDialogClose(false)} color="primary">
            No
          </Button>
          <Button onClick={() => handleConfirmDialogClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Navbar.propTypes = {
  classes: PropTypes.object
};

export default withStyles(NavbarStyle)(Navbar);
