const EnterNotificationDetailsStyle = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    padding: theme.spacing(4, 0),
    width: '90%'
  },
  title: {
    marginBottom: '12px !important'
  },
  saveButton: {
    width: '100%',
    height: '48px',
    marginTop: theme.spacing(2),
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
  },
  form: {
    width: '100%'
  },
  textFieldContainer: {
    margin: '32px 0 !important',
  },
  notificationMethodContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '32px !important'
  },
  notificationMethodTitle: {
    width: '85%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  notificationMethodLabel: {
    marginRight: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  notificationMethodButton: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontSize: '14px',
    borderRadius: '4px',
    fontWeight: 600,
    textTransform: 'none',
  },
  notificationMethodRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});

export default EnterNotificationDetailsStyle;