import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import React, { createContext, useEffect, useState } from 'react';
import APPLICATION_SETTINGS from "settings";

export const StripeContext = createContext();

const StripeProvider = ({ children }) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripe = await loadStripe(APPLICATION_SETTINGS.STRIPE_API_KEY);
      setStripePromise(stripe);
    };

    initializeStripe();
  }, []);

  const values = {
    stripePromise
  };

  return (
    stripePromise && (
      <StripeContext.Provider value={values}>
        <Elements stripe={stripePromise}>
          {children}
        </Elements>
      </StripeContext.Provider>
    )
  );
};

export default StripeProvider;
